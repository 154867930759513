import { FC } from "react";
import TextareaAutosize, {
  TextareaAutosizeProps,
} from "react-textarea-autosize";
import styled from "styled-components";

import Text from "./Text";
import { typography } from "@/utilities/theme";

interface TextareaProps {
  error?: string;
}

interface TextareaStyledProps {
  style: any;
}

const Error = styled(Text)`
  margin-bottom: 1rem;
`;

const TextareaStyled = styled(TextareaAutosize)<TextareaStyledProps>`
  outline: none;
  color: ${({ theme }) => theme.colors.bodyC};
  padding: 1rem;
  width: 100%;
  border: 1px solid ${({ theme }) => theme.colors.offBaseC};
  border-radius: 0.5rem;
  background-color: #fff;

  ::placeholder {
    color: ${({ theme }) => theme.colors.bodyC};
  }

  @media ${({ theme }) => theme.device.mobile} {
    padding: 0.75rem 1rem;
  }
`;

const Textarea: FC<TextareaProps & TextareaAutosizeProps> = ({
  error,
  style,
  ...props
}) => {
  return (
    <>
      {!!error && (
        <Error type="body" color="dangerC">
          {error}
        </Error>
      )}
      <TextareaStyled style={{ ...typography.body, ...style }} {...props} />
    </>
  );
};

export default Textarea;
