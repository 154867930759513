import { FC, HTMLAttributes } from "react";
import { IoChevronDown, IoChevronUp } from "react-icons/io5";
import styled from "styled-components";

import Text from "@/components/Text";
import { useMediaQuery } from "@/utilities/hooks";
import { colors } from "@/utilities/theme";

interface FAQItemProps {
  active: boolean;
  question: string;
  answers: string[];
  onItemClick: () => void;
}

interface ContainerProps {
  active: boolean;
}

interface AnswerProps {
  hasBottomMargin: boolean;
}

const Container = styled.div<ContainerProps>`
  padding: 1.5rem;
  border-radius: 0.75rem;

  ${({ theme, active }) =>
    active && `background-color: ${theme.colors.chatTC};`}

  @media ${({ theme }) => theme.device.mobile} {
    padding: 1rem;
  }
`;

const QuestionContainer = styled.div`
  cursor: pointer;
  border-bottom: 1px solid ${({ theme }) => theme.colors.inactiveLightC};
  padding-bottom: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media ${({ theme }) => theme.device.mobile} {
    padding-bottom: 1rem;
  }
`;

const AnswersContainer = styled.div`
  padding-top: 1.5rem;

  @media ${({ theme }) => theme.device.mobile} {
    padding-top: 1rem;
  }
`;

const Answer = styled(Text)<AnswerProps>`
  margin-bottom: ${({ hasBottomMargin }) => (hasBottomMargin ? 0.75 : 0)}rem;
`;

const FAQItem: FC<FAQItemProps & HTMLAttributes<HTMLDivElement>> = ({
  active,
  question,
  answers,
  onItemClick,
  ...props
}) => {
  const { isMobile } = useMediaQuery();

  return (
    <Container active={active} {...props}>
      <QuestionContainer onClick={onItemClick}>
        <Text type={isMobile ? "bodyBold" : "h4Bold"} color="headingText">
          {question}
        </Text>
        <div>
          {active ? (
            <IoChevronUp size="1rem" color={colors.mainC} />
          ) : (
            <IoChevronDown size="1rem" color={colors.mainC} />
          )}
        </div>
      </QuestionContainer>
      {active && (
        <AnswersContainer>
          {answers.map((answer, index) => (
            <Answer
              key={index}
              type="body"
              color="headingText"
              hasBottomMargin={index !== answers.length - 1}
            >
              {answer}
            </Answer>
          ))}
        </AnswersContainer>
      )}
    </Container>
  );
};

export default FAQItem;
