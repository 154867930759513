import { FC, HTMLAttributes, useRef, useEffect, useState } from "react";
import { DateTime } from "luxon";
import styled from "styled-components";

import { useFetchFirebaseStorageFileUrl } from "@/utilities/hooks";
import { Message } from "@/models/messages";

interface VideoMessageProps {
  message: Message;
  isMyMsg: boolean;
}

interface ContainerProps {
  isMyMsg: boolean;
  height: number;
}

const Container = styled.div<ContainerProps>`
  max-width: 320px;
  width: 100%;
  min-height: ${({ height }) => height}px;
  border-radius: 1rem;
  background-color: ${({ theme, isMyMsg }) =>
    isMyMsg ? theme.colors.chatUC : theme.colors.chatTC};
  margin-bottom: 0.5rem;
  align-self: ${({ isMyMsg }) => (isMyMsg ? "flex-end" : "flex-start")};
  position: relative;
  overflow: hidden;
`;

const Time = styled.p`
  font-family: Roboto;
  font-weight: 400;
  font-size: 0.63rem;
  line-height: 0.5rem;
  letter-spacing: 0.1px;
  color: ${({ theme }) => theme.colors.white};
  position: absolute;
  bottom: 0.75rem;
  right: 1.5rem;

  @media ${({ theme }) => theme.device.mobile} {
    bottom: 0.5rem;
    right: 1rem;
  }
`;

const VideoMessage: FC<VideoMessageProps & HTMLAttributes<HTMLDivElement>> = ({
  message,
  ...props
}) => {
  const [containerWidth, setContainerWidth] = useState<number>(320);

  const videoUrl = useFetchFirebaseStorageFileUrl(message.file!);

  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const handleResize = () => {
    if (!containerRef.current) {
      return;
    }

    setContainerWidth(containerRef.current.offsetWidth);
  };

  return (
    <Container ref={containerRef} height={(containerWidth / 4) * 3} {...props}>
      <video src={videoUrl} width="100%" height="100%" controls />
      <Time>
        {DateTime.fromSeconds(message.createdAt.seconds).toFormat("t")}
      </Time>
    </Container>
  );
};

export default VideoMessage;
