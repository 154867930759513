import { FC, HTMLAttributes } from "react";
import styled from "styled-components";

import Text from "@/components/Text";
import { useMediaQuery } from "@/utilities/hooks";

interface SlideProps {
  img: string;
  title: string;
  titleStrong: string;
  description: string;
}

const Image = styled.img`
  margin-bottom: 3rem;
`;

const Title = styled(Text)`
  margin-bottom: 0.75rem;
`;

const Slide: FC<SlideProps & HTMLAttributes<HTMLDivElement>> = ({
  img,
  title,
  titleStrong,
  description,
  ...props
}) => {
  const { isMobile } = useMediaQuery();

  return (
    <div {...props}>
      <Image src={img} alt={description} />
      <Title type="h2" color="headingText">
        {title} <strong>{titleStrong}</strong>
      </Title>
      <Text type={isMobile ? "h5" : "body"} color="bodyC">
        {description}
      </Text>
    </div>
  );
};

export default Slide;
