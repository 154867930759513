import { FC, HTMLAttributes } from "react";
import styled from "styled-components";

import { ReactComponent as SecurityLogosIcon } from "@/assets/images/security-logos.svg";

const HeaderContainer = styled.div`
  padding-bottom: 1rem;
  display: flex;
  align-items: center;
`;

const Divider = styled.div`
  flex: 1;
  height: 1px;
  background-color: #dcdcdc;
`;

const TitleContainer = styled.div`
  padding: 0 1.5rem;
`;

const Title = styled.p`
  font-family: "Source Sans Pro", sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 1rem;
  line-height: 1.4rem;
  color: #2f2f2f;

  strong {
    font-family: inherit;
    font-weight: 700;
  }
`;

const SecurityLogosIconStyled = styled(SecurityLogosIcon)`
  width: 100%;
`;

const SafeCheckout: FC<HTMLAttributes<HTMLDivElement>> = (props) => {
  return (
    <div {...props}>
      <HeaderContainer>
        <Divider />
        <TitleContainer>
          <Title>
            Guaranteed <strong>safe</strong> checkout
          </Title>
        </TitleContainer>
        <Divider />
      </HeaderContainer>
      <SecurityLogosIconStyled />
    </div>
  );
};

export default SafeCheckout;
