import { FC, SVGProps, HTMLAttributes } from "react";
import styled from "styled-components";

interface SubscriptionDetailProps {
  title: string;
  description: string;
  Icon: FC<SVGProps<SVGSVGElement>>;
}

const IconContainer = styled.div`
  padding-bottom: 1.5rem;
`;

const TitleContainer = styled.div`
  padding-bottom: 0.5rem;
`;

const Title = styled.h4`
  font-family: "Source Sans Pro", sans-serif;
  font-weight: 600;
  font-style: normal;
  font-size: 1.13rem;
  line-height: 1.46rem;
  color: #18191f;
`;

const Description = styled.p`
  font-family: "Source Sans Pro", sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 1rem;
  line-height: 1.3rem;
  color: #18191f;
`;

const SubscriptionDetail: FC<
  SubscriptionDetailProps & HTMLAttributes<HTMLDivElement>
> = ({ title, description, Icon, ...props }) => {
  return (
    <div {...props}>
      <IconContainer>
        <Icon width="2rem" height="2rem" />
      </IconContainer>
      <TitleContainer>
        <Title>{title}</Title>
      </TitleContainer>
      <Description>{description}</Description>
    </div>
  );
};

export default SubscriptionDetail;
