import { FC, HTMLAttributes } from "react";
import styled from "styled-components";

import PaymentSteps from "./PaymentSteps";
import { Product } from "@/models/products";

interface PaymentDialogOrderSummaryProps {
  selectedProduct: Product | null;
}

interface OrderSummaryTextProps {
  color?: string;
}

const Container = styled.div`
  padding-top: 2rem;
`;

const Title = styled.h1`
  font-family: "Merriweather", serif;
  font-weight: 700;
  font-size: 1.13rem;
  line-height: 1.41rem;
  color: #222222;
  text-align: center;
  margin-bottom: 1rem;
`;

const OrderSummaryContainer = styled.div`
  background-color: #f3f4f8;
  padding: 1.5rem 1.5rem 1rem 1.5rem;
`;

const OrderSummaryItemContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.5rem;
`;

const OrderSummaryText = styled.span<OrderSummaryTextProps>`
  font-family: "Source Sans Pro", sans-serif;
  font-weight: 400;
  font-size: 0.88rem;
  line-height: 1.5rem;
  color: ${({ color }) => color ?? "#222222"};

  strong {
    font-size: 1rem;
    font-weight: 600;
  }
`;

const Divider = styled.div`
  height: 1px;
  background-color: #a7a7a7;
  margin: 0.25rem 0 0.75rem 0;
`;

const OrderSummaryTotalText = styled.span`
  font-family: "Merriweather", serif;
  font-weight: 700;
  font-size: 1rem;
  line-height: 1.63rem;
  color: #222222;
`;

const OrderSummaryTotalPriceText = styled(OrderSummaryText)`
  strong {
    font-size: 1.38rem;
    font-weight: 700;
    padding-left: 0.5rem;
  }
`;

const PaymentDialogOrderSummary: FC<
  PaymentDialogOrderSummaryProps & HTMLAttributes<HTMLDivElement>
> = ({ selectedProduct, ...props }) => {
  const prices = selectedProduct?.prices?.[0];

  const price = prices?.price ?? 0;
  const discount = prices?.discount ?? 0;
  const finalPrice = prices?.final_price ?? 0;

  const getWeekPrice = (totalPrice: number) => {
    switch (selectedProduct?.key) {
      case "test-1":
        return { price: Math.round(totalPrice / (1 * 4)), weeks: 4 };
      case "test-2":
        return { price: Math.round(totalPrice / (3 * 4)), weeks: 3 * 4 };
      case "test-3":
        return { price: Math.round(totalPrice / (6 * 4)), weeks: 6 * 4 };
      default:
        return { price: 0, weeks: 0 };
    }
  };

  return (
    <Container {...props}>
      <Title>Order Summary</Title>
      <PaymentSteps />
      <OrderSummaryContainer>
        <OrderSummaryItemContainer>
          <OrderSummaryText>{selectedProduct?.name}</OrderSummaryText>
          <OrderSummaryText>
            <strong>${price.toFixed(2)}</strong>
          </OrderSummaryText>
        </OrderSummaryItemContainer>
        <OrderSummaryItemContainer>
          <OrderSummaryText>Discount</OrderSummaryText>
          <OrderSummaryText color="#E38B3A">
            <strong>-${discount.toFixed(2)}</strong>
          </OrderSummaryText>
        </OrderSummaryItemContainer>
        <Divider />
        <OrderSummaryItemContainer>
          <OrderSummaryTotalText>Total:</OrderSummaryTotalText>
          <OrderSummaryTotalPriceText>
            (${getWeekPrice(finalPrice).price} x{" "}
            {getWeekPrice(finalPrice).weeks}weeks)
            <strong>${finalPrice.toFixed(2)}</strong>
          </OrderSummaryTotalPriceText>
        </OrderSummaryItemContainer>
      </OrderSummaryContainer>
    </Container>
  );
};

export default PaymentDialogOrderSummary;
