import { FC, HTMLAttributes } from "react";
import { IconType } from "react-icons";
import styled from "styled-components";

import Text from "@/components/Text";
import Switch from "@/components/Switch";
import { useMediaQuery } from "@/utilities/hooks";

interface NotificationsItemProps {
  name: string;
  title: string;
  description: string;
  value: boolean;
  loading: boolean;
  Icon: IconType;
  onValueChange: ({ name, value }: { name: string; value: boolean }) => void;
}

const Container = styled.div`
  display: flex;
  align-items: center;
`;

const LeftContainer = styled.div`
  align-self: flex-start;
`;

const CenterContainer = styled.div`
  padding: 0 1rem;
`;

const Title = styled(Text)`
  margin-bottom: 0.25rem;
`;

const NotificationsItem: FC<
  NotificationsItemProps & HTMLAttributes<HTMLDivElement>
> = ({
  name,
  title,
  description,
  value,
  loading,
  Icon,
  onValueChange,
  ...props
}) => {
  const { isMobile } = useMediaQuery();

  const handleValueChange = (nextValue: boolean) => {
    onValueChange({ name, value: nextValue });
  };

  return (
    <Container {...props}>
      <LeftContainer>
        <Icon size={isMobile ? "1.5rem" : "2rem"} color="#000000" />
      </LeftContainer>
      <CenterContainer>
        <Title type="bodyBold" color="headingText">
          {title}
        </Title>
        <Text type="bodyMini" color="offBaseC">
          {description}
        </Text>
      </CenterContainer>
      <Switch checked={value} disabled={loading} onChange={handleValueChange} />
    </Container>
  );
};

export default NotificationsItem;
