import { FC } from "react";
import { Link as RRDLink, LinkProps as RRDLinkProps } from "react-router-dom";

import TextButton from "./TextButton";
import { Color, Typography } from "@/utilities/theme";

interface LinkProps {
  type: Typography;
  color: Color;
}

const Link: FC<LinkProps & RRDLinkProps> = ({
  type,
  color,
  children,
  style,
  ...props
}) => {
  return (
    <RRDLink {...props}>
      <TextButton type={type} color={color} style={style}>
        {children}
      </TextButton>
    </RRDLink>
  );
};

export default Link;
