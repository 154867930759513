import { FC } from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";

import ScreenContainer from "@/components/ScreenContainer";
import Header from "@/components/Header";
import Text from "@/components/Text";
import Button from "@/components/Button";
import StartImg from "@/assets/images/start-img.svg";
import GooglePlayImg from "@/assets/images/google-play.svg";
import AppStoreImg from "@/assets/images/app-store.svg";
import { useMediaQuery } from "@/utilities/hooks";

const ScreenContainerStyled = styled(ScreenContainer)`
  display: flex;
  align-items: center;
  justify-content: center;

  @media ${({ theme }) => theme.device.mobile} {
    align-items: flex-start;
  }
`;

const Container = styled.div`
  max-width: 34.5rem;
  width: 100%;
  padding: 1rem 0;

  @media ${({ theme }) => theme.device.mobile} {
    padding: 2rem 0;
  }
`;

const Image = styled.img`
  max-width: 14.74rem;
  width: 100%;
  height: auto;
  margin: 0 auto 3rem auto;
  display: block;

  @media ${({ theme }) => theme.device.mobile} {
    display: none;
  }
`;

const Title = styled(Text)`
  text-align: center;
  margin-bottom: 0.75rem;
`;

const Description = styled(Text).attrs({
  type: "body",
  color: "bodyC",
})`
  text-align: center;
`;

const StoresContainer = styled.div`
  max-width: 22rem;
  width: 100%;
  margin: 0 auto;
  padding: 3rem 0 1.5rem 0;
`;

const Subtitle = styled(Text)`
  text-align: center;
  margin-bottom: 1.5rem;
`;

const StoreItemsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 1.5rem;

  @media ${({ theme }) => theme.device.mobile} {
    grid-column-gap: 1rem;
    padding: 0 1rem;
  }
`;

const StoreImage = styled.img`
  width: 100%;
  cursor: pointer;
`;

const DividerContainer = styled.div`
  padding-top: 3rem;
  display: flex;
  align-items: center;
`;

const Divider = styled.div`
  height: 1px;
  background-color: ${({ theme }) => theme.colors.inactiveLightC};
  flex: 1;
`;

const DividerDescription = styled(Description)`
  padding: 0 1.5rem;
`;

const ButtonStyled = styled(Button)`
  max-width: 14rem;
  width: 100%;
  margin: 0 auto;
  display: block;
`;

const Start: FC = () => {
  const history = useHistory();

  const { isMobile } = useMediaQuery();

  const navigateToChat = () => {
    history.push("/chat");
  };

  const openGooglePlay = () => {
    window.open(
      "https://play.google.com/store/apps/details?id=com.domental.app",
      "_self"
    );
  };

  const openAppStore = () => {
    window.open("https://apps.apple.com/lt/app/domental/id1558664529", "_self");
  };

  return (
    <ScreenContainerStyled HeaderComponent={<Header />}>
      <Container>
        <Image src={StartImg} alt="start-img" />
        <Title type="h2" color="headingText">
          You have successfully created your account!
        </Title>
        <Description>It's time to start your therapy.</Description>
        <StoresContainer>
          <Subtitle type={isMobile ? "h4Bold" : "h3Bold"} color="headingText">
            Download the app
          </Subtitle>
          <StoreItemsContainer>
            <StoreImage
              src={GooglePlayImg}
              alt="google-play"
              onClick={openGooglePlay}
            />
            <StoreImage
              src={AppStoreImg}
              alt="app-store"
              onClick={openAppStore}
            />
          </StoreItemsContainer>
          <DividerContainer>
            <Divider />
            <DividerDescription>or</DividerDescription>
            <Divider />
          </DividerContainer>
        </StoresContainer>
        <ButtonStyled onClick={navigateToChat}>
          Continue on browser
        </ButtonStyled>
      </Container>
    </ScreenContainerStyled>
  );
};

export default Start;
