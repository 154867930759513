import { FC, HTMLAttributes } from "react";
import styled from "styled-components";

import theme, { Color, Typography } from "@/utilities/theme";

interface TextProps {
  type: Typography;
  color: Color;
}

interface TextStyledProps {
  color: Color;
}

const TextStyled = styled.div<TextStyledProps>`
  color: ${({ color, theme: { colors } }) => colors[color]};
`;

const Text: FC<TextProps & HTMLAttributes<HTMLDivElement>> = ({
  type,
  style,
  ...props
}) => {
  const typographyStyle = theme.typography[type] ? theme.typography[type] : {};

  switch (type) {
    case "h1":
      return (
        <TextStyled
          as="h1"
          style={{ ...typographyStyle, ...style }}
          {...props}
        />
      );
    case "h2":
    case "h2Bold":
      return (
        <TextStyled
          as="h2"
          style={{ ...typographyStyle, ...style }}
          {...props}
        />
      );
    case "h3Bold":
      return (
        <TextStyled
          as="h3"
          style={{ ...typographyStyle, ...style }}
          {...props}
        />
      );
    case "h4":
    case "h4Bold":
      return (
        <TextStyled
          as="h4"
          style={{ ...typographyStyle, ...style }}
          {...props}
        />
      );
    case "h5":
    case "h5Bold":
      return (
        <TextStyled
          as="h5"
          style={{ ...typographyStyle, ...style }}
          {...props}
        />
      );
    case "body":
    case "bodyBold":
    case "bodyBlack":
    case "bodyMini":
    case "chatP":
      return (
        <TextStyled
          as="p"
          style={{ ...typographyStyle, ...style }}
          {...props}
        />
      );
    case "btn":
      return (
        <TextStyled
          as="span"
          style={{ ...typographyStyle, ...style }}
          {...props}
        />
      );
    default:
      return null;
  }
};

export default Text;
