import { FC } from "react";
import RLoader, { LoaderProps } from "react-loader-spinner";

import { colors } from "@/utilities/theme";

const Loader: FC<LoaderProps> = (props) => {
  return <RLoader color={colors.mainC} {...props} />;
};

export default Loader;
