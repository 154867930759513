import { FC, HTMLAttributes } from "react";
import styled from "styled-components";

import Loader from "@/components/Loader";

const Container = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ScreenLoader: FC<HTMLAttributes<HTMLDivElement>> = (props) => {
  return (
    <Container {...props}>
      <Loader type="Circles" />
    </Container>
  );
};

export default ScreenLoader;
