import {
  init,
  InitConfigRedux,
  RematchDispatch,
  RematchRootState,
} from "@rematch/core";
import selectPlugin from "@rematch/select";
import loadingPlugin, { ExtraModelsFromLoading } from "@rematch/loading";
import storage from "redux-persist/lib/storage";
import persistPlugin, { getPersistor } from "@rematch/persist";
import logger from "redux-logger";
import { models, RootModel } from "@/models";

const redux: InitConfigRedux = {
  middlewares: [],
};

if (process.env.NODE_ENV === "development" && !!redux?.middlewares) {
  redux.middlewares.push(logger);
}

const store = init<RootModel, ExtraModelsFromLoading<RootModel>>({
  models,
  plugins: [
    selectPlugin(),
    loadingPlugin(),
    persistPlugin({
      key: "persist-storage",
      storage,
      whitelist: ["authentication"],
    }),
  ],
  redux,
});

const persistor = getPersistor();

export default store;
export { persistor };
export type Store = typeof store;
export type Dispatch = RematchDispatch<RootModel>;
export type RootState = RematchRootState<RootModel, ExtraModelsFromLoading<RootModel>>;
