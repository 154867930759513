import { FC } from "react";
import { Props } from "react-modal";
import styled from "styled-components";

import Modal from "@/components/Modal";
import Text from "@/components/Text";
import TextButton from "@/components/TextButton";
import { useMediaQuery } from "@/utilities/hooks";

interface DeleteAccountModalProps {
  disabled?: boolean;
  toggleModal: () => void;
  onConfirm: () => void;
}

const TextStyled = styled(Text)`
  margin-bottom: 1rem;
`;

const ButtonsContainer = styled.div`
  margin-top: 2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const DeleteAccountModal: FC<DeleteAccountModalProps & Props> = ({
  disabled,
  toggleModal,
  onConfirm,
  ...props
}) => {
  const { isMobile } = useMediaQuery();

  return (
    <Modal width="22.13rem" toggleModal={toggleModal} {...props}>
      <TextStyled type={isMobile ? "h4Bold" : "h3Bold"} color="headingText">
        Delete account
      </TextStyled>
      <Text type="body" color="subHeadingTxt">
        Are you sure you want to delete your account?
      </Text>
      <ButtonsContainer>
        <TextButton
          type="btn"
          color="baseC"
          disabled={!!disabled}
          onClick={toggleModal}
        >
          Cancel
        </TextButton>
        <TextButton
          type="btn"
          color="promoC"
          disabled={!!disabled}
          onClick={onConfirm}
        >
          Yes, Delete
        </TextButton>
      </ButtonsContainer>
    </Modal>
  );
};

export default DeleteAccountModal;
