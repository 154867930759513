import { FC, useEffect, useState, ChangeEvent } from "react";
import { useSelector, useDispatch } from "react-redux";
import { use100vh } from "react-div-100vh";
import { useHistory } from "react-router-dom";
import styled from "styled-components";

import ScreenContainer from "@/components/ScreenContainer";
import Header from "@/components/Header";
import MobileNav from "@/components/MobileNav";
import Footer from "@/components/Footer";
import Button from "@/components/Button";
import CurrentConsultationListItem from "./components/CurrentConsultationListItem";
import ConsultationsList from "./components/ConsultationsList";
import ChatInput from "./components/ChatInput";
import ChatActions from "./components/ChatActions";
import MessagesList from "./components/MessagesList";
import store, { RootState, Dispatch } from "@/utilities/store";
import { useMediaQuery } from "@/utilities/hooks";
import { subscribeinChat } from "@/utilities/gtm";

interface ContainerProps {
  height: number;
}

const ScreenContainerStyled = styled(ScreenContainer)`
  display: flex;
`;

const Container = styled.div<ContainerProps>`
  flex: 1;
  display: grid;
  grid-template-columns: 18rem auto 1fr;
  grid-column-gap: 2rem;
  padding: 3rem 0;
  height: calc(${({ height }) => height}px - 6.25rem);

  @media ${({ theme }) => theme.device.mobile} {
    grid-template-columns: 1fr;
    grid-auto-rows: auto 1fr;
    padding: 1.5rem 0;
    height: calc(${({ height }) => height}px - 3.5rem - 4.5rem);
  }
`;

const LeftContainer = styled.div`
  overflow-y: auto;
  display: flex;
  flex-direction: column;
`;

const LeftContentContainer = styled.div`
  flex: 1;
`;

const LeftContentHeaderContainer = styled.div`
  padding-bottom: 1rem;

  @media ${({ theme }) => theme.device.mobile} {
    padding-bottom: 0;
  }
`;

const ButtonStyled = styled(Button)`
  :hover:enabled {
    box-shadow: none;
  }

  :active:enabled {
    box-shadow: none;
  }
`;

const RightContainer = styled.div`
  display: flex;
  flex-direction: column;
  overflow-y: auto;
`;

const Divider = styled.div`
  width: 1px;
  background-color: ${({ theme }) => theme.colors.inactiveLightC};

  @media ${({ theme }) => theme.device.mobile} {
    display: none;
  }
`;

const ChatInputContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
  grid-column-gap: 1.5rem;

  @media ${({ theme }) => theme.device.mobile} {
    grid-column-gap: 1rem;
  }
`;

const Chat: FC = () => {
  const [activeConsultationId, setActiveConsultationId] = useState<string>("");
  const [message, setMessage] = useState<string>("");
  const [fileAttachment, setFileAttachment] = useState<File | null>(null);
  const [audioAttachment, setAudioAttachment] = useState<Blob | null>(null);
  const [audioRecording, setAudioRecording] = useState<boolean>(false);

  const user = useSelector((state: RootState) => state.authentication.user);
  const messages = useSelector((state: RootState) => state.messages.messages);
  const subscription = useSelector(
    (state: RootState) => state.subscriptions.subscription
  );

  const currentConsultation = useSelector((state: RootState) =>
    store.select.consultations.currentConsultation(
      state,
      user!.assignedDoctor!.id
    )
  );
  const supportConsultation = useSelector((state: RootState) =>
    store.select.consultations.supportConsultation(state)
  );
  const expertConsultation = useSelector((state: RootState) =>
    store.select.consultations.expertConsultation(state)
  );
  const previousConsultations = useSelector((state: RootState) =>
    store.select.consultations.previousConsultations(
      state,
      user!.assignedDoctor!.id
    )
  );

  const sendTextMessageLoading: boolean = useSelector(
    (state: RootState) => state.loading.effects.messages.sendTextMessage
  );
  const sendFileMessageLoading: boolean = useSelector(
    (state: RootState) => state.loading.effects.messages.sendFileMessage
  );
  const sendAudioMessageLoading: boolean = useSelector(
    (state: RootState) => state.loading.effects.messages.sendAudioMessage
  );
  const loading =
    sendTextMessageLoading || sendFileMessageLoading || sendAudioMessageLoading;

  const dispatch = useDispatch<Dispatch>();

  const height = use100vh();

  const history = useHistory<{ consultationId?: string }>();

  const { isMobile } = useMediaQuery();

  const isCurrentConsultation =
    currentConsultation?.id === activeConsultationId;
  const isSupportConsultation =
    supportConsultation?.id === activeConsultationId;
  const isExpertConsultation = expertConsultation?.id === activeConsultationId;

  useEffect(() => {
    const consultationId = history.location.state?.consultationId;
    if (!!consultationId) {
      setActiveConsultationId(consultationId);

      history.replace({
        state: undefined,
      });
    }

    let unsubscribeConsultations: () => void;
    dispatch.consultations.fetchConsultations().then((unsubscribeHandler) => {
      unsubscribeConsultations = unsubscribeHandler;
    });
    let unsubscribeCurrentUserDetails: (() => void) | undefined;
    dispatch.authentication
      .subscribeCurrentUserDetails()
      .then((unsubscribeHandler) => {
        unsubscribeCurrentUserDetails = unsubscribeHandler;
      });

    return () => {
      unsubscribeConsultations?.();
      unsubscribeCurrentUserDetails?.();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!currentConsultation || !subscription?.hasSubscriptions) {
      return;
    }

    let unsubscribe: () => void;
    dispatch.messages
      .fetchCurrentConsultationMessages(currentConsultation.id)
      .then((unsubscribeHandler) => {
        unsubscribe = unsubscribeHandler;
      });

    return () => unsubscribe?.();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentConsultation, subscription]);

  useEffect(() => {
    if (!supportConsultation || !subscription?.hasSubscriptions) {
      return;
    }

    let unsubscribe: () => void;
    dispatch.messages
      .fetchSupportConsultationMessages(supportConsultation.id)
      .then((unsubscribeHandler) => {
        unsubscribe = unsubscribeHandler;
      });

    return () => unsubscribe?.();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [supportConsultation, subscription]);

  useEffect(() => {
    if (!expertConsultation) {
      return;
    }

    let unsubscribe: () => void;
    dispatch.messages
      .fetchExpertConsultationMessages(expertConsultation.id)
      .then((unsubscribeHandler) => {
        unsubscribe = unsubscribeHandler;
      });

    return () => unsubscribe?.();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [expertConsultation]);

  useEffect(() => {
    if (!previousConsultations.length) {
      return;
    }

    previousConsultations.forEach((consultation) =>
      dispatch.messages.fetchPreviousConsultationMessages(consultation.id)
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [previousConsultations]);

  useEffect(() => {
    setMessage("");
  }, [activeConsultationId]);

  const navigateToChatTherapist = () => {
    history.push("/chat/therapist");
  };

  const navigateToSubscriptionSettings = () => {
    history.push("/profile/settings/subscription");
  };

  const getConsultationLastMessage = (consultationId: string) => {
    return messages?.[consultationId]?.slice(-1)[0];
  };

  const getChatInputValue = () => {
    if (!!fileAttachment) {
      return fileAttachment.name;
    }

    if (!!audioAttachment) {
      return "audio_message.mp3";
    }

    if (audioRecording) {
      return "Recording...";
    }

    return message;
  };

  const sendTextMessage = async () => {
    if (!message.trim().length) {
      return;
    }

    await dispatch.messages.sendTextMessage({
      consultationId: activeConsultationId,
      text: message,
    });

    setMessage("");
  };

  const sendFileMessage = async () => {
    const consultation = isCurrentConsultation
      ? currentConsultation
      : supportConsultation;
    const doctorId = consultation!.doctor.id;

    await dispatch.messages.sendFileMessage({
      consultationId: activeConsultationId,
      doctorId,
      file: fileAttachment!,
    });

    setFileAttachment(null);
  };

  const sendAudioMessage = async () => {
    const consultation = isCurrentConsultation
      ? currentConsultation
      : supportConsultation;
    const doctorId = consultation!.doctor.id;

    await dispatch.messages.sendAudioMessage({
      consultationId: activeConsultationId,
      doctorId,
      file: audioAttachment!,
    });

    setAudioAttachment(null);
  };

  const handleMessageSend = () => {
    if (!!fileAttachment) {
      return sendFileMessage();
    }

    if (!!audioAttachment) {
      return sendAudioMessage();
    }

    sendTextMessage();
  };

  const handleMessageChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setMessage(e.target.value);
  };

  const handleSetFileAttachment = (file: File | null) => {
    setMessage("");

    setFileAttachment(file);
  };

  const handleSetAudioAttachment = (blob: Blob | null) => {
    setMessage("");

    setAudioAttachment(blob);
  };

  const handleEndReached = () => {
    dispatch.consultations.updateLastSeen(activeConsultationId);
  };

  const handleChoosePlan = () => {
    subscribeinChat(user?.funnel?.code ?? "");

    navigateToSubscriptionSettings();
  };

  return (
    <ScreenContainerStyled
      HeaderComponent={<Header />}
      FooterComponent={<MobileNav />}
    >
      <Container height={height ?? 0}>
        <LeftContainer>
          <LeftContentContainer>
            {!!subscription && (
              <LeftContentHeaderContainer>
                {subscription.hasSubscriptions ? (
                  <CurrentConsultationListItem
                    consultation={currentConsultation}
                    disabled={subscription.expired}
                    onConsultationSelect={setActiveConsultationId}
                    onAboutClick={navigateToChatTherapist}
                  />
                ) : (
                  <ButtonStyled onClick={handleChoosePlan}>
                    Choose a plan
                  </ButtonStyled>
                )}
              </LeftContentHeaderContainer>
            )}
            <ConsultationsList
              title="Previous conversations"
              consultations={previousConsultations}
              activeConsultationId={activeConsultationId}
              getConsultationLastMessage={getConsultationLastMessage}
              onConsultationSelect={setActiveConsultationId}
            />
            <ConsultationsList
              title="Expert"
              consultations={!!expertConsultation ? [expertConsultation] : []}
              activeConsultationId={activeConsultationId}
              avatarBadge
              getConsultationLastMessage={getConsultationLastMessage}
              onConsultationSelect={setActiveConsultationId}
            />
            {!!subscription?.hasSubscriptions && (
              <ConsultationsList
                title="Support"
                consultations={
                  !!supportConsultation ? [supportConsultation] : []
                }
                activeConsultationId={activeConsultationId}
                getConsultationLastMessage={getConsultationLastMessage}
                onConsultationSelect={setActiveConsultationId}
              />
            )}
          </LeftContentContainer>
          {!isMobile && <Footer />}
        </LeftContainer>
        <Divider />
        {!!activeConsultationId && (
          <RightContainer>
            <MessagesList
              messages={messages?.[activeConsultationId]}
              onEndReached={handleEndReached}
            />
            {((isCurrentConsultation && !subscription?.expired) ||
              isSupportConsultation ||
              isExpertConsultation) && (
              <ChatInputContainer>
                <ChatInput
                  autoComplete="off"
                  value={getChatInputValue()}
                  disabled={
                    !!fileAttachment ||
                    !!audioAttachment ||
                    audioRecording ||
                    loading
                  }
                  onChange={handleMessageChange}
                  placeholder="Message text"
                />
                <ChatActions
                  isFileAttachment={!!fileAttachment}
                  isAudioAttachment={!!audioAttachment}
                  loading={loading}
                  setFileAttachment={handleSetFileAttachment}
                  setAudioAttachment={handleSetAudioAttachment}
                  setAudioRecording={setAudioRecording}
                  onSend={handleMessageSend}
                />
              </ChatInputContainer>
            )}
          </RightContainer>
        )}
      </Container>
    </ScreenContainerStyled>
  );
};

export default Chat;
