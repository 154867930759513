import { FC } from "react";
import { useRouteMatch, LinkProps } from "react-router-dom";
import styled from "styled-components";

import Link from "@/components/Link";
import { Color, Typography } from "@/utilities/theme";

interface AboutListItemProps {
  type: Typography;
  color: Color;
}

interface LinkStyledProps {
  active: boolean;
}

const LinkStyled = styled(Link).attrs<LinkStyledProps>(({ active }) => ({
  style: {
    fontWeight: active ? 600 : 400,
  },
}))<LinkStyledProps>`
  text-decoration: ${({ active }) => (active ? "underline" : "none")};
  color: ${({ theme }) => theme.colors.bodyC};
`;

const AboutListItem: FC<AboutListItemProps & LinkProps> = ({
  to,
  ...props
}) => {
  const match = useRouteMatch(String(to));

  return <LinkStyled to={to} active={!!match} {...props} />;
};

export default AboutListItem;
