import { FC, HTMLAttributes } from "react";
import { DateTime } from "luxon";
import styled from "styled-components";

import Text from "@/components/Text";
import { Message } from "@/models/messages";

interface TextMessageProps {
  message: Message;
  isMyMsg: boolean;
}

interface ContainerProps {
  isMyMsg: boolean;
}

const Container = styled.div<ContainerProps>`
  padding: 0.75rem 1.5rem;
  border-radius: 1rem;
  background-color: ${({ theme, isMyMsg }) =>
    isMyMsg ? theme.colors.chatUC : theme.colors.chatTC};
  margin-bottom: 0.5rem;
  align-self: ${({ isMyMsg }) => (isMyMsg ? "flex-end" : "flex-start")};
  white-space: pre-line;
  word-break: break-word;

  @media ${({ theme }) => theme.device.mobile} {
    padding: 0.5rem 1rem;
  }
`;

const Time = styled.p`
  font-family: Roboto;
  font-weight: 400;
  font-size: 0.63rem;
  line-height: 0.5rem;
  letter-spacing: 0.1px;
  color: rgba(62, 86, 89, 0.38);
  text-align: right;
`;

const TextMessage: FC<TextMessageProps & HTMLAttributes<HTMLDivElement>> = ({
  message,
  ...props
}) => {
  return (
    <Container {...props}>
      <Text type="chatP" color="bodyC">
        {message.text}
      </Text>
      <Time>
        {DateTime.fromSeconds(message.createdAt.seconds).toFormat("t")}
      </Time>
    </Container>
  );
};

export default TextMessage;
