import { FC, HTMLAttributes, useState } from "react";
import { IoChevronDown, IoChevronUp } from "react-icons/io5";
import styled from "styled-components";

import Text from "@/components/Text";
import ConsultationsListItem from "./ConsultationsListItem";
import { useMediaQuery } from "@/utilities/hooks";
import { Consultation } from "@/models/consultations";
import { Message } from "@/models/messages";

interface ConsultationsListProps {
  title: string;
  consultations: Consultation[];
  activeConsultationId: string;
  avatarBadge?: boolean;
  getConsultationLastMessage: (consultationId: string) => Message | undefined;
  onConsultationSelect: (consultationId: string) => void;
}

interface ConsultationsContainerProps {
  visible: boolean;
}

const Container = styled.div`
  @media ${({ theme }) => theme.device.mobile} {
    border-bottom: 1px solid ${({ theme }) => theme.colors.inactiveLightC};
  }
`;

const TextContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 1rem;

  @media ${({ theme }) => theme.device.mobile} {
    padding: 0.75rem 0;
    cursor: pointer;
  }
`;

const ConsultationsContainer = styled.div<ConsultationsContainerProps>`
  display: ${({ visible }) => (visible ? "block" : "none")};

  @media ${({ theme }) => theme.device.mobile} {
    display: ${({ visible }) => (visible ? "flex" : "none")};
    overflow-x: auto;
  }
`;

const ChevronDownIcon = styled(IoChevronDown)`
  color: ${({ theme }) => theme.colors.bodyC};
  font-size: 1rem;
  display: none;

  @media ${({ theme }) => theme.device.mobile} {
    display: block;
  }
`;

const ChevronUpIcon = styled(IoChevronUp)`
  color: ${({ theme }) => theme.colors.bodyC};
  font-size: 1rem;
  display: none;

  @media ${({ theme }) => theme.device.mobile} {
    display: block;
  }
`;

const ConsultationsList: FC<
  ConsultationsListProps & HTMLAttributes<HTMLDivElement>
> = ({
  title,
  consultations,
  activeConsultationId,
  avatarBadge,
  getConsultationLastMessage,
  onConsultationSelect,
  ...props
}) => {
  const [expanded, setExpanded] = useState<boolean>(false);

  const { isMobile } = useMediaQuery();

  const toggleExpanded = () => {
    if (!isMobile) {
      return;
    }

    setExpanded(!expanded);
  };

  if (!consultations.length) {
    return null;
  }

  return (
    <Container {...props}>
      <TextContainer onClick={toggleExpanded}>
        <Text type="body" color="baseC">
          {title}
        </Text>
        {expanded ? <ChevronUpIcon /> : <ChevronDownIcon />}
      </TextContainer>
      <ConsultationsContainer visible={expanded || !isMobile}>
        {consultations.map((consultation) => (
          <ConsultationsListItem
            key={consultation.id}
            consultation={consultation}
            lastMessage={getConsultationLastMessage(consultation.id)}
            active={consultation.id === activeConsultationId}
            avatarBadge={avatarBadge}
            onConsultationSelect={onConsultationSelect}
          />
        ))}
      </ConsultationsContainer>
    </Container>
  );
};

export default ConsultationsList;
