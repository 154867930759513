import { FC, HTMLAttributes, useRef, useEffect, Fragment } from "react";
import { useSelector } from "react-redux";
import { DateTime } from "luxon";
import styled from "styled-components";

import TextMessage from "./TextMessage";
import ImageMessage from "./ImageMessage";
import VideoMessage from "./VideoMessage";
import AudioMessage from "./AudioMessage";
import DateLabel from "./DateLabel";
import { RootState } from "@/utilities/store";
import { Message } from "@/models/messages";

enum Date {
  Format = "yyyy-LL-dd",
}

interface MessagesListProps {
  messages?: Message[];
  onEndReached: () => void;
}

const Container = styled.div`
  flex: 1;
  overflow-y: auto;
  display: flex;
  flex-direction: column;

  @media ${({ theme }) => theme.device.mobile} {
    padding-top: 1.5rem;
  }
`;

const MessagesList: FC<MessagesListProps & HTMLAttributes<HTMLDivElement>> = ({
  messages,
  onEndReached,
  ...props
}) => {
  const user = useSelector((state: RootState) => state.authentication.user);

  const containerRef = useRef<HTMLDivElement>(null);

  const groupedMessagesByDate =
    messages?.reduce(
      (groupedMessages: { [date: string]: Message[] }, message) => {
        const date = DateTime.fromSeconds(message.createdAt.seconds).toFormat(
          Date.Format
        );

        if (!groupedMessages[date]) {
          groupedMessages[date] = [];
        }

        groupedMessages[date].push(message);

        return groupedMessages;
      },
      {}
    ) || {};

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  const scrollToBottom = () => {
    const scrollHeight = containerRef.current?.scrollHeight || 0;
    containerRef.current?.scroll(0, scrollHeight);
  };

  const handleScroll = (e: any) => {
    const target = e.currentTarget;

    if (target.scrollHeight - target.scrollTop - target.clientHeight !== 0) {
      return;
    }

    onEndReached();
  };

  const renderMessage = (message: Message) => {
    const isMyMsg = user?.id === message.sender.id;

    switch (message.type) {
      case "text":
      case "auto-reply":
        return <TextMessage message={message} isMyMsg={isMyMsg} />;
      case "image":
        return <ImageMessage message={message} isMyMsg={isMyMsg} />;
      case "video":
        return <VideoMessage message={message} isMyMsg={isMyMsg} />;
      case "audio":
        return <AudioMessage message={message} isMyMsg={isMyMsg} />;
      default:
        return null;
    }
  };

  return (
    <Container ref={containerRef} onScroll={handleScroll} {...props}>
      {Object.entries(groupedMessagesByDate).map(([date, messages]) => (
        <Fragment key={date}>
          <DateLabel
            label={DateTime.fromFormat(date, Date.Format).toFormat(
              "dd LLLL yyyy"
            )}
          />
          {messages.map((message) => (
            <Fragment key={message.id}>{renderMessage(message)}</Fragment>
          ))}
        </Fragment>
      ))}
    </Container>
  );
};

export default MessagesList;
