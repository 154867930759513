import { FC, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { use100vh } from "react-div-100vh";
import styled from "styled-components";

import ScreenContainer from "@/components/ScreenContainer";
import Header from "@/components/Header";
import MobileNav from "@/components/MobileNav";
import Text from "@/components/Text";
import Footer from "@/components/Footer";
import JourneyList from "./components/JourneyList";
import store, { RootState, Dispatch } from "@/utilities/store";

interface ContainerProps {
  height: number;
}

const ScreenContainerStyled = styled(ScreenContainer)`
  display: flex;
`;

const Container = styled.div<ContainerProps>`
  flex: 1;
  display: grid;
  grid-template-columns: 18rem auto 1fr;
  grid-column-gap: 2rem;
  padding: 3rem 0;
  height: calc(${({ height }) => height}px - 6.25rem);

  @media ${({ theme }) => theme.device.mobile} {
    grid-template-columns: 1fr;
    grid-auto-rows: auto 1fr;
    grid-row-gap: 1.5rem;
    padding: 1.5rem 0 0.5rem 0;
    height: calc(${({ height }) => height}px - 3.5rem - 4.5rem);
    overflow-y: auto;
  }
`;

const LeftContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const LeftContentContainer = styled.div`
  @media ${({ theme }) => theme.device.mobile} {
    text-align: center;
  }
`;

const Title = styled(Text)`
  margin-bottom: 1rem;

  @media ${({ theme }) => theme.device.mobile} {
    margin-bottom: 0.75rem;
  }
`;

const Divider = styled.div`
  width: 1px;
  background-color: ${({ theme }) => theme.colors.inactiveLightC};

  @media ${({ theme }) => theme.device.mobile} {
    display: none;
  }
`;

const RightContainer = styled.div`
  overflow-y: auto;

  @media ${({ theme }) => theme.device.mobile} {
    overflow-y: visible;
  }
`;

const FooterStyled = styled(Footer)`
  padding-top: 3rem;

  @media ${({ theme }) => theme.device.mobile} {
    display: none;
  }
`;

const Journey: FC = () => {
  const user = useSelector((state: RootState) => state.authentication.user);

  const currentConsultation = useSelector((state: RootState) =>
    store.select.consultations.currentConsultation(
      state,
      user!.assignedDoctor!.id
    )
  );

  const dispatch = useDispatch<Dispatch>();

  const height = use100vh();

  useEffect(() => {
    let unsubscribe: () => void;
    dispatch.consultations.fetchConsultations().then((unsubscribeHandler) => {
      unsubscribe = unsubscribeHandler;
    });

    return () => unsubscribe?.();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ScreenContainerStyled
      HeaderComponent={<Header />}
      FooterComponent={<MobileNav />}
    >
      <Container height={height ?? 0}>
        <LeftContainer>
          <LeftContentContainer>
            <Title type="h2" color="headingText">
              My Therapy Journey
            </Title>
            <Text type="body" color="bodyC">
              Never get stuck! Your therapist will mark your achievements so
              that your progress is always clear.
            </Text>
          </LeftContentContainer>
          <FooterStyled />
        </LeftContainer>
        <Divider />
        <RightContainer>
          <JourneyList journeyItems={currentConsultation?.journey || []} />
        </RightContainer>
      </Container>
    </ScreenContainerStyled>
  );
};

export default Journey;
