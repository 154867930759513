import { Fragment, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { each, get } from "lodash";
import { useSelector } from "react-redux";
import styled from "styled-components";

import PaymentCard from "./PaymentCard";
import MastercardImg from "@/assets/images/mastercard.png";
import VisaImg from "@/assets/images/visa.png";
import AmericanExpressImg from "@/assets/images/american-express.png";
import PaypalImg from "@/assets/images/paypal.png";
import {
  trackPurchaseEvent,
  trackPaymentSubmitEvent,
  addPaymentInfoinChat,
} from "@/utilities/gtm";

export const PAYMENT_METHOD = {
  stripe: "kilo-payment-stripe",
  paypal: "kilo-payment-paypal_express",
};

const PAYMENT_METHOD_IMAGES = {
  [PAYMENT_METHOD.stripe]: [
    { src: MastercardImg, alt: "mastercard" },
    { src: VisaImg, alt: "visa" },
    { src: AmericanExpressImg, alt: "american-express" },
  ],
  [PAYMENT_METHOD.paypal]: [{ src: PaypalImg, alt: "paypal" }],
};

const PaymentCardContainer = styled.div`
  padding-bottom: 0.5rem;
`;

const KiloPayments = ({
  code,
  children,
  handleError,
  handleSuccess,
  initialProduct,
  onPaymentCardSelect,
}) => {
  let IS_LOADED = false;
  const [selectedId, setSelectedId] = useState("");
  const [loading, setLoading] = useState(true);
  const urlConfigs = useSelector(
    (state) => state.projectConfig.projectConfig?.gateway
  );

  const loadScript = (src) =>
    new Promise((resolve) => {
      if (IS_LOADED) {
        resolve();
        return;
      }

      const element = document.createElement("script");
      const attributes = {
        id: "kilo-script-" + btoa(src),
        src,
      };

      each(attributes, (value, name) => {
        if (value) {
          element[name] = value;
        }
      });

      element.onload = function () {
        IS_LOADED = true;
        resolve();
      };

      document["head"].appendChild(element);
    });

  const onSuccess = (data) => {
    trackPurchaseEvent({ orderId: data?.order_id, currency: "USD" });
    if (typeof handleSuccess === "function") {
      handleSuccess(data);
    }
    return false;
  };

  const onSubmit = (data) => {
    const price = initialProduct?.prices?.[0];
    addPaymentInfoinChat({
      code: code ?? "",
      price: price?.final_price ?? 0,
      currency: price?.currency_id ?? "",
      plan: initialProduct?.name ?? "",
    });
    trackPaymentSubmitEvent(data);
    if (data?.payment_provider === "paypal_express") {
      return;
    }
    setLoading(true);
  };

  const onError = (e) => {
    setLoading(false);
    if (typeof handleError === "function") {
      handleError(e);
    }
    return false;
  };

  const createUi = async () => {
    try {
      setLoading(true);
      const price = get(initialProduct, "prices[0].final_price");
      const product = new window.KiloProduct(
        initialProduct.key,
        initialProduct.name,
        price
      );

      if (urlConfigs.prepare_urls) {
        Object.keys(urlConfigs.prepare_urls).map(async (url) => {
          await window.kiloCheckout.create(urlConfigs.prepare_urls[url], {
            product,
            clientCode: code,
            selector: `#kilo-payment-${url}`,
            callbacks: {
              onError,
              onSubmit,
              onSuccess,
            },
          });
        });
      }
    } catch (e) {
      onError(e);
    } finally {
      setTimeout(() => {
        setLoading(false);
      }, 1000);
    }
  };

  const handleSelect = (id) => {
    if (id === selectedId) {
      return setSelectedId("");
    }

    setSelectedId(id);
  };

  const renderPaymentMethod = (url) => {
    const id = "kilo-payment-" + url;

    const selected = id === selectedId;

    switch (id) {
      case PAYMENT_METHOD.stripe:
        return (
          <PaymentCardContainer>
            <PaymentCard
              id={id}
              title="Pay with Card"
              images={PAYMENT_METHOD_IMAGES[PAYMENT_METHOD.stripe]}
              selected={selected}
              onSelect={() => handleSelect(id)}
            />
          </PaymentCardContainer>
        );
      case PAYMENT_METHOD.paypal:
        return (
          <PaymentCard
            id={id}
            title="Pay with PayPal"
            images={PAYMENT_METHOD_IMAGES[PAYMENT_METHOD.paypal]}
            selected={selected}
            onSelect={() => handleSelect(id)}
          />
        );
      default:
        return null;
    }
  };

  useEffect(() => {
    if (urlConfigs?.main) {
      loadScript(urlConfigs.main).then(() => {
        createUi();
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [urlConfigs]);

  useEffect(() => {
    if (!selectedId) {
      return;
    }

    onPaymentCardSelect?.();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedId]);

  return (
    <>
      <div hidden={loading}>
        {Object.keys(urlConfigs?.prepare_urls || []).map((url, index) => (
          <Fragment key={index}>{renderPaymentMethod(url)}</Fragment>
        ))}
      </div>
      {loading ? children : null}
    </>
  );
};

export default KiloPayments;

KiloPayments.propTypes = {
  children: PropTypes.any,
  code: PropTypes.string,
  handleError: PropTypes.func,
  handleSuccess: PropTypes.func,
  initialProduct: PropTypes.object,
};
