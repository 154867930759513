import { FC, HTMLAttributes } from "react";
import { MdChevronRight } from "react-icons/md";
import styled from "styled-components";

import Avatar from "@/components/Avatar";
import Text from "@/components/Text";
import TextButton from "@/components/TextButton";
import { useMediaQuery } from "@/utilities/hooks";
import { Consultation } from "@/models/consultations";

interface CurrentConsultationListItemProps {
  consultation?: Consultation;
  disabled?: boolean;
  onConsultationSelect: (consultationId: string) => void;
  onAboutClick: () => void;
}

interface ContainerProps {
  disabled?: boolean;
}

interface ActiveIndicatorProps {
  isOnline: boolean;
}

const Container = styled.div<ContainerProps>`
  display: flex;
  cursor: pointer;
  opacity: ${({ disabled }) => (!!disabled ? 0.5 : 1)};

  @media ${({ theme }) => theme.device.mobile} {
    padding-bottom: 0.75rem;
    border-bottom: 1px solid ${({ theme }) => theme.colors.inactiveLightC};
  }
`;

const AvatarContainer = styled.div`
  position: relative;
`;

const ActiveIndicator = styled.div<ActiveIndicatorProps>`
  width: 0.75rem;
  height: 0.75rem;
  border-radius: 50%;
  background-color: ${({ theme, isOnline }) =>
    isOnline ? theme.colors.correctC : theme.colors.promoC};
  position: absolute;
  bottom: 0.375rem;
  right: 0.375rem;
  border: 1px solid ${({ theme }) => theme.colors.white};

  @media ${({ theme }) => theme.device.mobile} {
    width: 0.5rem;
    height: 0.5rem;
    bottom: 0.25rem;
    right: 0.25rem;
  }
`;

const ContentContainer = styled.div`
  flex: 1;
  padding: 0 0.5rem 0 1.5rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;

  @media ${({ theme }) => theme.device.mobile} {
    padding: 0 0 0 1rem;
    flex-direction: row;
    align-items: center;
  }
`;

const Title = styled(Text)`
  margin-bottom: 0.13rem;

  @media ${({ theme }) => theme.device.mobile} {
    margin-bottom: 0;
  }
`;

const CurrentConsultationListItem: FC<
  CurrentConsultationListItemProps & HTMLAttributes<HTMLDivElement>
> = ({ consultation, onConsultationSelect, onAboutClick, ...props }) => {
  const { isMobile } = useMediaQuery();

  const fullName = `${consultation?.doctor.name} ${consultation?.doctor.surname}`;

  const handleClick = () => {
    onConsultationSelect(consultation!.id);
  };

  if (!consultation) {
    return null;
  }

  return (
    <Container onClick={handleClick} {...props}>
      <AvatarContainer>
        <Avatar
          src={consultation?.doctor.photo}
          size={isMobile ? "2.44rem" : "4.5rem"}
        />
        <ActiveIndicator isOnline={!!consultation?.doctor?.active} />
      </AvatarContainer>
      <ContentContainer>
        <div>
          <Title type="bodyMini" color="baseC">
            My current therapist
          </Title>
          <Text type={isMobile ? "bodyBold" : "h4Bold"} color="bodyC">
            {fullName}
          </Text>
        </div>
        <TextButton
          type="bodyMini"
          color="mainC"
          Icon={<MdChevronRight size="1rem" />}
          onClick={onAboutClick}
        >
          About
        </TextButton>
      </ContentContainer>
    </Container>
  );
};

export default CurrentConsultationListItem;
