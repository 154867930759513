import { createModel } from "@rematch/core";

import firebase from "@/utilities/firebase";
import { RootModel } from ".";

export interface Doctor {
  id: string;
  name: string;
  photo: string;
  role: string;
  summary: string;
  surname: string;
  active?: boolean;
}

interface User {
  id?: string;
  assignedDoctor?: Doctor;
  fcmTokens?: string[];
  nickname?: string;
  role?: string;
  sex?: string;
  funnel?: {
    code?: string;
  };
  email?: string;
  emailNotificationsEnabled?: boolean;
}

interface State {
  user: User | null;
}

const initialState: State = {
  user: null,
};

const authentication = createModel<RootModel>()({
  state: initialState,
  reducers: {
    setUser(state, user: User) {
      return { ...state, user };
    },
    updateUser(state, payload: User) {
      return { ...state, user: { ...state.user, ...payload } };
    },
    resetState() {
      return initialState;
    },
  },
  effects: (dispatch) => ({
    async login(user: { email: string; password: string }) {
      await firebase
        .auth()
        .signInWithEmailAndPassword(user.email, user.password);
    },
    async loginWithToken(token: string) {
      return await firebase.auth().signInWithCustomToken(token);
    },
    async fetchCurrentUserDetails() {
      const currentUser = firebase.auth().currentUser;

      const userSnapshot = await firebase
        .firestore()
        .collection("users")
        .doc(currentUser?.uid)
        .get();

      const userData = userSnapshot.data();

      if (userData?.role !== "patient") {
        dispatch.authentication.logout();
        return;
      }

      const assignedDoctorSnapshot = await userData.assignedDoctor.get();

      const assignedDoctorData = assignedDoctorSnapshot.data();

      const assignedDoctorPhoto = await dispatch.authentication.fetchPhotoUrl(
        assignedDoctorData.photo
      );

      const userDetails = {
        ...userData,
        id: userSnapshot.id,
        assignedDoctor: {
          ...assignedDoctorData,
          id: assignedDoctorSnapshot.id,
          photo: assignedDoctorPhoto,
        },
        email: currentUser?.email,
      } as User;

      dispatch.authentication.setUser(userDetails);
    },
    async subscribeCurrentUserDetails() {
      const currentUser = firebase.auth().currentUser;
      if (!currentUser) {
        return;
      }

      const unsubscribe = firebase
        .firestore()
        .collection("users")
        .doc(currentUser.uid)
        .onSnapshot(async (user) => {
          const assignedDoctor = await user.data()?.assignedDoctor?.get();

          const assignedDoctorPhoto =
            await dispatch.authentication.fetchPhotoUrl(
              assignedDoctor.data()?.photo
            );

          const userDetails = {
            ...user.data(),
            id: currentUser.uid,
            email: currentUser?.email,
            assignedDoctor: {
              ...assignedDoctor?.data(),
              id: assignedDoctor?.id,
              photo: assignedDoctorPhoto,
            },
          } as User;

          dispatch.authentication.updateUser(userDetails);
        });

      return unsubscribe;
    },
    async updateNickname(nickname: string) {
      const currentUser = firebase.auth().currentUser;

      const querySnapshot = await firebase
        .firestore()
        .collection("users")
        .where("nickname", "==", nickname)
        .limit(1)
        .get();

      if (!querySnapshot.empty) {
        return { error: "This username exists. Please choose another one." };
      }

      await firebase
        .firestore()
        .collection("users")
        .doc(currentUser?.uid)
        .update({ nickname });

      dispatch.authentication.updateUser({ nickname });
    },
    async updateUserDetails(data: User, state) {
      await firebase
        .firestore()
        .collection("users")
        .doc(state.authentication.user?.id)
        .update(data);

      dispatch.authentication.updateUser(data);
    },
    async fetchPhotoUrl(photo: string) {
      return await firebase.storage().refFromURL(photo).getDownloadURL();
    },
    async sendChangeTherapistRequest(data: {
      reason: string;
      description: string;
    }): Promise<string | undefined> {
      const result = await firebase
        .functions()
        .httpsCallable("sendChangeRequestEmail")(data);

      return result.data?.requestId;
    },
    async resetPassword(email: string) {
      await firebase.auth().sendPasswordResetEmail(email);
    },
    async disableUser({ text }: { text: string }) {
      await firebase.functions().httpsCallable("disableUser")();

      dispatch.authentication.sendEmailToAdmin({ text });

      dispatch.authentication.logout();
    },
    async sendEmailToAdmin({ text }: { text: string }) {
      await firebase.functions().httpsCallable("sendEmailToAdmin")({
        text,
      });
    },
    async setPassword({
      token,
      password,
    }: {
      token: string;
      password: string;
    }) {
      const userCredential = await dispatch.authentication.loginWithToken(
        token
      );

      await userCredential.user?.updatePassword(password);
    },
    async logout() {
      dispatch.authentication.resetState();

      dispatch.subscriptions.resetState();

      dispatch.consultations.resetState();

      dispatch.messages.resetState();

      dispatch.products.resetState();

      dispatch.projectConfig.resetState();

      const currentUser = firebase.auth().currentUser;
      if (!currentUser) {
        return;
      }

      await firebase.auth().signOut();
    },
  }),
});

const getFirebaseErrorMsgByCode = (code: string) => {
  switch (code) {
    case "auth/invalid-email":
      return {
        email: "The email address is badly formatted.",
      };
    case "auth/user-not-found":
      return {
        email:
          "There is no user record corresponding to this identifier. The user may have been deleted.",
      };
    case "auth/user-disabled":
      return {
        email: "The user account has been disabled by an administrator.",
      };
    case "auth/wrong-password":
      return {
        password:
          "The password is invalid or the user does not have a password.",
      };
    case "auth/weak-password":
      return {
        password: "Password should be at least 6 characters.",
      };
    default:
      return null;
  }
};

export { authentication, getFirebaseErrorMsgByCode };
