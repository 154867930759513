import { FC, ReactNode } from "react";
import { Props } from "react-modal";
import { MdKeyboardBackspace } from "react-icons/md";
import styled from "styled-components";

import Modal from "@/components/Modal";
import Text from "@/components/Text";
import CircularProgress from "./CircularProgress";

interface CancelSubscriptionModalProps {
  hasBackArrow?: boolean;
  progressValue: number;
  progressText: string;
  title: string;
  description: string;
  children: ReactNode;
  onBackArrowClick?: () => void;
  onClose: () => void;
}

const BackIcon = styled(MdKeyboardBackspace)`
  color: ${({ theme }) => theme.colors.mainC};
  font-size: 1.5rem;
  position: absolute;
  top: 1.5rem;
  left: 1.5rem;
  cursor: pointer;
`;

const Container = styled.div`
  padding-top: 1.5rem;
`;

const ProgressContainer = styled.div`
  display: flex;
  justify-content: center;
`;

const TitleContainer = styled.div`
  padding-top: 0.75rem;
`;

const Title = styled(Text).attrs({
  type: "h3Bold",
  color: "headingText",
})`
  text-align: center;
`;

const DescriptionContainer = styled.div`
  padding-top: 1.5rem;
`;

const Description = styled(Text).attrs({
  type: "body",
  color: "baseC",
})`
  text-align: center;
`;

const ContentContainer = styled.div`
  padding: 2rem 0 1.5rem 0;
`;

const CancelSubscriptionModal: FC<CancelSubscriptionModalProps & Props> = ({
  hasBackArrow,
  progressValue,
  progressText,
  title,
  description,
  children,
  onBackArrowClick,
  onClose,
  ...props
}) => {
  return (
    <Modal width="35.31rem" toggleModal={onClose} {...props}>
      {!!hasBackArrow && <BackIcon onClick={onBackArrowClick} />}
      <Container>
        <ProgressContainer>
          <CircularProgress value={progressValue} text={progressText} />
        </ProgressContainer>
        <TitleContainer>
          <Title>{title}</Title>
        </TitleContainer>
        <DescriptionContainer>
          <Description>{description}</Description>
        </DescriptionContainer>
        <ContentContainer>{children}</ContentContainer>
      </Container>
    </Modal>
  );
};

export default CancelSubscriptionModal;
