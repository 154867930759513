import { FC, useState, ChangeEvent, KeyboardEvent } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams, useHistory } from "react-router-dom";
import styled from "styled-components";

import ScreenContainer from "@/components/ScreenContainer";
import Header from "@/components/Header";
import Text from "@/components/Text";
import Input from "@/components/Input";
import Button from "@/components/Button";
import BackButton from "@/components/BackButton";
import { ReactComponent as MessagingImg } from "@/assets/images/create-password-messaging-img.svg";
import { useMediaQuery } from "@/utilities/hooks";
import { getFirebaseErrorMsgByCode } from "@/models/authentication";
import { RootState, Dispatch } from "@/utilities/store";
import firebase from "firebase";

interface Error {
  password?: string;
  repeatPassword?: string;
}

const ScreenContainerStyled = styled(ScreenContainer)`
  display: flex;
  align-items: center;
  justify-content: center;

  @media ${({ theme }) => theme.device.mobile} {
    align-items: flex-start;
  }
`;

const Container = styled.div`
  max-width: 64rem;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-auto-rows: minmax(33.06rem, auto);
  grid-column-gap: 1rem;
  padding: 1rem 0;
  width: 100%;

  @media ${({ theme }) => theme.device.mobile} {
    grid-template-columns: 1fr;
    grid-auto-rows: auto;
    padding: 2rem 0;
    grid-row-gap: 1.5rem;
  }
`;

const LeftContainer = styled.div`
  @media ${({ theme }) => theme.device.mobile} {
    padding: 0 1rem;
  }
`;

const RightContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;

  @media ${({ theme }) => theme.device.mobile} {
    grid-row: 1;
    display: flex;
    flex-direction: column;
  }
`;

const BackButtonStyled = styled(BackButton)`
  margin-bottom: 3rem;

  @media ${({ theme }) => theme.device.mobile} {
    margin-bottom: 2rem;
    align-self: flex-start;
  }
`;

const Title = styled(Text)`
  margin-bottom: 0.75rem;

  @media ${({ theme }) => theme.device.mobile} {
    text-align: center;
  }
`;

const Description = styled(Text)`
  @media ${({ theme }) => theme.device.mobile} {
    text-align: center;
  }
`;

const InputsContainer = styled.div`
  padding: 3rem 0;

  @media ${({ theme }) => theme.device.mobile} {
    padding: 2rem 0;
  }
`;

const InputContainer = styled.div`
  padding-bottom: 1.5rem;

  @media ${({ theme }) => theme.device.mobile} {
    padding-bottom: 1rem;
  }
`;

const ButtonStyled = styled(Button)`
  max-width: 14rem;
  width: 100%;

  @media ${({ theme }) => theme.device.mobile} {
    margin: 0 auto;
    display: block;
  }
`;

const MessagingImgStyled = styled(MessagingImg)`
  max-width: 20.88rem;
  width: 100%;
  height: auto;

  @media ${({ theme }) => theme.device.mobile} {
    max-width: 5.82rem;
  }
`;

const CreatePassword: FC = () => {
  const [password, setPassword] = useState<string>("");
  const [repeatPassword, setRepeatPassword] = useState<string>("");
  const [error, setError] = useState<Error | null>(null);

  const loading = useSelector(
    (state: RootState) => state.loading.effects.authentication.setPassword
  );

  const dispatch = useDispatch<Dispatch>();

  const params: { token: string } = useParams();

  const history = useHistory();

  const { isMobile } = useMediaQuery();

  const navigateToStart = () => {
    history.push("/start");
  };

  const navigateToLogin = () => {
    history.push("/login");
  };

  const handlePasswordChange = (e: ChangeEvent<HTMLInputElement>) => {
    setPassword(e.target.value);
  };

  const handleRepeatPasswordChange = (e: ChangeEvent<HTMLInputElement>) => {
    setRepeatPassword(e.target.value);
  };

  const handleSetPassword = async () => {
    if (!password.trim().length) {
      return setError({ password: "This field is required." });
    }

    if (!repeatPassword.trim().length) {
      return setError({ repeatPassword: "This field is required." });
    }

    if (password !== repeatPassword) {
      return setError({ password: "Passwords do not match." });
    }

    setError(null);

    try {
      await dispatch.authentication.setPassword({
        token: params.token,
        password,
      });

      navigateToStart();
    } catch (e) {
      const error = getFirebaseErrorMsgByCode((e as firebase.auth.Error).code);
      if (!error) {
        return alert((e as firebase.auth.Error).message);
      }
      setError(error);
    }
  };

  const handleRepeatPasswordKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key !== "Enter") {
      return;
    }

    handleSetPassword();
  };

  return (
    <ScreenContainerStyled HeaderComponent={<Header />}>
      <Container>
        <LeftContainer>
          {!isMobile && <BackButtonStyled onClick={navigateToLogin} />}
          <Title type="h2" color="headingText">
            Create a password
          </Title>
          <Description type={isMobile ? "body" : "h4"} color="bodyC">
            It must have a minimum of 6 characters and contain at least 1 upper
            case letter.
          </Description>
          <InputsContainer>
            <InputContainer>
              <Input
                type="password"
                name="password"
                placeholder="Your password"
                loading={loading}
                error={error?.password}
                value={password}
                onChange={handlePasswordChange}
              />
            </InputContainer>
            <Input
              type="password"
              name="repeatPassword"
              placeholder="Repeat your password"
              loading={loading}
              error={error?.repeatPassword}
              value={repeatPassword}
              onChange={handleRepeatPasswordChange}
              onKeyDown={handleRepeatPasswordKeyDown}
            />
          </InputsContainer>
          <ButtonStyled disabled={loading} onClick={handleSetPassword}>
            Continue
          </ButtonStyled>
        </LeftContainer>
        <RightContainer>
          {isMobile && <BackButtonStyled />}
          <MessagingImgStyled />
        </RightContainer>
      </Container>
    </ScreenContainerStyled>
  );
};

export default CreatePassword;
