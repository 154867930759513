import { FC, ImgHTMLAttributes } from "react";
import styled from "styled-components";

interface AvatarProps {
  size: string;
}

interface AvatarStyledProps {
  size: string;
}

const AvatarStyled = styled.img<AvatarStyledProps>`
  width: ${({ size }) => size};
  height: ${({ size }) => size};
  object-fit: cover;
  border-radius: 50%;
`;

const Avatar: FC<AvatarProps & ImgHTMLAttributes<HTMLImageElement>> = (
  props
) => {
  return <AvatarStyled {...props} />;
};

export default Avatar;
