import { FC, HTMLAttributes } from "react";
import { IconType } from "react-icons";
import styled from "styled-components";

import Text from "@/components/Text";
import JourneyListItemIcon from "./JourneyListItemIcon";
import { useMediaQuery } from "@/utilities/hooks";

interface JourneyListItemProps {
  date?: string;
  title: string;
  period?: string;
  header?: boolean;
  last?: boolean;
  iconBgColor: string;
  Icon: IconType;
}

interface ContainerProps {
  header: boolean;
}

const Container = styled.div<ContainerProps>`
  min-height: 2.81rem;
  padding: 0 1.5rem;
  display: grid;
  grid-template-columns: minmax(5.04rem, 11.03rem) 1fr;

  ${({ header }) =>
    header &&
    `
    border-radius: 0.25rem;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
    `}

  @media ${({ theme }) => theme.device.tablet} {
    grid-template-columns: 5.04rem 1fr;
    grid-column-gap: 1.5rem;
  }

  @media ${({ theme }) => theme.device.mobile} {
    grid-template-columns: 3.74rem 1fr;
    padding: 0 1rem;
    grid-column-gap: 1rem;
    height: 3.25rem;
  }
`;

const LeftContainer = styled.div`
  display: flex;
  align-items: center;
`;

const RightContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
`;

const JourneyListItemIconStyled = styled(JourneyListItemIcon)`
  margin-right: 1.5rem;

  @media ${({ theme }) => theme.device.mobile} {
    margin-right: 1rem;
  }
`;

const JourneyListItem: FC<
  JourneyListItemProps & HTMLAttributes<HTMLDivElement>
> = ({ date, title, period, header, last, iconBgColor, Icon, ...props }) => {
  const { isMobile } = useMediaQuery();

  return (
    <Container header={!!header} {...props}>
      <LeftContainer>
        {!!date && (
          <Text type={isMobile ? "bodyMini" : "h5"} color="bodyC">
            {date}
          </Text>
        )}
      </LeftContainer>
      <RightContainer>
        <TitleContainer>
          <JourneyListItemIconStyled
            header={!!header}
            last={!!last}
            iconBgColor={iconBgColor}
            Icon={Icon}
          />
          <Text
            type={
              isMobile
                ? !!header
                  ? "bodyBold"
                  : "bodyMini"
                : !!header
                ? "h4Bold"
                : "h5"
            }
            color={!!header ? "headingText" : "baseC"}
          >
            {title}
          </Text>
        </TitleContainer>
        {!!period && (
          <Text type={isMobile ? "bodyMini" : "h5"} color="bodyC">
            {period}
          </Text>
        )}
      </RightContainer>
    </Container>
  );
};

export default JourneyListItem;
