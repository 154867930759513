import { FC } from "react";
import styled from "styled-components";

import Text from "@/components/Text";

interface DateLabelProps {
  label: string;
}

const Container = styled.div`
  margin-bottom: 0.5rem;
  background-color: ${({ theme }) => theme.colors.inactiveLightC};
  border-radius: 1rem;
  padding: 0.5rem;
  align-self: center;
`;

const DateLabel: FC<DateLabelProps> = ({ label, ...props }) => {
  return (
    <Container>
      <Text type="body" color="mainC">
        {label}
      </Text>
    </Container>
  );
};

export default DateLabel;
