import { DefaultTheme } from "styled-components";

export const typography = {
  h1: {
    fontFamily: "Merriweather",
    fontSize: 50,
    lineHeight: "130%",
    fontWeight: "normal",
  },
  h2: {
    fontFamily: "Merriweather",
    fontSize: 32,
    lineHeight: "130%",
    fontWeight: "normal",
  },
  h2Bold: {
    fontFamily: "Merriweather",
    fontSize: 32,
    fontWeight: "bold",
    lineHeight: "140%",
    letterSpacing: "-0.01em",
  },
  h3Bold: {
    fontFamily: "Source Sans Pro",
    fontSize: 24,
    fontWeight: 600,
    lineHeight: "140%",
    letterSpacing: "-0.025em",
  },
  h4: {
    fontFamily: "Source Sans Pro",
    fontSize: 20,
    lineHeight: "140%",
    fontWeight: "normal",
  },
  h4Bold: {
    fontFamily: "Source Sans Pro",
    fontSize: 20,
    fontWeight: 600,
    lineHeight: "140%",
  },
  h5: {
    fontFamily: "Source Sans Pro",
    fontSize: 18,
    lineHeight: "130%",
    fontWeight: "normal",
  },
  h5Bold: {
    fontFamily: "Source Sans Pro",
    fontSize: 18,
    lineHeight: "130%",
    fontWeight: 600,
  },
  body: {
    fontFamily: "Source Sans Pro",
    fontSize: 16,
    lineHeight: "24px",
    letterSpacing: "0.02em",
    fontWeight: "normal",
  },
  bodyBold: {
    fontFamily: "Source Sans Pro",
    fontSize: 16,
    fontWeight: 600,
    lineHeight: "130%",
  },
  bodyBlack: {
    fontFamily: "Source Sans Pro",
    fontSize: 16,
    fontWeight: "bold",
    lineHeight: "24px",
    letterSpacing: "-0.02em",
  },
  bodyMini: {
    fontFamily: "Source Sans Pro",
    fontSize: 14,
    lineHeight: "140%",
    letterSpacing: "-0.02em",
    fontWeight: "normal",
  },
  btn: {
    fontFamily: "Source Sans Pro",
    fontSize: 16,
    fontWeight: 600,
    lineHeight: "140%",
    letterSpacing: "0.045em",
    textTransform: "uppercase",
  },
  chatP: {
    fontFamily: "Roboto",
    fontSize: 16,
    lineHeight: "140%",
    fontWeight: "normal",
  },
};

export const colors = {
  headingText: "#222222",
  subHeadingTxt: "#2F2F2F",
  mainC: "#4F7BEA",
  mainCHover: "#6F95F5",
  mainCPress: "#3E68D3",
  baseC: "#555555",
  inactiveOp: "rgba(62, 86, 89, 0.38)",
  inactiveC: "#D6D6D6",
  inactiveLightC: "#EEEEEE",
  dangerC: "#CD721D",
  dangerCHover: "#E38B3A",
  secondaryB: "#9CB8FF",
  secondaryBtnC: "#151A6A",
  secondaryBtnCHover: "#464EC8",
  bodyC: "#2F2F2F",
  selectC: "#D5DFFA",
  promoC: "#FF3E80",
  offBaseC: "#A7A7A7",
  correctC: "#4BBF68",
  chatTC: "#F3F4F8",
  chatUC: "#D5DFFA",
  modalC: "#F9F9F9",
  white: "#FFFFFF",
};

export const device = {
  mobile: "(max-width: 767.98px)",
  tablet: "(max-width: 991.98px)",
  desktop: "(max-width: 1199.98px)",
};

const theme: DefaultTheme = {
  typography,
  colors,
  device,
};

export default theme;

export type Color = keyof DefaultTheme["colors"];
export type Typography = keyof DefaultTheme["typography"];
