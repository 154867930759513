import { FC, useState, ChangeEvent, KeyboardEvent } from "react";
import { useSelector, useDispatch } from "react-redux";
import styled from "styled-components";

import ScreenContainer from "@/components/ScreenContainer";
import Header from "@/components/Header";
import Text from "@/components/Text";
import Input from "@/components/Input";
import Button from "@/components/Button";
import BackButton from "@/components/BackButton";
import SuccessModal from "./components/SuccessModal";
import { ReactComponent as MessagingImg } from "@/assets/images/messaging-img.svg";
import { ReactComponent as MessagingMobileImg } from "@/assets/images/messaging-mobile-img.svg";
import { useMediaQuery } from "@/utilities/hooks";
import { getFirebaseErrorMsgByCode } from "@/models/authentication";
import { RootState, Dispatch } from "@/utilities/store";
import firebase from "firebase";

const ScreenContainerStyled = styled(ScreenContainer)`
  display: flex;
  align-items: center;
  justify-content: center;

  @media ${({ theme }) => theme.device.mobile} {
    align-items: flex-start;
  }
`;

const Container = styled.div`
  max-width: 64rem;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-auto-rows: minmax(33.06rem, auto);
  grid-column-gap: 1rem;
  padding: 1rem 0;
  width: 100%;

  @media ${({ theme }) => theme.device.mobile} {
    grid-template-columns: 1fr;
    grid-auto-rows: auto;
    padding: 2rem 0;
  }
`;

const LeftContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const RightContainer = styled.div`
  display: flex;
  justify-content: flex-end;

  @media ${({ theme }) => theme.device.mobile} {
    grid-row: 1;
    display: flex;
    flex-direction: column;
  }
`;

const BackButtonStyled = styled(BackButton)`
  margin-bottom: 3rem;

  @media ${({ theme }) => theme.device.mobile} {
    margin-bottom: 2rem;
  }
`;

const Title = styled(Text)`
  margin-bottom: 0.75rem;

  @media ${({ theme }) => theme.device.mobile} {
    margin-top: 1.5rem;
    text-align: center;
  }
`;

const Description = styled(Text)`
  @media ${({ theme }) => theme.device.mobile} {
    text-align: center;
  }
`;

const InputContainer = styled.div`
  margin: 3rem 0;

  @media ${({ theme }) => theme.device.mobile} {
    margin: 2rem 0;
  }
`;

const ButtonStyled = styled(Button)`
  max-width: 14rem;

  @media ${({ theme }) => theme.device.mobile} {
    margin: 0 auto;
  }
`;

const MessagingImgStyled = styled(MessagingImg)`
  height: 100%;
  max-width: 100%;
`;

const MessagingMobileImgStyled = styled(MessagingMobileImg)`
  height: 100%;
  width: 100%;
`;

const ForgotPassword: FC = () => {
  const [email, setEmail] = useState<string>("");
  const [error, setError] = useState<string>();
  const [showSuccessModal, setShowSuccessModal] = useState<boolean>(false);

  const loading = useSelector(
    (state: RootState) => state.loading.effects.authentication.resetPassword
  );

  const { isMobile } = useMediaQuery();

  const dispatch = useDispatch<Dispatch>();

  const toggleSuccessModal = () => {
    setShowSuccessModal(!showSuccessModal);
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
  };

  const handleResetPassword = async () => {
    setError("");

    try {
      await dispatch.authentication.resetPassword(email);

      toggleSuccessModal();

      setEmail("");
    } catch (e) {
      setError(getFirebaseErrorMsgByCode((e as firebase.auth.Error).code)?.email);
    }
  };

  const handleEmailKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key !== "Enter") {
      return;
    }

    handleResetPassword();
  };

  return (
    <ScreenContainerStyled HeaderComponent={<Header />}>
      <Container>
        <LeftContainer>
          {!isMobile && <BackButtonStyled />}
          <Title type="h2" color="headingText">
            Password reset
          </Title>
          <Description type={isMobile ? "body" : "h4"} color="bodyC">
            You will receive an email with reset password link in a few minutes
          </Description>
          <InputContainer>
            <Input
              type="email"
              name="email"
              placeholder="Enter email"
              loading={loading}
              error={error}
              value={email}
              onChange={handleChange}
              onKeyDown={handleEmailKeyDown}
            />
          </InputContainer>
          <ButtonStyled disabled={loading} onClick={handleResetPassword}>
            Continue
          </ButtonStyled>
        </LeftContainer>
        <RightContainer>
          {isMobile && <BackButtonStyled />}
          {isMobile ? <MessagingMobileImgStyled /> : <MessagingImgStyled />}
        </RightContainer>
      </Container>
      <SuccessModal
        isOpen={showSuccessModal}
        toggleModal={toggleSuccessModal}
      />
    </ScreenContainerStyled>
  );
};

export default ForgotPassword;
