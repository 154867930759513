import { FC } from "react";
import RModal, { Props, Styles } from "react-modal";
import { MdClose } from "react-icons/md";
import styled from "styled-components";

interface ModalProps {
  width: string;
  toggleModal: () => void;
}

const CloseIcon = styled(MdClose)`
  font-size: 1rem;
  color: ${({ theme }) => theme.colors.offBaseC};
  cursor: pointer;
  position: absolute;
  top: 1.5rem;
  right: 1.5rem;
  z-index: 999;
`;

const Modal: FC<ModalProps & Props> = ({
  width,
  style,
  children,
  toggleModal,
  ...props
}) => {
  const customStyle: Styles = {
    overlay: {
      backgroundColor: "rgba(0, 0, 0, 0.7)",
      zIndex: 999,
      ...style?.overlay,
    },
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      transform: "translate(-50%, -50%)",
      padding: "1.5rem",
      borderRadius: "0.75rem",
      width,
      maxWidth: "calc(100vw - 2rem)",
      maxHeight: "calc(100vh - 2rem)",
      ...style?.content,
    },
  };

  return (
    <RModal
      style={customStyle}
      onRequestClose={toggleModal}
      ariaHideApp={false}
      {...props}
    >
      <CloseIcon onClick={toggleModal} />
      {children}
    </RModal>
  );
};

export default Modal;
