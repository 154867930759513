import { FC, ButtonHTMLAttributes } from "react";
import styled from "styled-components";

import Text from "@/components/Text";

const ButtonStyled = styled.button`
  height: 3rem;
  border-radius: 0.5rem;
  border: none;
  background-color: ${({ theme }) => theme.colors.mainC};
  width: 100%;
  cursor: pointer;

  :hover:enabled {
    background-color: ${({ theme }) => theme.colors.mainCHover};
    box-shadow: 0 6px 20px rgba(0, 0, 0, 0.35);
  }

  :active:enabled {
    background-color: ${({ theme }) => theme.colors.mainCPress};
    box-shadow: 0 6px 20px rgba(0, 0, 0, 0.05);
  }

  ${({ disabled, theme }) =>
    !!disabled &&
    `
    background-color: ${theme.colors.inactiveC};
    cursor: not-allowed;
  `}
`;

const Button: FC<ButtonHTMLAttributes<HTMLButtonElement>> = ({
  children,
  ...props
}) => {
  return (
    <ButtonStyled {...props}>
      <Text type="btn" color="white">
        {children}
      </Text>
    </ButtonStyled>
  );
};

export default Button;
