import { FC } from "react";
import { Props } from "react-modal";
import styled from "styled-components";

import Modal from "@/components/Modal";
import Text from "@/components/Text";
import TextButton from "@/components/TextButton";
import { useMediaQuery } from "@/utilities/hooks";

interface SuccessModalProps {
  toggleModal: () => void;
}

const TextStyled = styled(Text)`
  margin-bottom: 1rem;
`;

const ButtonContainer = styled.div`
  margin-top: 2rem;
  display: flex;
  justify-content: flex-end;
`;

const SuccessModal: FC<SuccessModalProps & Props> = ({
  toggleModal,
  ...props
}) => {
  const { isMobile } = useMediaQuery();

  return (
    <Modal width="22.13rem" toggleModal={toggleModal} {...props}>
      <TextStyled type={isMobile ? "h4Bold" : "h3Bold"} color="headingText">
        Success
      </TextStyled>
      <Text type="body" color="subHeadingTxt">
        Password reset instructions were sent to your email.
      </Text>
      <ButtonContainer>
        <TextButton type="btn" color="mainC" onClick={toggleModal}>
          Understood
        </TextButton>
      </ButtonContainer>
    </Modal>
  );
};

export default SuccessModal;
