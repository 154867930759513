import { createModel } from "@rematch/core";

import api from "@/utilities/api";
import { RootModel } from ".";

export interface Product {
  key: string;
  sku: string;
  name: string;
  is_upsell: boolean;
  image: string | null;
  is_subscription: boolean;
  description: string | null;
  type: "physical" | "digital";
  images: string[];
  prices: Price[] | [];
}

interface Price {
  price: number;
  discount: number;
  currency_id: string;
  final_price: number;
}

interface State {
  products: Product[];
}

const initialState: State = {
  products: [],
};

const products = createModel<RootModel>()({
  state: initialState,
  reducers: {
    setProducts(state, products: Product[]) {
      return { ...state, products };
    },
    resetState() {
      return initialState;
    },
  },
  effects: (dispatch) => ({
    async fetchProducts() {
      const products: Product[] | undefined = (await api.get("products"))?.data
        ?.data;
      if (!products?.length) {
        return;
      }

      dispatch.products.setProducts(products);
    },
  }),
});

export { products };
