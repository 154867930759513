import { FC, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { Carousel } from "react-responsive-carousel";
import styled from "styled-components";

import ScreenContainer from "@/components/ScreenContainer";
import Header from "@/components/Header";
import MobileNav from "@/components/MobileNav";
import Button from "@/components/Button";
import TextButton from "@/components/TextButton";
import Slide from "./components/Slide";
import Dots from "./components/Dots";
import Onboarding1Img from "@/assets/images/onboarding-1.svg";
import Onboarding2Img from "@/assets/images/onboarding-2.svg";
import Onboarding3Img from "@/assets/images/onboarding-3.svg";
import "react-responsive-carousel/lib/styles/carousel.min.css";

const SLIDES = [
  {
    img: Onboarding1Img,
    title: "Start",
    titleStrong: "your conversation",
    description: "Open your inbox and start talking with your therapist",
  },
  {
    img: Onboarding2Img,
    title: "Let your",
    titleStrong: "therapist know about you",
    description:
      "Tell them about yourself, your problems, and your needs so that they can help you better",
  },
  {
    img: Onboarding3Img,
    title: "Track",
    titleStrong: "your progress",
    description:
      "Your therapist will mark your achievements so that your progress is always clear",
  },
];

const ScreenContainerStyled = styled(ScreenContainer)`
  display: flex;
  align-items: center;
  justify-content: center;

  @media ${({ theme }) => theme.device.mobile} {
    align-items: flex-start;
  }
`;

const Container = styled.div`
  max-width: 34.5rem;
  width: 100%;
  padding: 1rem 0;

  @media ${({ theme }) => theme.device.mobile} {
    padding: 2rem 0;
  }
`;

const ButtonStyled = styled(Button)`
  max-width: 14rem;
  margin: 3rem auto;
  display: block;
`;

const TextButtonContainer = styled.div`
  margin-top: 1.5rem;
  display: flex;
  justify-content: center;
`;

const Onboarding: FC = () => {
  const [activeIndex, setActiveIndex] = useState<number>(0);

  const history = useHistory();

  const carouselRef = useRef<Carousel>(null);

  const navigateToChat = () => {
    history.push("/chat");
  };

  const handleContinue = () => {
    const nextIndex = activeIndex + 1;

    if (nextIndex === SLIDES.length) {
      return navigateToChat();
    }

    carouselRef.current?.moveTo(nextIndex);

    setActiveIndex(nextIndex);
  };

  return (
    <ScreenContainerStyled
      HeaderComponent={<Header />}
      FooterComponent={<MobileNav />}
    >
      <Container>
        <Carousel
          ref={carouselRef}
          showArrows={false}
          showStatus={false}
          showIndicators={false}
          showThumbs={false}
          swipeable={false}
        >
          {SLIDES.map((slide, index) => (
            <Slide key={index} {...slide} />
          ))}
        </Carousel>
        <ButtonStyled onClick={handleContinue}>Continue</ButtonStyled>
        <Dots activeIndex={activeIndex} length={SLIDES.length} />
        <TextButtonContainer>
          <TextButton type="btn" color="secondaryBtnC" onClick={navigateToChat}>
            SKIP explanation
          </TextButton>
        </TextButtonContainer>
      </Container>
    </ScreenContainerStyled>
  );
};

export default Onboarding;
