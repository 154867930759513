import { FC, useState, ChangeEvent, useEffect } from "react";
import { Props } from "react-modal";
import styled from "styled-components";

import Modal from "@/components/Modal";
import Text from "@/components/Text";
import Button from "@/components/Button";
import Textarea from "@/components/Textarea";
import RadioInput from "./RadioInput";
import { useMediaQuery } from "@/utilities/hooks";

const CHANGE_THERAPIST_REASONS = [
  "They weren’t responsive",
  "I didn’t feel a connection with them",
  "My needs weren’t adequately addressed",
  "I want a therapist with a different methodology",
  "Other",
];

interface ChangeTherapistModalProps {
  disabled?: boolean;
  toggleModal: () => void;
  onConfirm: ({
    reason,
    description,
  }: {
    reason: string;
    description: string;
  }) => void;
}

const Label = styled(Text)`
  margin: 2rem 0 1.5rem 0;

  @media ${({ theme }) => theme.device.mobile} {
    margin: 1.5rem 0 1rem 0;
  }
`;

const RadioInputContainer = styled.div`
  margin-bottom: 1rem;
`;

const TextareaContainer = styled.div`
  margin: 0.5rem 0 2rem 0;
`;

const ButtonStyled = styled(Button)`
  width: 16.25rem;
  margin: 0 auto;
  display: block;

  @media ${({ theme }) => theme.device.mobile} {
    width: 100%;
  }
`;

const ChangeTherapistModal: FC<ChangeTherapistModalProps & Props> = ({
  disabled,
  isOpen,
  toggleModal,
  onConfirm,
  ...props
}) => {
  const [selectedReason, setSelectedReason] = useState<string>("");
  const [description, setDescription] = useState<string>("");

  const { isMobile } = useMediaQuery();

  useEffect(() => {
    if (!isOpen) {
      return;
    }

    setSelectedReason("");
    setDescription("");
  }, [isOpen]);

  const handleChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setDescription(e.target.value);
  };

  const handleConfirm = () => {
    onConfirm({ reason: selectedReason, description });
  };

  return (
    <Modal
      isOpen={isOpen}
      width="35.31rem"
      toggleModal={toggleModal}
      {...props}
    >
      <Text type={isMobile ? "h4Bold" : "h3Bold"} color="headingText">
        Change the therapist
      </Text>
      <Label type="body" color="baseC">
        Why do you want to change your therapist?
      </Label>
      {CHANGE_THERAPIST_REASONS.map((reason, index) => (
        <RadioInputContainer key={index}>
          <RadioInput
            label={reason}
            value={reason}
            checked={reason === selectedReason}
            name="reason"
            disabled={disabled}
            onItemClick={setSelectedReason}
          />
        </RadioInputContainer>
      ))}
      <TextareaContainer>
        <Textarea
          placeholder="Please describe"
          value={description}
          disabled={disabled}
          onChange={handleChange}
        />
      </TextareaContainer>
      <ButtonStyled
        disabled={disabled || !selectedReason}
        onClick={handleConfirm}
      >
        Send request
      </ButtonStyled>
    </Modal>
  );
};

export default ChangeTherapistModal;
