import { FC, useState } from "react";
import { MdExitToApp } from "react-icons/md";
import styled from "styled-components";

import ScreenContainer from "@/components/ScreenContainer";
import Header from "@/components/Header";
import MobileNav from "@/components/MobileNav";
import SettingsCard from "@/components/SettingsCard";
import TextButton from "@/components/TextButton";
import LogoutModal from "@/components/LogoutModal";
import Link from "@/components/Link";
import StoresFooter from "@/components/StoresFooter";

const ScreenContainerStyled = styled(ScreenContainer)`
  display: flex;
`;

const Container = styled.div`
  flex: 1;
  padding: 3rem 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media ${({ theme }) => theme.device.mobile} {
    padding: 2rem 0;
  }
`;

const CardContentContainer = styled.div`
  padding: 1.5rem 0;
`;

const LinkContainer = styled.div`
  margin-bottom: 0.63rem;

  @media ${({ theme }) => theme.device.mobile} {
    margin-bottom: 1rem;
  }
`;

const LinkStyled = styled(Link)`
  text-decoration: none;
`;

const StoresFooterStyled = styled(StoresFooter)`
  padding-top: 3rem;
  margin: 0 auto;

  @media ${({ theme }) => theme.device.mobile} {
    padding-top: 2rem;
  }
`;

const Profile: FC = () => {
  const [showLogoutModal, setShowLogoutModal] = useState<boolean>(false);

  const toggleLogoutModal = () => {
    setShowLogoutModal(!showLogoutModal);
  };

  return (
    <ScreenContainerStyled
      HeaderComponent={<Header />}
      FooterComponent={<MobileNav />}
    >
      <Container>
        <SettingsCard
          title="Profile"
          FooterComponent={
            <TextButton
              type="body"
              color="baseC"
              Icon={<MdExitToApp size="1rem" />}
              onClick={toggleLogoutModal}
            >
              Log out
            </TextButton>
          }
        >
          <CardContentContainer>
            <LinkContainer>
              <LinkStyled to="/profile/settings" type="body" color="bodyC">
                Settings
              </LinkStyled>
            </LinkContainer>
            <LinkContainer>
              <LinkStyled
                to={{ pathname: "https://domental.com/contacts" }}
                target="_blank"
                type="body"
                color="bodyC"
              >
                Help
              </LinkStyled>
            </LinkContainer>
            <LinkContainer>
              <LinkStyled to="/faq" type="body" color="bodyC">
                FAQ
              </LinkStyled>
            </LinkContainer>
            <LinkStyled
              to={{ pathname: "https://domental.com" }}
              target="_blank"
              type="body"
              color="bodyC"
            >
              About DoMental
            </LinkStyled>
          </CardContentContainer>
        </SettingsCard>
        <StoresFooterStyled />
      </Container>
      <LogoutModal isOpen={showLogoutModal} toggleModal={toggleLogoutModal} />
    </ScreenContainerStyled>
  );
};

export default Profile;
