import { FC, HTMLAttributes } from "react";
import { Link as RRDLink } from "react-router-dom";
import styled from "styled-components";

import Text from "./Text";
import Link from "./Link";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
`;

const Assistance = styled(RRDLink)`
  font-weight: 600;
  color: inherit;
`;

const ButtonsContainer = styled.div`
  margin-top: 1.5rem;
  display: grid;
  grid-template-columns: repeat(3, auto);
  grid-column-gap: 0.88rem;
  align-items: center;
`;

const LinkStyled = styled(Link)`
  text-decoration: none;
`;

const Footer: FC<HTMLAttributes<HTMLDivElement>> = (props) => {
  return (
    <Container {...props}/>
  );
};

export default Footer;
