import { FC, HTMLAttributes } from "react";
import styled from "styled-components";

import { ReactComponent as SecurityIcons } from "@/assets/images/payment-security-logos.svg";

const SecurityIconsStyled = styled(SecurityIcons)`
  width: 100%;
`;

const PaymentSecurityLogos: FC<HTMLAttributes<HTMLDivElement>> = (props) => (
  <div {...props}>
    <SecurityIconsStyled />
  </div>
);

export default PaymentSecurityLogos;
