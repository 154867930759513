export default {
  "terms-and-conditions": {
    title: "Terms and Conditions",
    paragraphs: [
      "DoMental",
      "Effective March 29, 2021",
      "1. Welcome to DoMental!",
      "1.1. The following terms and conditions (hereinafter referred to as “Terms”) form part of the agreement between you and UAB Medical Score, a company established in Lithuania under the company number 305445861, with its registered office at Vaidilutes g. 79, LT-10100 Vilnius, the Republic of Lithuania (hereinafter referred to as “DoMental”, “we”, “our” or “us”), the operator of a platform that provides you (hereinafter referred to as “you”, “your” or “User”) access to our website (hereinafter referred the “Website”), app (hereinafter referred the “App”) and services (hereinafter referred to as “Services“).",
      "1.2. These Terms regulate order for the use of the DoMental Services. It is important that you have read and understood the present Terms in conjunction with our Privacy Policy before using DoMental Services. If you are not sure whether you understand all aspect of these documents, please contact us at help@domental.com and we will try to explain the aspects, which are not clear to you.",
      "1.3. You may only set up a User account with the DoMental if you agree to these Terms. If you do not agree to these Terms, you may not create a User account on the Website or App.",
      "1.4. We may change these Terms from time to time. If we do, we will let you know about any material changes, either by notifying you on the Website, App or by sending you an email. New versions of the Terms will never apply retroactively — we will tell you the exact date that they go into effect. If you keep using DoMental after a change, that means that you accept the new Terms.",
      "PLEASE NOTE THAT THE DOMENTAL WEBSITE OR APP ITSELF DOES NOT MAKE ANY MEDICAL DIAGNOSES. PLEASE SEEK THE ADVICE OF A MEDICAL PROFESSIONAL IF YOU ARE CONCERNED ABOUT YOUR HEALTH. IN CASE OF AN EMERGENCY, YOU SHOULD CONTACT THE EMERGENCY SERVICES IMMEDIATELY. ALWAYS CONSULT YOUR DOCTOR OR ANOTHER QUALIFIED HEALTH CARE PROVIDER IF YOU HAVE ANY QUESTIONS REGARDING ANY MENTAL HEALTH ISSUES. YOU SHOULD NEVER DISREGARD A DOCTOR’S PROFESSIONAL ADVICE OR CANCEL AN APPOINTMENT WITH A DOCTOR BECAUSE YOU ARE RELYING ON INFORMATION YOU HAVE READ OR HEARD IN THE DOMENTAL WEBSITE OR APP.",
      "1.5. The User must be at least 18 years old if he/she wants to use the DoMental Services. If User is under 18 years old, usage of the DoMental Services requires the consent of her/his legal guardian.",
      "2. DoMental Services",
      "2.1. You acknowledge and agree that Listeners and Therapists are neither employees nor agents nor representatives of DoMental, and DoMental assumes no responsibility for any act or omission of any such Listener or Therapist.",
      "2.2. While we hope our Services are beneficial to you, you understand, agree and acknowledge that they may not be the appropriate solution for everyone’s needs and that they may not be appropriate for every particular situation and/or may not be a complete substitute for a face-to-face examination and/or care in every particular situation.",
      "2.3. You understand and agree that, although a mental or medical health professional, physician, or other professional Listener or Therapist may have been accessed through DoMental, we cannot predict or assess the competence of, or appropriateness for your needs, of the professional or other Listener or Therapist. You also acknowledge and agree that you take full responsibility for the decision to access a Listener or Therapist through the Website or App and to continue to interact with the Listener or Therapist, and that the role of DoMental is strictly limited to providing access to such Listeners and Therapists for your consideration.",
      "2.4. Your relationship relating to the Listening and Therapy Services is strictly with the Listener or Therapist. We are not involved in any way with the actual substance of that relationship or any part of the Listening or Therapy Service (whether provided through the Website and App or not). Information shared between User and Therapist during online therapy services may be reviewed periodically by DoMental to conduct quality control, address potential safety issues, and prevent misuse of our platform, if certain suspicious or potentially harmful activity is detected.",
      "2.5. IF YOU ARE THINKING ABOUT SUICIDE OR IF YOU ARE CONSIDERING HARMING YOURSELF OR OTHERS OR IF YOU FEEL THAT ANY OTHER PERSON MAY BE IN ANY DANGER OR IF YOU HAVE ANY MEDICAL EMERGENCY, YOU MUST IMMEDIATELY CALL THE EMERGENCY SERVICE AND NOTIFY THE RELEVANT AUTHORITIES. SEEK IMMEDIATE IN PERSON ASSISTANCE. THE WEBSITE OR APP IS NOT DESIGNED FOR USE IN ANY OF THE AFOREMENTIONED CASES, THEREFORE THE LISTENERS AND THERAPISTS CANNOT PROVIDE THE ASSISTANCE REQUIRED IN ANY OF THE AFOREMENTIONED CASES.",
      "2.6. THE WEBSITE OR APP ARE NOT INTENDED FOR ANY INFORMATION REGARDING WHICH DRUGS OR MEDICAL TREATMENT MAY BE APPROPRIATE FOR YOU, AND YOU SHOULD DISREGARD ANY SUCH ADVICE IF DELIVERED THROUGH THE WEBSITE OR APP.",
      "3. Things You Should Not Do",
      "We expect all Users using DoMental Services of them to behave responsibly and help keep this a nice place. Do not do any of these things on the Website:",
      "3.1. Do not take any action that infringes or violates other people’s rights, violates the law, or breaches any contract or legal duty you have toward anyone.",
      "3.2. Do not post information you know is false, misleading, or inaccurate. Do not do anything deceptive or fraudulent.",
      "3.3. Do not offer any rewards that are illegal, violate any of DoMental’s policies, rules, or guidelines, or violate any applicable law, statute, ordinance, or regulation.",
      "3.4. Do not do anything threatening, abusive, harassing, defamatory, libelous, tortious, obscene, profane, or invasive of another person’s privacy.",
      "3.5. Do not distribute unsolicited or unauthorised advertising or promotional material, or any junk mail, spam, or chain letters.",
      "3.6. Do not run mail lists, listservs, or any kind of auto-responder or spam on or through the Website.",
      "3.7. Do not distribute viruses or any other technologies that may harm DoMental’s or the interests of Users of DoMental or otherwise interfere with or disrupt our server.",
      "3.8. Do not try to interfere with the proper workings of the Services.",
      "3.9. Do not bypass any measures we have put in place to secure the Services.",
      "3.10. Do not try to damage or get unauthorized access to any system, data, password, or other information, whether it belongs to DoMental or another party.",
      "3.11. Do not take any action that imposes an unreasonable load on our infrastructure, or on our third-party providers. (We reserve the right to determine what is reasonable.)",
      "3.12. Do not use any kind of software or device (whether it is manual or automated) to “crawl” or “spider” any part of the Website.",
      "3.13. Do not take apart or reverse engineer any aspect of DoMental in an effort to access things like source code, underlying ideas, or algorithms.",
      "4. Third-party services",
      "4.1. If DoMental website contains you links that you follow to another website, what happens there is between you and third-party service provider — not us.",
      "4.2. We cannot regulate the content of such websites or resources provided by third parties, and we may not be held liable for the content of information or websites linked included in frames. We may not be held liable for any financial or physical damages that you may incur as a result of, or in connection with, the use of such websites or resources.",
      "5. DoMental Intellectual Property",
      "5.1. The content on DoMental Website is protected in various ways. You do have the right to use it for certain personal purposes, but you can not use it for anything commercial without getting permission first.",
      "5.2. DoMental Services are legally protected in various ways, including copyrights, trademarks, service marks, patents, trade secrets, and other rights and laws. You agree to respect all copyright and other legal notices, information, and restrictions contained in any content accessed through the Website. You also agree not to change, translate, or otherwise create derivative works of the Services.",
      "6. Warranty Disclaimer",
      "6.1. This Website is presented as-is, without the warranties.",
      "6.2. You use our Services solely at your own risk. They are provided to you “as is” and “as available” and without warranty of any kind, express or implied.",
      "6.3. DOMENTAL SPECIFICALLY DISCLAIMS ANY AND ALL WARRANTIES AND CONDITIONS OF MERCHANTABILITY, NON-INFRINGEMENT, AND FITNESS FOR A PARTICULAR PURPOSE, AND ANY WARRANTIES IMPLIED BY ANY COURSE OF DEALING, COURSE OF PERFORMANCE, OR USAGE OF TRADE. NO ADVICE OR INFORMATION (ORAL OR WRITTEN) OBTAINED BY YOU FROM DOMENTAL SHALL CREATE ANY WARRANTY.",
      "7. Indemnification",
      "7.1. If you do something that results in legal action against us, or break any of the promises you make in these Terms, you agree to defend, indemnify, and hold us harmless from all liabilities, claims, and expenses (including reasonable attorneys’ fees and other legal costs) that arise from or relate to your use or misuse of DoMental Services. We reserve the right to assume the exclusive defence and control of any matter otherwise subject to this indemnification clause, in which case you agree that you will cooperate and help us in asserting any defences.",
      "8. Limitation of Liability",
      "8.1. To the fullest extent permitted by law, in no event will DoMental, its directors, employees, partners, suppliers, or content providers be liable for any indirect, incidental, punitive, consequential, special, or exemplary damages of any kind, including but not limited to damages (i) resulting from your access to, use of, or inability to access or use the Services; (ii) for any lost profits, data loss, or cost of procurement or substitute goods or services; or (iii) for any conduct of content of any third party on the Website.",
      "8.2. We do not oversee events’ performance, and we do not mediate disputes between users.",
      "8.3. DoMental is not liable for any damages or losses related to your use of the Services. We do not become involved in disputes between Users, or between Users and any third party relating to the use of the Services. We do not oversee the performance or punctuality of events. When you use the Services, you release DoMental from claims, damages, and demands of every kind — known or unknown, suspected or unsuspected, disclosed or undisclosed — arising out of or in any way related to such disputes and the Services. All content you access through the Website is at your own risk. You are solely responsible for any resulting damage or loss to any party.",
      "9. Dispute Resolution and Governing Law",
      "9.1. We are located in the Republic of Lithuania, and any disputes with us have to be handled in the Republic of Lithuania under the Republic of Lithuania law.",
      "9.2. We at DoMental encourage you to contact us if you are having an issue, before resorting to the courts. In the unfortunate situation where legal action does arise, these Terms (and all other rules, policies, or guidelines incorporated by reference) will be governed by and construed in accordance with the laws of the Republic of Lithuania, without giving effect to any principles of conflicts of law, and without application of the United Nations Convention of Controls for International Sale of Goods. You agree that any action at law or in equity arising out of or relating to these Terms, or your use or non-use of DoMental Services, shall be filed in the court located in Vilnius, and you hereby consent and submit to the personal jurisdiction of these courts for the purposes of litigating any such action.",
      "10. Other",
      "10.1. These Terms and the other material referenced in them are the entire agreement between you and DoMental with respect to the Services. They supersede all other communications and proposals (whether oral, written, or electronic) between you and DoMental with respect to the Services and govern our future relationship. If any provision of these Terms is found to be invalid under the law, that provision will be limited or eliminated to the minimum extent necessary so that the Terms otherwise will remain in full force and effect and enforceable. The failure of either you or DoMental to exercise any right provided for in these Terms in any way will not be deemed a waiver of any other rights.",
      "10.2. These Terms are personal to you. You can not assign them, transfer them, or sublicense them unless you get DoMental’s prior written consent. DoMental has the right to assign, transfer, or delegate any of its rights and obligations under these Terms without your consent. DoMental will provide you notice via email or written notice.",
      "DoMental",
      "help@domental.com",
    ],
  },
  "privacy-policy": {
    title: "Privacy Policy",
    paragraphs: [
      "DoMental",
      "Effective March 16, 2021",
      "1. How this Privacy Policy Applies",
      "1.1. This privacy policy (hereinafter referred to as the “Privacy Policy”), together with our Terms & Conditions, and any other documents referred therein, sets out the basis on which any personal data we collect from you and how it will be processed. All capitalised words are defined in our Terms and Conditions.",
      "1.2. Please read this Privacy Policy carefully to understand what data we collect from you, how we use it, when we will share it with third parties and what rights to your personal data you have.",
      "1.3. It is important that you have read and understood the present Privacy Policy in conjunction with our Terms and Conditions before using DoMental Services. If you are not sure whether you understand all aspect of these documents, please contact us at help@domental.com and we will try to explain the aspects, which are not clear to you.",
      "1.4. We may update this Privacy Policy from time to time. If we do, we’ll let you know about any material changes, either by notifying you on the Website or by sending you an email. If you keep using DoMental after a change, that means you accept this Privacy Policy.",
      "2. What We Collect",
      "2.1. In order for you to use our Services, we need to collect and process some personal information. Depending on your use of the Services, that may include, but is not limited to:",
      "your name,",
      "gender,",
      "date of birth,",
      "email address,",
      "phone number,",
      "symptoms of your illness,",
      "potential causes of your illness symptoms,",
      "health insurance (optional),",
      "medical history,",
      "any allergies you have, or",
      "further information required to verify your identity.",
      "2.2. We may collect and process personal data that you will be asked to provide on our Website:",
      "fill in forms on our Website;",
      "register and use our Services,",
      "subscribe to our newsletter,",
      "receive promotional emails or any other marketing materials;",
      "report a problem or provide any feedback with our Services; or",
      "complete any surveys on our Website.",
      "2.3. We may collect the following analytical data during each of your visits and use of our Services:",
      "technical information about your device;",
      "details of your visits (e.g. length of visits, clicks, etc.);",
      "details of conditions and symptoms searched;",
      "your IP address, operating system and browser type;",
      "information about which app store.",
      "3. How we Use this Information",
      "We use the information we collect for the following purposes:",
      "3.1. To keep your account secure;",
      "3.2. To enable us to provide you with our Services, and to improve, promote, and protect our Services;",
      "3.3. To set up and administer your account, contact you, and customize your experience on our Website, and",
      "3.4. To track use of the Services so that we can improve our Services.",
      "4. How this Information is Shared",
      "4.1. We may use technical service providers to operate and maintain our Services, who act as our processors based on a data processing agreement. In addition, we do not transfer your personal data to third parties - with the exception, when applicable, of the purposes listed below:",
      "4.2. If we are required on the basis of EU law or the law of a Member State to disclose or share your personal data.",
      "4.3. We may disclose certain data to organizations involved in clinical trials and other types of research where you have explicitly authorized us to do so.",
      "5. Retention",
      "5.1. We will retain your information as long as your account is active, as necessary to provide you with the Services or as otherwise set forth in this Policy. We will also retain and use this information as necessary for the purposes set out in this Policy and to the extent necessary to comply with our legal obligations, resolve disputes, enforce our agreements and protect DoMental’s legal rights.",
      "5.2. After the processing of your data is no longer necessary for the purposes outlined in section 3 or your account is deleted we will securely and separately store some of your data in accordance with statutory retention obligations applicable to us.",
      "5.3. We also collect and maintain aggregated, anonymized or pseudonymized information which we may retain indefinitely to protect the safety and security of our Website, improve our Services or comply with legal obligations.",
      "5.4. If the processing of your personal data is no longer necessary for any purpose it is securely erased.",
      "6. Your Rights",
      "6.1. Users residing in certain countries, including the EU, are afforded certain rights regarding their personal information. Except where an exception or exemption applies, these rights include:",
      "the right to be informed about your data we are processing;",
      "the right to object of processing your data;",
      "the right to erasure / ‘Right to be forgotten’ of your data;",
      "the right to restrict of processing your data;",
      "the right to your data portability;",
      "the right to rectification of you data;",
      "the tight to complain.",
      "6.2. You can file your express request to exercise your rights free from any charge, at any time, by using the contact details provided in this document (e.g. an email at help@domental.com ), or via your legal representative.",
    ],
  },
};
