import { FC, InputHTMLAttributes, useState } from "react";
import { IconType } from "react-icons";
import { MdVisibility, MdVisibilityOff } from "react-icons/md";
import styled from "styled-components";

import Text from "@/components/Text";
import { typography, colors } from "@/utilities/theme";

interface InputProps {
  error?: string;
  style?: any;
  loading?: boolean;
  Icon?: IconType;
  onIconClick?: () => void;
}

interface InputStyledProps {
  isIcon: boolean;
}

interface IconContainerProps {
  loading: boolean;
}

const Container = styled.div`
  width: 100%;
`;

const Error = styled(Text)`
  margin-bottom: 1rem;
`;

const InputContainer = styled.div`
  position: relative;
`;

const InputStyled = styled.input<InputStyledProps>`
  outline: none;
  height: 3.5rem;
  border: 1px solid ${({ theme }) => theme.colors.inactiveLightC};
  border-radius: 0.5rem;
  width: 100%;
  padding: 1rem ${({ isIcon }) => (isIcon ? "3.5rem" : "1rem")} 1rem 1rem;
  color: ${({ theme }) => theme.colors.bodyC};
  box-shadow: 0 6px 20px rgba(0, 0, 0, 0.05);

  ::placeholder {
    color: inherit;
  }

  @media ${({ theme }) => theme.device.mobile} {
    height: 3rem;
    padding: 0.75rem ${({ isIcon }) => (isIcon ? "3.5rem" : "1rem")} 0.75rem
      1rem;
  }
`;

const IconMainContainer = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  right: 1rem;
  display: flex;
  align-items: center;
`;

const IconContainer = styled.div<IconContainerProps>`
  cursor: ${({ loading }) => (loading ? "not-allowed" : "pointer")};
`;

const Input: FC<InputProps & InputHTMLAttributes<HTMLInputElement>> = ({
  error,
  type,
  style,
  loading,
  Icon,
  onIconClick,
  ...props
}) => {
  const [showPassword, setShowPassword] = useState<boolean>(false);

  const isPassword = type === "password";

  const inputType = isPassword ? (showPassword ? "text" : "password") : type;

  const togglePasswordVisibility = () => {
    if (!!loading) {
      return;
    }

    setShowPassword(!showPassword);
  };

  const handleIconClick = () => {
    if (!!loading) {
      return;
    }

    onIconClick?.();
  };

  return (
    <Container>
      {!!error && (
        <Error type="body" color="dangerC">
          {error}
        </Error>
      )}
      <InputContainer>
        <InputStyled
          type={inputType}
          style={{ ...typography.body, ...style }}
          isIcon={!!Icon || isPassword}
          disabled={loading}
          {...props}
        />
        {!!Icon ? (
          <IconMainContainer>
            <IconContainer loading={!!loading}>
              <Icon
                size="1.5rem"
                color={colors.baseC}
                onClick={handleIconClick}
              />
            </IconContainer>
          </IconMainContainer>
        ) : (
          isPassword &&
          (showPassword ? (
            <IconMainContainer>
              <IconContainer loading={!!loading}>
                <MdVisibilityOff
                  size="1.5rem"
                  color={colors.baseC}
                  onClick={togglePasswordVisibility}
                />
              </IconContainer>
            </IconMainContainer>
          ) : (
            <IconMainContainer>
              <IconContainer loading={!!loading}>
                <MdVisibility
                  size="1.5rem"
                  color={colors.baseC}
                  onClick={togglePasswordVisibility}
                />
              </IconContainer>
            </IconMainContainer>
          ))
        )}
      </InputContainer>
    </Container>
  );
};

export default Input;
