import { FC, HTMLAttributes } from "react";
import styled from "styled-components";

interface DotProps {
  selected?: boolean;
  next?: boolean;
}

interface DotTitleProps {
  align?: string;
}

interface DotDividerProps {
  selected?: boolean;
}

const Container = styled.div`
  padding: 0 1.5rem 1rem 1.5rem;
`;

const DotTitlesContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding-bottom: 0.38rem;
`;

const DotTitle = styled.span<DotTitleProps>`
  font-family: "Source Sans Pro", serif;
  font-weight: 400;
  font-size: 0.88rem;
  line-height: 1.23rem;
  color: #000000;
  width: 4.08rem;
  text-align: ${({ align }) => align ?? "center"};
`;

const StepsContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 0 1rem;
`;

const Dot = styled.div<DotProps>`
  width: 1.25rem;
  height: 1.25rem;
  border-radius: 50%;
  border-width: 1px;
  border-style: solid;
  border-color: #e6e6e6;

  ${({ selected }) =>
    !!selected &&
    `
    background-color: #4F7BEA;
    border-color: #4F7BEA;
  `}

  ${({ next }) => !!next && `border-color: #4F7BEA;`}
`;

const DotDivider = styled.div<DotDividerProps>`
  flex: 1;
  height: 1px;
  background-color: ${({ selected }) => (selected ? "#4f7bea" : "#E6E6E6")};
`;

const PaymentSteps: FC<HTMLAttributes<HTMLDivElement>> = (props) => (
  <Container {...props}>
    <DotTitlesContainer>
      <DotTitle align="left">Select plan</DotTitle>
      <DotTitle>Payment</DotTitle>
      <DotTitle align="right">Therapy</DotTitle>
    </DotTitlesContainer>
    <StepsContainer>
      <Dot selected />
      <DotDivider selected />
      <Dot next />
      <DotDivider />
      <Dot />
    </StepsContainer>
  </Container>
);

export default PaymentSteps;
