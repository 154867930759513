import { FC, HTMLAttributes } from "react";
import { MdStarBorder, MdStar } from "react-icons/md";
import styled from "styled-components";

interface RatingProps {
  rating: number;
  disabled?: boolean;
  onRatingClick: (rating: number) => void;
}

interface RatingContainerProps {
  disabled: boolean;
  rated: boolean;
}

const Container = styled.div`
  display: flex;
`;

const RatingContainer = styled.div<RatingContainerProps>`
  cursor: ${({ disabled }) => (disabled ? "auto" : "pointer")};
  font-size: 3rem;
  color: ${({ rated, theme }) =>
    rated ? theme.colors.mainC : theme.colors.inactiveC};
`;

const Rating: FC<RatingProps & HTMLAttributes<HTMLDivElement>> = ({
  rating,
  disabled,
  onRatingClick,
  ...props
}) => {
  const rated = (index: number) => {
    return rating > index;
  };

  const handleClick = (index: number) => {
    if (!!disabled) {
      return;
    }

    onRatingClick(index + 1);
  };

  return (
    <Container {...props}>
      {new Array(5).fill(null).map((_, index) => (
        <RatingContainer
          key={index}
          disabled={!!disabled}
          rated={rated(index)}
          onClick={() => handleClick(index)}
        >
          {rated(index) ? <MdStar /> : <MdStarBorder />}
        </RatingContainer>
      ))}
    </Container>
  );
};

export default Rating;
