import { FC, HTMLAttributes } from "react";
import { DateTime } from "luxon";
import {
  MdCompareArrows,
  MdDirectionsWalk,
  MdSearch,
  MdLocalFlorist,
  MdBeachAccess,
  MdOpenWith,
  MdAccessibility,
  MdTerrain,
  MdDone,
} from "react-icons/md";
import { BsDot } from "react-icons/bs";
import styled from "styled-components";

import JourneyListItem from "./JourneyListItem";
import { Journey } from "@/models/consultations";
import { colors } from "@/utilities/theme";

const ICON = {
  orientation: MdCompareArrows,
  "first-steps": MdDirectionsWalk,
  exploration: MdSearch,
  "continuing-on": MdLocalFlorist,
  "resting-up": MdBeachAccess,
  "broadening-scope": MdOpenWith,
  "carrying-on": MdAccessibility,
  "ending-the-journey": MdTerrain,
};

interface JourneyListProps {
  journeyItems: Journey[];
}

const Container = styled.div`
  max-width: 35.5rem;
  width: 100%;

  @media ${({ theme }) => theme.device.mobile} {
    max-width: 100%;
  }
`;

const JourneyListItemContainer = styled.div`
  padding-bottom: 1.5rem;

  @media ${({ theme }) => theme.device.mobile} {
    padding-bottom: 1rem;
  }
`;

const JourneyList: FC<JourneyListProps & HTMLAttributes<HTMLDivElement>> = ({
  journeyItems,
  ...props
}) => {
  return (
    <Container {...props}>
      {journeyItems.map((journeyItem) => (
        <JourneyListItemContainer key={journeyItem.section.id}>
          <JourneyListItem
            date={
              journeyItem.steps[0].completedAt &&
              DateTime.fromSeconds(
                journeyItem.steps[0].completedAt.seconds
              ).toFormat("yyyy.LL.dd")
            }
            title={journeyItem.section.title}
            period={journeyItem.section.period}
            header
            iconBgColor={
              journeyItem.steps[0].completed
                ? colors.mainC
                : "rgba(62, 86, 89, 0.65)"
            }
            Icon={ICON[journeyItem.section.id]}
          />
          {journeyItem.steps.map((step, index) => (
            <JourneyListItem
              key={step.id}
              title={step.title}
              last={index === journeyItem.steps.length - 1}
              iconBgColor={
                step.completed ? colors.secondaryB : colors.inactiveOp
              }
              Icon={step.completed ? MdDone : BsDot}
            />
          ))}
        </JourneyListItemContainer>
      ))}
    </Container>
  );
};

export default JourneyList;
