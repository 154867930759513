import { FC, useState, ChangeEvent, KeyboardEvent } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import styled from "styled-components";

import ScreenContainer from "@/components/ScreenContainer";
import Header from "@/components/Header";
import Text from "@/components/Text";
import Input from "@/components/Input";
import Button from "@/components/Button";
import TextButton from "@/components/TextButton";
import { ReactComponent as MessagingImg } from "@/assets/images/messaging-img.svg";
import { ReactComponent as MessagingMobileImg } from "@/assets/images/messaging-mobile-img.svg";
import { useMediaQuery } from "@/utilities/hooks";
import { getFirebaseErrorMsgByCode } from "@/models/authentication";
import { RootState, Dispatch } from "@/utilities/store";
import firebase from "firebase";

interface User {
  email: string;
  password: string;
}

interface Error {
  email?: string;
  password?: string;
}

const ScreenContainerStyled = styled(ScreenContainer)`
  display: flex;
  align-items: center;
  justify-content: center;

  @media ${({ theme }) => theme.device.mobile} {
    align-items: flex-start;
  }
`;

const Container = styled.div`
  max-width: 64rem;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-auto-rows: minmax(33.06rem, auto);
  grid-column-gap: 1rem;
  padding: 1rem 0;
  width: 100%;

  @media ${({ theme }) => theme.device.mobile} {
    grid-template-columns: 1fr;
    grid-auto-rows: auto;
    padding: 2rem 0;
  }
`;

const LeftContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const RightContainer = styled.div`
  display: flex;
  justify-content: flex-end;

  @media ${({ theme }) => theme.device.mobile} {
    grid-row: 1;
  }
`;

const Title = styled(Text)`
  margin-bottom: 3rem;

  @media ${({ theme }) => theme.device.mobile} {
    margin: 1.5rem 0 2rem 0;
    text-align: center;
  }
`;

const InputContainer = styled.div`
  margin-bottom: 1.5rem;

  @media ${({ theme }) => theme.device.mobile} {
    margin-bottom: 1rem;
  }
`;

const ButtonStyled = styled(Button)`
  max-width: 14rem;
  margin: 3rem 0 1rem 0;

  @media ${({ theme }) => theme.device.mobile} {
    margin: 2rem auto 0 auto;
  }
`;

const TextButtonStyled = styled(TextButton)`
  margin-top: 2rem;

  @media ${({ theme }) => theme.device.mobile} {
    margin: 2rem auto 0 auto;
  }
`;

const MessagingImgStyled = styled(MessagingImg)`
  height: 100%;
  max-width: 100%;
`;

const MessagingMobileImgStyled = styled(MessagingMobileImg)`
  height: 100%;
  width: 100%;
`;

const Login: FC = () => {
  const [user, setUser] = useState<User>({
    email: "",
    password: "",
  });
  const [error, setError] = useState<Error | null>(null);

  const loading = useSelector(
    (state: RootState) => state.loading.models.authentication
  );

  const { isMobile } = useMediaQuery();

  const dispatch = useDispatch<Dispatch>();

  const history = useHistory();

  const navigateToForgotPassword = () => {
    history.push("/forgot-password");
  };

  const navigateToLanding = () => {
    window.open("https://domental.com/start", "_blank");
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setUser({ ...user, [e.target.name]: e.target.value });
  };

  const handleLogin = async () => {
    setError(null);

    try {
      await dispatch.authentication.login(user);
    } catch (e) {
      setError(getFirebaseErrorMsgByCode((e as firebase.auth.Error).code));
    }
  };

  const handlePasswordKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key !== "Enter") {
      return;
    }

    handleLogin();
  };

  return (
    <ScreenContainerStyled HeaderComponent={<Header />}>
      <Container>
        <LeftContainer>
          <Title type="h2Bold" color="headingText">
            Login
          </Title>
          <InputContainer>
            <Input
              type="email"
              name="email"
              placeholder="Email"
              loading={loading}
              error={error?.email}
              value={user.email}
              onChange={handleChange}
            />
          </InputContainer>
          <Input
            type="password"
            name="password"
            placeholder="Password"
            loading={loading}
            error={error?.password}
            value={user.password}
            onChange={handleChange}
            onKeyDown={handlePasswordKeyDown}
          />
          <ButtonStyled disabled={loading} onClick={handleLogin}>
            Continue
          </ButtonStyled>
          <TextButtonStyled
            type="bodyBold"
            color="secondaryBtnC"
            onClick={navigateToForgotPassword}
          >
            I forgot my password
          </TextButtonStyled>
          <TextButtonStyled
            type="btn"
            color="secondaryBtnC"
            onClick={navigateToLanding}
          >
            Do not have an account?
          </TextButtonStyled>
        </LeftContainer>
        <RightContainer>
          {isMobile ? <MessagingMobileImgStyled /> : <MessagingImgStyled />}
        </RightContainer>
      </Container>
    </ScreenContainerStyled>
  );
};

export default Login;
