import { FC, useState } from "react";
import styled from "styled-components";

import ScreenContainer from "@/components/ScreenContainer";
import Header from "@/components/Header";
import MobileNav from "@/components/MobileNav";
import Footer from "@/components/Footer";
import Text from "@/components/Text";
import FAQItem from "./components/FAQItem";
import data from "./data";

const ScreenContainerStyled = styled(ScreenContainer)`
  display: flex;
`;

const Container = styled.div`
  flex: 1;
  padding: 3rem 0;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr auto;
  grid-row-gap: 3rem;

  @media ${({ theme }) => theme.device.mobile} {
    padding: 2rem 0;
    grid-row-gap: 2rem;
  }
`;

const Title = styled(Text)`
  margin-bottom: 3rem;

  @media ${({ theme }) => theme.device.mobile} {
    margin-bottom: 1.5rem;
  }
`;

const ItemsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 3rem;

  @media ${({ theme }) => theme.device.mobile} {
    grid-template-columns: 1fr;
  }
`;

const FAQ: FC = () => {
  const [activeIndex, setActiveIndex] = useState<number | null>(null);

  const handleItemClick = (index: number) => {
    if (activeIndex === index) {
      return setActiveIndex(null);
    }

    setActiveIndex(index);
  };

  return (
    <ScreenContainerStyled
      HeaderComponent={<Header />}
      FooterComponent={<MobileNav />}
    >
      <Container>
        <div>
          <Title type="h2" color="headingText">
            FAQ
          </Title>
          <ItemsContainer>
            {data.map((faq, index) => (
              <FAQItem
                key={index}
                active={activeIndex === index}
                onItemClick={() => handleItemClick(index)}
                {...faq}
              />
            ))}
          </ItemsContainer>
        </div>
        <Footer />
      </Container>
    </ScreenContainerStyled>
  );
};

export default FAQ;
