import { FC } from "react";
import { useDispatch } from "react-redux";
import { Props } from "react-modal";
import styled from "styled-components";

import Modal from "@/components/Modal";
import Text from "@/components/Text";
import TextButton from "@/components/TextButton";
import { useMediaQuery } from "@/utilities/hooks";
import { Dispatch } from "@/utilities/store";

interface LogoutModalProps {
  toggleModal: () => void;
}

const TextStyled = styled(Text)`
  margin-bottom: 1rem;
`;

const ButtonsContainer = styled.div`
  margin-top: 2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const LogoutModal: FC<LogoutModalProps & Props> = ({
  toggleModal,
  ...props
}) => {
  const { isMobile } = useMediaQuery();

  const dispatch = useDispatch<Dispatch>();

  const handleConfirm = async () => {
    await dispatch.authentication.logout();

    toggleModal();
  };

  return (
    <Modal width="17.6rem" toggleModal={toggleModal} {...props}>
      <TextStyled type={isMobile ? "h4Bold" : "h3Bold"} color="headingText">
        Logout
      </TextStyled>
      <Text type="body" color="subHeadingTxt">
        Are you sure you want to log out?
      </Text>
      <ButtonsContainer>
        <TextButton type="btn" color="baseC" onClick={toggleModal}>
          Cancel
        </TextButton>
        <TextButton type="btn" color="correctC" onClick={handleConfirm}>
          OK, LoG me OUT
        </TextButton>
      </ButtonsContainer>
    </Modal>
  );
};

export default LogoutModal;
