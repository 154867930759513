import { FC, HTMLAttributes } from "react";
import styled from "styled-components";

import Button from "@/components/Button";
import DiscountBadge from "@/assets/images/discount-badge.png";
import { Product } from "@/models/products";

interface SubscriptionCardProps {
  product: Product;
  isSpecialOffer: boolean;
  onStartClick: (product: Product) => void;
}

interface ContainerProps {
  withSpace: boolean;
}

const Container = styled.div<ContainerProps>`
  flex: 1;
  position: relative;
  padding-top: ${({ withSpace }) => (withSpace ? "2rem" : 0)};

  @media ${({ theme }) => theme.device.mobile} {
    padding-top: ${({ withSpace }) => (withSpace ? "0.75rem" : 0)};
  }
`;

const SpecialOfferContainer = styled.div`
  width: calc(100% - 3rem);
  height: 2rem;
  background-color: #ff3e80;
  border-top-left-radius: 3rem;
  border-top-right-radius: 3rem;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const SpecialOfferTitle = styled.h5`
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-size: 0.88rem;
  line-height: 1rem;
  color: #ffffff;
  text-transform: uppercase;
`;

const DiscountBadgeStyled = styled.img`
  position: absolute;
  top: -2rem;
  right: 1rem;
  width: 4.06rem;
  height: 4rem;
`;

const ContentContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  border-radius: 0.75rem;
  border: 0.06rem solid #767e93;
  overflow: hidden;
`;

const Title = styled.h3`
  font-family: "Source Sans Pro", sans-serif;
  font-weight: 600;
  font-size: 1.25rem;
  line-height: 1.75rem;
  height: 4rem;
  padding-bottom: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  background-color: #001b5f;
  margin-bottom: 0rem;
  position: relative;

  &::after {
    content: "";
    width: 100%;
    height: 1rem;
    background: #ffffff;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    border-top-left-radius: 0.75rem;
    border-top-right-radius: 0.75rem;
    border-top: 0.06rem solid #767e93;
  }

  @media ${({ theme }) => theme.device.mobile} {
    font-size: 1rem;
    line-height: 1.26rem;
  }
`;

const ContentCenterContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 1.5rem;
`;

const PriceWithoutDiscount = styled.span`
  font-family: "Source Sans Pro", sans-serif;
  font-weight: 600;
  font-size: 1rem;
  line-height: 1rem;
  color: rgba(0, 0, 0, 0.58);
  text-decoration-line: line-through;
  width: 6.56rem;
  text-align: right;

  @media ${({ theme }) => theme.device.mobile} {
    width: 5.31rem;
  }
`;

const Price = styled.span`
  font-family: "Merriweather", serif;
  font-weight: 700;
  font-size: 3rem;
  line-height: 3rem;
  color: #222222;
  margin-bottom: 0.13rem;
`;

const PricePeriod = styled.span`
  font-family: "Source Sans Pro", sans-serif;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1rem;
  color: rgba(0, 0, 0, 0.58);
`;

const Description = styled.p`
  font-family: "Source Sans Pro", sans-serif;
  font-weight: 400;
  font-size: 0.88rem;
  line-height: 1.1rem;
  margin-top: 1.5rem;
  color: rgba(0, 0, 0, 0.58);
`;

const ButtonContainer = styled.div`
  padding: 1rem;
`;

const SubscriptionCard: FC<
  SubscriptionCardProps & HTMLAttributes<HTMLDivElement>
> = ({ product, isSpecialOffer, onStartClick, ...props }) => {
  const name = product?.name ?? "";
  const description = product?.description ?? "";
  const finalPrice = product?.prices?.[0]?.final_price ?? 0;

  const getWeekPrice = () => {
    switch (product?.key) {
      case "test-1":
        return Math.round(finalPrice / (1 * 4));
      case "test-2":
        return Math.round(finalPrice / (3 * 4));
      case "test-3":
        return Math.round(finalPrice / (6 * 4));
      default:
        return 0;
    }
  };

  const handleClick = () => {
    onStartClick(product);
  };

  return (
    <Container withSpace={!isSpecialOffer} {...props}>
      {isSpecialOffer && (
        <SpecialOfferContainer>
          <SpecialOfferTitle>Most popular</SpecialOfferTitle>
          <DiscountBadgeStyled src={DiscountBadge} />
        </SpecialOfferContainer>
      )}
      <ContentContainer>
        <Title>{name}</Title>
        <ContentCenterContainer>
          <PriceWithoutDiscount>$65</PriceWithoutDiscount>
          <Price>
            ${getWeekPrice()}
            <PricePeriod>/ week</PricePeriod>
          </Price>
          <Description dangerouslySetInnerHTML={{ __html: description }} />
        </ContentCenterContainer>
        <ButtonContainer>
          <Button onClick={handleClick}>Start now</Button>
        </ButtonContainer>
      </ContentContainer>
    </Container>
  );
};

export default SubscriptionCard;
