import { Models } from "@rematch/core";

import { authentication } from "./authentication";
import { consultations } from "./consultations";
import { messages } from "./messages";
import { subscriptions } from "./subscriptions";
import { products } from "./products";
import { projectConfig } from "./projectConfig";

export interface RootModel extends Models<RootModel> {
  authentication: typeof authentication;
  consultations: typeof consultations;
  messages: typeof messages;
  subscriptions: typeof subscriptions;
  products: typeof products;
  projectConfig: typeof projectConfig;
}

export const models: RootModel = {
  authentication,
  consultations,
  messages,
  subscriptions,
  products,
  projectConfig,
};
