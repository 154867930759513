import { createModel } from "@rematch/core";

import api from "@/utilities/api";
import firebase from "@/utilities/firebase";
import { RootModel } from ".";

interface Subscription {
  active: SubscriptionData | null;
  valid: SubscriptionData | null;
  expired: boolean;
  hasSubscriptions: boolean;
}

interface SubscriptionData {
  id: number;
  status: "active" | "canceled";
  product_id: number;
  currency_id: string;
  billing_amount: number;
  billing_period: number;
  billing_period_type: string;
  payment_count: number;
  last_payment_at: string;
  next_payment_at: string;
  updated_at: string;
}

interface State {
  subscription: Subscription | null;
}

const initialState: State = {
  subscription: null,
};

const subscriptions = createModel<RootModel>()({
  state: initialState,
  reducers: {
    setSubscription(state, subscription: Subscription) {
      return { ...state, subscription };
    },
    resetState() {
      return initialState;
    },
  },
  effects: (dispatch) => ({
    async checkSubscriptions() {
      const subscription: Subscription = (
        await firebase.functions().httpsCallable("checkSubscriptions")()
      )?.data;

      dispatch.subscriptions.setSubscription(subscription);
    },
    async cancelSubscription(id: number, state) {
      const code = state.authentication.user?.funnel?.code;
      if (!code) {
        return;
      }

      await api.post("payments/subscriptions/cancel", {
        client_code: code,
        subscription_id: id,
        data: {},
      });

      await dispatch.subscriptions.checkSubscriptions();
    },
    async sendUnsubscribedEmail(payload: { rating: number; reason: string }) {
      const currentUser = firebase.auth().currentUser;
      if (!currentUser?.email) {
        return;
      }

      await firebase.functions().httpsCallable("sendUnsubscribedEmail")({
        ...payload,
        email: currentUser.email,
      });
    },
  }),
});

export { subscriptions };
