import { FC, HTMLAttributes, useState } from "react";
import { useSelector } from "react-redux";
import { LinkProps } from "react-router-dom";
import { IoChevronDown, IoChatbubble, IoFlag } from "react-icons/io5";
import { MdExitToApp } from "react-icons/md";
import styled from "styled-components";

import Avatar from "./Avatar";
import NavLink from "./NavLink";
import TextButton from "./TextButton";
import LogoutModal from "./LogoutModal";
import Link from "./Link";
import { ReactComponent as BrandLogo } from "@/assets/images/brand-logo.svg";
import DefaultProfileImg from "@/assets/images/default-profile-img.svg";
import { RootState } from "@/utilities/store";

interface DropDownContainerProps {
  show: boolean;
}

const Container = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  border-bottom: 1px solid ${({ theme }) => theme.colors.inactiveLightC};
  background-color: ${({ theme }) => theme.colors.white};
`;

const ContentContainer = styled.div`
  max-width: 71.25rem;
  margin: 0 auto;
  height: 6.25rem;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media ${({ theme }) => theme.device.desktop} {
    padding: 0 1rem;
  }

  @media ${({ theme }) => theme.device.mobile} {
    height: 3.5rem;
    justify-content: center;
  }
`;

const BrandLogoStyled = styled(BrandLogo)`
  width: 9.92rem;

  @media ${({ theme }) => theme.device.mobile} {
    width: 6.89rem;
  }
`;

const AvatarContainer = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  position: relative;

  @media ${({ theme }) => theme.device.mobile} {
    display: none;
  }
`;

const ChevronDownIcon = styled(IoChevronDown)`
  font-size: 1rem;
  margin-left: 0.5rem;
`;

const NavLinksContainer = styled.div`
  flex: 1;
  height: 100%;
  padding: 0 4rem;
  display: grid;
  grid-template-columns: repeat(2, 9rem);
  grid-column-gap: 4rem;

  @media ${({ theme }) => theme.device.mobile} {
    display: none;
  }
`;

const DropDownContainer = styled.div<DropDownContainerProps>`
  min-width: 9.01rem;
  background-color: ${({ theme }) => theme.colors.white};
  position: absolute;
  bottom: calc(-100% - 0.75rem - 10.5rem);
  right: 0;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.15);
  border-radius: 0.75rem;
  z-index: 999;
  padding: 1rem;
  display: ${({ show }) => (show ? "block" : "none")};
`;

const DropDownLinkContainer = styled.div`
  margin-bottom: 0.75rem;
`;

const DropDownLinkStyled = styled(Link)`
  text-decoration: none;
`;

const DropDownDivider = styled.div`
  margin-bottom: 1rem;
  height: 1px;
  background-color: ${({ theme }) => theme.colors.inactiveLightC};
`;

const DropDownLink: FC<LinkProps> = ({ type, color, children, ...props }) => {
  return (
    <DropDownLinkContainer>
      <DropDownLinkStyled type="body" color="headingText" {...props}>
        {children}
      </DropDownLinkStyled>
    </DropDownLinkContainer>
  );
};

const Header: FC<HTMLAttributes<HTMLDivElement>> = (props) => {
  const [showDropDown, setShowDropDown] = useState<boolean>(false);
  const [showLogoutModal, setShowLogoutModal] = useState<boolean>(false);

  const user = useSelector((state: RootState) => state.authentication.user);
  const subscription = useSelector(
    (state: RootState) => state.subscriptions.subscription
  );

  const isNickname = !!user?.nickname;

  const toggleDropDown = () => {
    setShowDropDown(!showDropDown);
  };

  const toggleLogoutModal = () => {
    setShowLogoutModal(!showLogoutModal);
  };

  const handleAvatarClick = () => {
    if (!isNickname) {
      return toggleLogoutModal();
    }

    toggleDropDown();
  };

  return (
    <Container {...props}>
      <ContentContainer>
        <BrandLogoStyled />
        {!!user && (
          <>
            {isNickname && (
              <NavLinksContainer>
                <NavLink to="/chat" Icon={IoChatbubble}>
                  Inbox
                </NavLink>
                {!!subscription?.hasSubscriptions && (
                  <NavLink to="/journey" Icon={IoFlag}>
                    Journey
                  </NavLink>
                )}
              </NavLinksContainer>
            )}
            <AvatarContainer onClick={handleAvatarClick}>
              <Avatar size="3rem" src={DefaultProfileImg} />
              {isNickname && <ChevronDownIcon />}
              <DropDownContainer show={showDropDown}>
                <DropDownLink to="/profile/settings">Settings</DropDownLink>
                <DropDownLink
                  to={{ pathname: "https://domental.com/contacts" }}
                  target="_blank"
                >
                  Help
                </DropDownLink>
                <DropDownLink to="/faq">FAQ</DropDownLink>
                <DropDownLink
                  to={{ pathname: "https://domental.com" }}
                  target="_blank"
                >
                  About DoMental
                </DropDownLink>
                <DropDownDivider />
                <TextButton
                  type="body"
                  color="baseC"
                  Icon={<MdExitToApp size="1rem" />}
                  onClick={toggleLogoutModal}
                >
                  Log out
                </TextButton>
              </DropDownContainer>
            </AvatarContainer>
          </>
        )}
      </ContentContainer>
      <LogoutModal isOpen={showLogoutModal} toggleModal={toggleLogoutModal} />
    </Container>
  );
};

export default Header;
