import { FC, HTMLAttributes } from "react";
import {
  CircularProgressbarWithChildren,
  buildStyles,
} from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import styled from "styled-components";

import Text from "@/components/Text";

interface CircularProgressProps {
  value: number;
  text: string;
}

const Container = styled.div`
  width: 3.81rem;
  height: 3.81rem;
`;

const CircularProgressStyled = styled(CircularProgressbarWithChildren).attrs(
  ({ theme }) => ({
    strokeWidth: 5,
    styles: buildStyles({
      trailColor: theme.colors.inactiveLightC,
      pathColor: theme.colors.mainCHover,
    }),
  })
)``;

const CircularProgress: FC<
  CircularProgressProps & HTMLAttributes<HTMLDivElement>
> = ({ value, text, ...props }) => {
  return (
    <Container {...props}>
      <CircularProgressStyled value={value}>
        <Text type="h3Bold" color="offBaseC">
          {text}
        </Text>
      </CircularProgressStyled>
    </Container>
  );
};

export default CircularProgress;
