import { FC } from "react";
import RSwitch, { ReactSwitchProps } from "react-switch";

import { colors } from "@/utilities/theme";

const Switch: FC<ReactSwitchProps> = (props) => {
  return (
    <RSwitch
      uncheckedIcon={false}
      checkedIcon={false}
      offColor={colors.offBaseC}
      onColor={colors.mainC}
      {...props}
    />
  );
};

export default Switch;
