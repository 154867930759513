import { createModel } from "@rematch/core";

import api from "@/utilities/api";
import { RootModel } from ".";

interface ProjectConfig {
  name: string;
  env: "production" | "staging" | "development";
  settings: any[];
  gateway: {
    main: string;
    prepare_url: string;
    prepare_urls: { [key: string]: string };
  };
}

interface State {
  projectConfig: ProjectConfig | null;
}

const initialState: State = {
  projectConfig: null,
};

const projectConfig = createModel<RootModel>()({
  state: initialState,
  reducers: {
    setProjectConfig(state, projectConfig: ProjectConfig) {
      return { ...state, projectConfig };
    },
    resetState() {
      return initialState;
    },
  },
  effects: (dispatch) => ({
    async fetchProjectConfig() {
      const projectConfig: ProjectConfig | undefined = (
        await api.get("project")
      )?.data?.data;
      if (!projectConfig) {
        return;
      }

      dispatch.projectConfig.setProjectConfig(projectConfig);
    },
  }),
});

export { projectConfig };
