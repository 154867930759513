import { FC, useState, HTMLAttributes } from "react";
import { IoChevronDown, IoChevronUp } from "react-icons/io5";
import styled from "styled-components";

import Text from "@/components/Text";
import AboutListItem from "./AboutListItem";
import { colors } from "@/utilities/theme";
import { useMediaQuery } from "@/utilities/hooks";

interface Item {
  id: string;
  title: string;
}

interface AboutListProps {
  items: Item[];
}

const HeaderContainer = styled.div`
  padding-bottom: 0.75rem;
  border-bottom: 1px solid ${({ theme }) => theme.colors.inactiveLightC};
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media ${({ theme }) => theme.device.mobile} {
    cursor: pointer;
  }
`;

const ItemsContainer = styled.div`
  padding-top: 0.13rem;
`;

const AboutListItemContainer = styled.div`
  padding-top: 0.63rem;
`;

const AboutList: FC<AboutListProps & HTMLAttributes<HTMLDivElement>> = ({
  items,
  ...props
}) => {
  const [expanded, setExpanded] = useState<boolean>(true);

  const { isMobile } = useMediaQuery();

  const toggleExpanded = () => {
    if (!isMobile) {
      return;
    }

    setExpanded(!expanded);
  };

  return (
    <div {...props}>
      <HeaderContainer onClick={toggleExpanded}>
        <Text type="bodyBold" color="headingText">
          About DoMental
        </Text>
        {isMobile &&
          (expanded ? (
            <IoChevronUp size="1rem" color={colors.mainC} />
          ) : (
            <IoChevronDown size="1rem" color={colors.mainC} />
          ))}
      </HeaderContainer>
      {expanded && (
        <ItemsContainer>
          {items.map((item) => (
            <AboutListItemContainer key={item.id}>
              <AboutListItem to={`/about/${item.id}`} type="body" color="bodyC">
                {item.title}
              </AboutListItem>
            </AboutListItemContainer>
          ))}
        </ItemsContainer>
      )}
    </div>
  );
};

export default AboutList;
