import { FC } from "react";
import { useHistory } from "react-router";
import { IconBaseProps } from "react-icons";
import { MdKeyboardBackspace } from "react-icons/md";
import styled from "styled-components";

const KeyboardBackspaceIcon = styled(MdKeyboardBackspace)`
  font-size: 1.5rem;
  color: ${({ theme }) => theme.colors.mainC};
  cursor: pointer;
`;

const BackButton: FC<IconBaseProps> = (props) => {
  const history = useHistory();

  const navigateBack = () => {
    history.goBack();
  };

  return <KeyboardBackspaceIcon onClick={navigateBack} {...props} />;
};

export default BackButton;
