import { FC, HtmlHTMLAttributes } from "react";
import { IoMdSend, IoMdClose } from "react-icons/io";
import styled from "styled-components";

import AttachFile from "./AttachFile";
import AudioRecorder from "./AudioRecorder";
import theme from "@/utilities/theme";

interface ChatActionsProps {
  isFileAttachment: boolean;
  isAudioAttachment: boolean;
  loading: boolean;
  setFileAttachment: (file: File | null) => void;
  setAudioAttachment: (blob: Blob | null) => void;
  setAudioRecording: (value: boolean) => void;
  onSend: () => void;
}

interface ContainerProps {
  isAttachment: boolean;
}

interface SendIconProps {
  loading: boolean;
}

interface CloseIconProps {
  loading: boolean;
}

const Container = styled.div<ContainerProps>`
  display: grid;
  grid-template-columns: repeat(
    ${({ isAttachment }) => (isAttachment ? 2 : 3)},
    auto
  );
  grid-column-gap: 1rem;
  align-items: center;

  @media ${({ theme }) => theme.device.mobile} {
    grid-column-gap: 0.75rem;
  }
`;

const SendIcon = styled(IoMdSend)<SendIconProps>`
  font-size: 1.5rem;
  color: ${({ theme }) => theme.colors.baseC};
  cursor: ${({ loading }) => (loading ? "not-allowed" : "pointer")};
`;

const CloseIcon = styled(IoMdClose)<CloseIconProps>`
  font-size: 1.5rem;
  color: #ff0000;
  cursor: ${({ loading }) => (loading ? "not-allowed" : "pointer")};
`;

const ChatActions: FC<ChatActionsProps & HtmlHTMLAttributes<HTMLDivElement>> =
  ({
    isFileAttachment,
    isAudioAttachment,
    loading,
    setFileAttachment,
    setAudioAttachment,
    setAudioRecording,
    onSend,
    ...props
  }) => {
    const clearAudioAttachment = () => {
      setAudioAttachment(null);

      setAudioRecording(false);
    };

    const handleSend = () => {
      if (loading) {
        return;
      }

      onSend();
    };

    const handleSetFileAttachment = (file: File | null) => {
      if (loading) {
        return;
      }

      setFileAttachment(file);
    };

    const handleAudioRecorderStart = () => {
      setAudioRecording(true);
    };

    const handleAudioRecorderStop = (blob: Blob) => {
      setAudioAttachment(blob);

      setAudioRecording(false);
    };

    const handleAudioAttachmentRemove = () => {
      if (loading) {
        return;
      }

      clearAudioAttachment();
    };

    return (
      <Container
        isAttachment={isFileAttachment || isAudioAttachment}
        {...props}
      >
        {!isAudioAttachment && (
          <AttachFile
            isAttachment={isFileAttachment}
            loading={loading}
            setAttachment={handleSetFileAttachment}
          />
        )}
        {!isFileAttachment &&
          (!isAudioAttachment ? (
            <AudioRecorder
              backgroundColor={theme.colors.promoC}
              recordingBackgroundColor={theme.colors.correctC}
              loading={loading}
              onStart={handleAudioRecorderStart}
              onStop={handleAudioRecorderStop}
              onError={clearAudioAttachment}
            />
          ) : (
            <CloseIcon
              loading={loading}
              onClick={handleAudioAttachmentRemove}
              color={theme.colors.promoC}
            />
          ))}
        <SendIcon loading={loading} onClick={handleSend} />
      </Container>
    );
  };

export default ChatActions;
