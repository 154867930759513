import { FC, HTMLAttributes, useRef, useEffect, useState } from "react";
import { DateTime } from "luxon";
import Loader from "react-loader-spinner";
import styled from "styled-components";

import Lightbox from "@/components/Lightbox";
import { useFetchFirebaseStorageFileUrl } from "@/utilities/hooks";
import { Message } from "@/models/messages";
import { colors } from "@/utilities/theme";

interface ImageMessageProps {
  message: Message;
  isMyMsg: boolean;
}

interface ContainerProps {
  isMyMsg: boolean;
  height: number;
  loading: boolean;
}

const Container = styled.div<ContainerProps>`
  max-width: 320px;
  width: 100%;
  min-height: ${({ height }) => height}px;
  border-radius: 1rem;
  background-color: ${({ theme, isMyMsg }) =>
    isMyMsg ? theme.colors.chatUC : theme.colors.chatTC};
  margin-bottom: 0.5rem;
  align-self: ${({ isMyMsg }) => (isMyMsg ? "flex-end" : "flex-start")};
  position: relative;
  overflow: hidden;
  cursor: ${({ loading }) => (loading ? "not-allowed" : "pointer")};
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Time = styled.p`
  font-family: Roboto;
  font-weight: 400;
  font-size: 0.63rem;
  line-height: 0.5rem;
  letter-spacing: 0.1px;
  color: ${({ theme }) => theme.colors.white};
  position: absolute;
  bottom: 0.75rem;
  right: 1.5rem;

  @media ${({ theme }) => theme.device.mobile} {
    bottom: 0.5rem;
    right: 1rem;
  }
`;

const ImageMessage: FC<ImageMessageProps & HTMLAttributes<HTMLDivElement>> = ({
  message,
  ...props
}) => {
  const [containerWidth, setContainerWidth] = useState<number>(320);
  const [showLightbox, setShowLightbox] = useState<boolean>(false);

  const imageUrl = useFetchFirebaseStorageFileUrl(message.file!);

  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const toggleLightbox = () => {
    setShowLightbox(!showLightbox);
  };

  const handleMessageClick = () => {
    if (!imageUrl) {
      return;
    }

    toggleLightbox();
  };

  const handleResize = () => {
    if (!containerRef.current) {
      return;
    }

    setContainerWidth(containerRef.current.offsetWidth);
  };

  return (
    <>
      <Container
        ref={containerRef}
        height={(containerWidth / 4) * 3}
        loading={!imageUrl}
        onClick={handleMessageClick}
        {...props}
      >
        {!imageUrl ? (
          <Loader type="Circles" width={50} height={50} color={colors.mainC} />
        ) : (
          <img src={imageUrl} alt={message.type} width="100%" />
        )}
        <Time>
          {DateTime.fromSeconds(message.createdAt.seconds).toFormat("t")}
        </Time>
      </Container>
      {showLightbox && (
        <Lightbox mainSrc={imageUrl!} onCloseRequest={toggleLightbox} />
      )}
    </>
  );
};

export default ImageMessage;
