import { FC, useState } from "react";
import {
  NavLink as RRDNavLink,
  NavLinkProps as RRDNavLinkProps,
  match,
} from "react-router-dom";
import { IconType } from "react-icons";
import styled from "styled-components";

import Text from "./Text";
import { useMediaQuery } from "@/utilities/hooks";
import { colors } from "@/utilities/theme";

interface NavLinkProps {
  Icon: IconType;
}

interface ContainerProps {
  isActive: boolean;
  isMobile: boolean;
}

interface NavLinkStyledProps {
  isMobile: boolean;
}

interface TextStyledProps {
  isMobile: boolean;
}

const Container = styled.div<ContainerProps>`
  display: flex;
  align-items: center;
  justify-content: center;

  ${({ isMobile, isActive, theme }) =>
    isMobile
      ? `
        border-top: 0.25rem solid ${
          isActive ? theme.colors.mainC : theme.colors.inactiveLightC
        };
      `
      : `
        border-bottom: 0.25rem solid ${
          isActive ? theme.colors.mainC : "transparent"
        };
      `}
`;

const NavLinkStyled = styled(RRDNavLink)<NavLinkStyledProps>`
  text-decoration: none;
  display: flex;
  align-items: center;

  ${({ isMobile }) =>
    isMobile &&
    `
    flex-direction: column;
    `}
`;

const TextStyled = styled(Text)<TextStyledProps>`
  ${({ isMobile }) =>
    isMobile
      ? `
        margin-top: 0.25rem;
      `
      : `
        margin-left: 0.75rem;
      `}
`;

const NavLink: FC<NavLinkProps & RRDNavLinkProps> = ({
  Icon,
  children,
  ...props
}) => {
  const [isActive, setIsActive] = useState<boolean>(false);

  const { isMobile } = useMediaQuery();

  const handleIsActive = (match: match<{}> | null) => {
    const active = !!match;

    setIsActive(active);

    return active;
  };

  return (
    <Container isActive={isActive} isMobile={isMobile}>
      <NavLinkStyled isActive={handleIsActive} isMobile={isMobile} {...props}>
        <Icon size="1.5rem" color={isActive ? colors.mainC : colors.baseC} />
        <TextStyled
          type="bodyBold"
          color={isActive ? "mainC" : "baseC"}
          isMobile={isMobile}
        >
          {children}
        </TextStyled>
      </NavLinkStyled>
    </Container>
  );
};

export default NavLink;
