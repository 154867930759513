import { FC, HTMLAttributes } from "react";
import styled from "styled-components";

import Text from "./Text";
import { Color, Typography } from "@/utilities/theme";

interface TextButtonProps {
  type: Typography;
  color: Color;
  Icon?: JSX.Element;
  disabled?: boolean;
  onClick?: () => void;
}

interface TextStyledProps {
  disabled: boolean;
}

const TextStyled = styled(Text)<TextStyledProps>`
  cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};
  display: flex;
  align-items: center;
`;

const IconContainer = styled.div`
  padding-left: 0.25rem;
  display: flex;
  align-items: center;

  @media ${({ theme }) => theme.device.mobile} {
    padding-left: 0.15rem;
  }
`;

const TextButton: FC<TextButtonProps & HTMLAttributes<HTMLDivElement>> = ({
  Icon,
  children,
  disabled,
  onClick,
  ...props
}) => {
  const handleClick = () => {
    if (disabled) {
      return;
    }

    onClick?.();
  };

  return (
    <TextStyled disabled={!!disabled} onClick={handleClick} {...props}>
      {children}
      {!!Icon && <IconContainer>{Icon}</IconContainer>}
    </TextStyled>
  );
};

export default TextButton;
