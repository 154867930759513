import { FC, useState, useEffect, ChangeEvent } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { DateTime } from "luxon";
import styled from "styled-components";

import ScreenContainer from "@/components/ScreenContainer";
import Header from "@/components/Header";
import MobileNav from "@/components/MobileNav";
import SettingsCard from "@/components/SettingsCard";
import TextButton from "@/components/TextButton";
import Button from "@/components/Button";
import Footer from "@/components/Footer";
import Textarea from "@/components/Textarea";
import CancelSubscriptionModal from "./components/CancelSubscriptionModal";
import Rating from "./components/Rating";
import SubscriptionCard from "./components/SubscriptionCard";
import PaymentDialog from "./components/PaymentDialog";
import SubscriptionDetail from "./components/SubscriptionDetail";
import SafeCheckout from "./components/SafeCheckout";
import { ReactComponent as PaymentProvidersIcon } from "@/assets/images/payment-providers.svg";
import { ReactComponent as TeamIcon } from "@/assets/images/team.svg";
import { ReactComponent as RhythmIcon } from "@/assets/images/rhythm.svg";
import { ReactComponent as ShapeIcon } from "@/assets/images/shape.svg";
import { ReactComponent as MoneyDollarCircleIcon } from "@/assets/images/money-dollar-circle.svg";
import store, { RootState, Dispatch } from "@/utilities/store";
import {
  subCancellation1GtmEvent,
  subCancellation2GtmEvent,
  startNowinChat,
  purchaseInChat,
} from "@/utilities/gtm";
import { Product } from "@/models/products";

const SUBSCRIPTION_DETAILS = [
  {
    title: "Chat with your therapist when you need it",
    description:
      "No need to change your schedule. Reach your therapist 7 days a week at a time that suits you",
    Icon: TeamIcon,
  },
  {
    title: "Receive professional and continuous feedback",
    description:
      "Your therapist is here to guide you. They will answer within 2 hours and offer support along your journey",
    Icon: RhythmIcon,
  },
  {
    title: "Talk about your problems in a safe space",
    description:
      "No more hiding feelings. You will be heard, understood, and accepted just the way you are",
    Icon: ShapeIcon,
  },
  {
    title: "Your own personalized mental health roadmap",
    description:
      "Never feel stuck. Easily track your therapy progress with visual steps and goals",
    Icon: MoneyDollarCircleIcon,
  },
];

const ScreenContainerStyled = styled(ScreenContainer)`
  display: flex;
`;

const Container = styled.div`
  flex: 1;
  padding: 3rem 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media ${({ theme }) => theme.device.mobile} {
    padding: 2rem 0;
  }
`;

const FooterStyled = styled(Footer)`
  padding-top: 3rem;

  @media ${({ theme }) => theme.device.mobile} {
    padding-top: 2rem;
  }
`;

const RatingContainer = styled.div`
  display: flex;
  justify-content: center;
`;

const ButtonStyled = styled(Button)`
  height: auto;
  padding: 0.75rem;
  margin: 0 auto;
  display: block;
`;

const QuestionModalButton = styled(ButtonStyled)`
  max-width: 25.75rem;
`;

const ConfirmModalButton = styled(ButtonStyled)`
  max-width: 16.25rem;
`;

const TextButtonContainer = styled.div`
  padding-top: 1rem;
`;

const TextButtonStyled = styled(TextButton)`
  padding: 0.75rem;
  text-align: center;
  max-width: 25.75rem;
  margin: 0 auto;
  display: block;
`;

const TextareaStyled = styled(Textarea)`
  border-width: 2px;
  color: ${({ theme }) => theme.colors.offBaseC};

  ::placeholder {
    color: ${({ theme }) => theme.colors.offBaseC};
  }
`;

const ConfirmModalButtonContainer = styled.div`
  padding-top: 2rem;
`;

const SettingsCardContentContainer = styled.div`
  padding: 1.5rem 0;
`;

const ProductsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 1.5rem;

  @media ${({ theme }) => theme.device.mobile} {
    grid-template-columns: 1fr;
    grid-row-gap: 0.75rem;
  }
`;

const PaymentProvidersIconContainer = styled.div`
  padding: 1.5rem 0;
`;

const PaymentProvidersIconStyled = styled(PaymentProvidersIcon)`
  width: 100%;
`;

const SubscriptionDetailsContainer = styled.div`
  padding: 2.25rem 4.5rem;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 2.5rem;
  grid-row-gap: 2.75rem;
  border-top: 1px solid #dcdcdc;
  max-width: 48.88rem;
  margin: 0 auto;

  @media ${({ theme }) => theme.device.mobile} {
    padding: 2.25rem 0;
    grid-template-columns: 1fr;
  }
`;

const SafeCheckoutStyled = styled(SafeCheckout)`
  max-width: 46.63rem;
  margin: 0 auto;
`;

const SubscriptionSettings: FC = () => {
  const [rating, setRating] = useState<number>(0);
  const [rateModalOpen, setRateModalOpen] = useState<boolean>(false);
  const [questionModalOpen, setQuestionModalOpen] = useState<boolean>(false);
  const [confirmModalOpen, setConfirmModalOpen] = useState<boolean>(false);
  const [paymentModalOpen, setPaymentModalOpen] = useState<boolean>(false);
  const [reason, setReason] = useState<string>("");
  const [error, setError] = useState<string>("");
  const [selectedProduct, setSelectedProduct] = useState<Product | null>(null);

  const subscription = useSelector(
    (state: RootState) => state.subscriptions.subscription
  );
  const products = useSelector((state: RootState) => state.products.products);
  const user = useSelector((state: RootState) => state.authentication.user);

  const supportConsultation = useSelector((state: RootState) =>
    store.select.consultations.supportConsultation(state)
  );

  const loading = useSelector(
    (state: RootState) => state.loading.effects.subscriptions.cancelSubscription
  );

  const dispatch = useDispatch<Dispatch>();

  const history = useHistory<{ consultationId?: string }>();

  useEffect(() => {
    let unsubscribe: () => void;
    dispatch.consultations.fetchConsultations().then((unsubscribeHandler) => {
      unsubscribe = unsubscribeHandler;
    });

    dispatch.products.fetchProducts();
    dispatch.projectConfig.fetchProjectConfig();

    return () => {
      unsubscribe?.();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!rateModalOpen) {
      return;
    }

    setRating(0);
  }, [rateModalOpen]);

  useEffect(() => {
    if (!confirmModalOpen) {
      return;
    }

    setReason("");

    setError("");
  }, [confirmModalOpen]);

  const toggleRateModal = () => {
    setRateModalOpen(!rateModalOpen);
  };

  const toggleQuestionModal = () => {
    setQuestionModalOpen(!questionModalOpen);
  };

  const toggleConfirmModal = () => {
    setConfirmModalOpen(!confirmModalOpen);
  };

  const togglePaymentModal = () => {
    setPaymentModalOpen(!paymentModalOpen);
  };

  const navigateToChat = () => {
    history.push({
      pathname: "/chat",
      state: { consultationId: supportConsultation?.id },
    });
  };

  const getText = () => {
    if (!!subscription?.expired) {
      return "Your subscription is expired.";
    }

    if (!subscription?.expired && !subscription?.active) {
      return `Your subscription will expire: ${DateTime.fromISO(
        subscription?.valid?.next_payment_at ?? ""
      ).toFormat("yyyy.LL.dd")}`;
    }

    return "Cancel Subscription";
  };

  const handleClick = () => {
    subCancellation1GtmEvent();

    toggleRateModal();
  };

  const handleRatingClick = (rating: number) => {
    setRating(rating);

    setTimeout(() => {
      toggleRateModal();

      toggleQuestionModal();
    }, 500);
  };

  const handleCancelSubscription = () => {
    toggleQuestionModal();

    toggleConfirmModal();
  };

  const handleQuestionModalBackArrowClick = () => {
    toggleQuestionModal();

    toggleRateModal();
  };

  const handleConfirmModalBackArrowClick = () => {
    toggleConfirmModal();

    toggleQuestionModal();
  };

  const handleReasonChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setReason(e.target.value);
  };

  const handleCancelSubscriptionConfirm = async () => {
    setError("");

    if (!reason.trim().length) {
      return setError("Please provide a valid answer");
    }

    subCancellation2GtmEvent();

    if (!subscription?.active) {
      return;
    }

    await dispatch.subscriptions.cancelSubscription(subscription.active.id);

    dispatch.subscriptions.sendUnsubscribedEmail({
      rating,
      reason,
    });

    toggleConfirmModal();
  };

  const handleNewTherapistChange = () => {
    dispatch.messages.sendSupportAutoMsg();

    navigateToChat();
  };

  const handleSubscriptionClick = (product: Product) => {
    setSelectedProduct(product);

    togglePaymentModal();

    const price = product?.prices?.[0];

    startNowinChat({
      code: user?.funnel?.code ?? "",
      price: price?.final_price ?? 0,
      currency: price?.currency_id ?? "",
      plan: product?.name ?? "",
    });
  };

  const handlePaymentSuccess = async (data: { order_id: number }) => {
    await dispatch.subscriptions.checkSubscriptions();

    togglePaymentModal();

    const price = selectedProduct?.prices?.[0];

    purchaseInChat({
      code: user?.funnel?.code ?? "",
      price: price?.final_price ?? 0,
      currency: price?.currency_id ?? "",
      plan: selectedProduct?.name ?? "",
      orderId: data?.order_id ?? 0,
    });
  };

  return (
    <ScreenContainerStyled
      HeaderComponent={<Header />}
      FooterComponent={<MobileNav />}
    >
      <Container>
        <SettingsCard
          title="Settings"
          breadcrumb="Subscription Settings"
          withBackButton
          FooterComponent={
            !!subscription?.hasSubscriptions && (
              <TextButton
                type="body"
                color="dangerC"
                disabled={!subscription?.active}
                onClick={handleClick}
              >
                {getText()}
              </TextButton>
            )
          }
        >
          <SettingsCardContentContainer>
            <ProductsContainer>
              {products
                .filter((product) => product?.key !== "weekly-1")
                .map((product) => (
                  <SubscriptionCard
                    key={product?.key}
                    product={product}
                    isSpecialOffer={product?.key === "test-1"}
                    onStartClick={handleSubscriptionClick}
                  />
                ))}
            </ProductsContainer>
            <PaymentProvidersIconContainer>
              <PaymentProvidersIconStyled />
            </PaymentProvidersIconContainer>
            <SubscriptionDetailsContainer>
              {SUBSCRIPTION_DETAILS.map(
                ({ title, description, Icon }, index) => (
                  <SubscriptionDetail
                    key={index}
                    title={title}
                    description={description}
                    Icon={Icon}
                  />
                )
              )}
            </SubscriptionDetailsContainer>
            <SafeCheckoutStyled />
          </SettingsCardContentContainer>
        </SettingsCard>
        <FooterStyled />
      </Container>
      <CancelSubscriptionModal
        progressValue={20}
        progressText="1"
        title="How has your experience with DoMental been?"
        description="Your feedback will help us improve and do better in the future."
        isOpen={rateModalOpen}
        onClose={toggleRateModal}
      >
        <RatingContainer>
          <Rating
            rating={rating}
            disabled={!!rating}
            onRatingClick={handleRatingClick}
          />
        </RatingContainer>
      </CancelSubscriptionModal>
      <CancelSubscriptionModal
        hasBackArrow
        progressValue={40}
        progressText="2"
        title="Would you be willing to try a fresh start with a new therapist?"
        description="We are certain that among our network of licensed therapists is the right one for you."
        isOpen={questionModalOpen}
        onClose={toggleQuestionModal}
        onBackArrowClick={handleQuestionModalBackArrowClick}
      >
        <QuestionModalButton onClick={handleNewTherapistChange}>
          Yes, I want to start with a new therapist
        </QuestionModalButton>
        <TextButtonContainer>
          <TextButtonStyled
            type="btn"
            color="promoC"
            onClick={handleCancelSubscription}
          >
            No, I am sure I want to cancel
          </TextButtonStyled>
        </TextButtonContainer>
      </CancelSubscriptionModal>
      <CancelSubscriptionModal
        hasBackArrow
        progressValue={60}
        progressText="3"
        title="Can you tell us why you want to cancel your subscription?"
        description="We know there’s always room for improvement. Let us know what we should work on."
        isOpen={confirmModalOpen}
        onClose={toggleConfirmModal}
        onBackArrowClick={handleConfirmModalBackArrowClick}
      >
        <TextareaStyled
          minRows={3}
          placeholder="Please tell us here"
          disabled={loading}
          error={error}
          value={reason}
          onChange={handleReasonChange}
        />
        <ConfirmModalButtonContainer>
          <ConfirmModalButton
            disabled={loading}
            onClick={handleCancelSubscriptionConfirm}
          >
            Submit
          </ConfirmModalButton>
        </ConfirmModalButtonContainer>
      </CancelSubscriptionModal>
      <PaymentDialog
        selectedProduct={selectedProduct}
        isOpen={paymentModalOpen}
        onSuccess={handlePaymentSuccess}
        onClose={togglePaymentModal}
      />
    </ScreenContainerStyled>
  );
};

export default SubscriptionSettings;
