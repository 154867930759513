import { FC } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";

import ScreenContainer from "@/components/ScreenContainer";
import Header from "@/components/Header";
import MobileNav from "@/components/MobileNav";
import BackButton from "@/components/BackButton";
import Text from "@/components/Text";
import Footer from "@/components/Footer";
import { useMediaQuery } from "@/utilities/hooks";
import { RootState } from "@/utilities/store";

const ScreenContainerStyled = styled(ScreenContainer)`
  display: flex;
`;

const Container = styled.div`
  flex: 1;
  padding: 3rem 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media ${({ theme }) => theme.device.mobile} {
    padding: 2rem 0;
  }
`;

const ContentContainer = styled.div`
  min-height: 34.44rem;
  display: grid;
  grid-template-columns: repeat(2, 1fr);

  @media ${({ theme }) => theme.device.mobile} {
    grid-template-columns: 1fr;
    min-height: auto;
  }
`;

const BackButtonStyled = styled(BackButton)`
  margin-bottom: 1rem;
`;

const Breadcrumb = styled(Text)`
  display: inline;
`;

const Title = styled(Text)`
  margin: 1.5rem 0;

  @media ${({ theme }) => theme.device.mobile} {
    margin: 0 0 1rem 0;
  }
`;

const RightContainer = styled.div`
  padding-left: 3rem;

  @media ${({ theme }) => theme.device.mobile} {
    padding-left: 0;
    grid-row: 1;
  }
`;

const Image = styled.img`
  width: 100%;
  height: 33.75rem;
  object-fit: cover;
  border-radius: 0.75rem;

  @media ${({ theme }) => theme.device.mobile} {
    margin: 2rem 0;
    height: 15rem;
  }
`;

const FooterStyled = styled(Footer)`
  padding-top: 3rem;

  @media ${({ theme }) => theme.device.mobile} {
    padding-top: 2rem;
  }
`;

const Therapist: FC = () => {
  const user = useSelector((state: RootState) => state.authentication.user);

  const { isMobile } = useMediaQuery();

  const fullName = `${user?.assignedDoctor?.name} ${user?.assignedDoctor?.surname}`;

  return (
    <ScreenContainerStyled
      HeaderComponent={<Header />}
      FooterComponent={<MobileNav />}
    >
      <Container>
        <ContentContainer>
          <div>
            {!isMobile && (
              <>
                <BackButtonStyled />
                <Text type="h3Bold" color="headingText">
                  Inbox
                  <Breadcrumb type="body" color="headingText">
                    {" / My Therapist"}
                  </Breadcrumb>
                </Text>
              </>
            )}
            <Title type="h2Bold" color="headingText">
              {fullName}
            </Title>
            <Text type="body" color="bodyC">
              {user?.assignedDoctor?.summary}
            </Text>
          </div>
          <RightContainer>
            {isMobile && (
              <>
                <BackButtonStyled />
                <Text type="h3Bold" color="headingText">
                  Inbox
                  <Breadcrumb type="body" color="headingText">
                    {" / My Therapist"}
                  </Breadcrumb>
                </Text>
              </>
            )}
            <Image src={user?.assignedDoctor?.photo} alt="my-therapist" />
          </RightContainer>
        </ContentContainer>
        <FooterStyled />
      </Container>
    </ScreenContainerStyled>
  );
};

export default Therapist;
