import { FC, useEffect, useState } from "react";
import {
  BrowserRouter,
  Switch,
  Route,
  Redirect,
  RouteProps,
} from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import Login from "@/screens/Login";
import CompleteProfile from "@/screens/CompleteProfile";
import Chat from "@/screens/Chat";
import Settings from "@/screens/Settings";
import SubscriptionSettings from "@/screens/SubscriptionSettings";
import TherapistSettings from "@/screens/TherapistSettings";
import NotificationsSettings from "@/screens/NotificationsSettings";
import Journey from "@/screens/Journey";
import Onboarding from "@/screens/Onboarding";
import ForgotPassword from "@/screens/ForgotPassword";
import Profile from "@/screens/Profile";
import Therapist from "@/screens/Therapist";
import AccountSettings from "@/screens/AccountSettings";
import About from "@/screens/About";
import FAQ from "@/screens/FAQ";
import Start from "@/screens/Start";
import CreatePassword from "@/screens/CreatePassword";
import FreeConsultation from "@/screens/FreeConsultation";
import ScreenLoader from "@/components/ScreenLoader";
import { RootState, Dispatch } from "@/utilities/store";
import firebase from "@/utilities/firebase";

const AuthenticatedRoute: FC<RouteProps> = ({ children, ...props }) => {
  const user = useSelector((state: RootState) => state.authentication.user);
  const subscription = useSelector(
    (state: RootState) => state.subscriptions.subscription
  );

  return (
    <Route
      {...props}
      render={({ location, match }) => {
        if (!user) {
          return (
            <Redirect
              to={{
                pathname: "/login",
                state: { from: location },
              }}
            />
          );
        }

        if (
          !user?.nickname &&
          location.pathname !== "/complete-profile" &&
          match.path !== "/about/:id" &&
          location.pathname !== "/faq"
        ) {
          return (
            <Redirect
              to={{
                pathname: "/complete-profile",
                state: { from: location },
              }}
            />
          );
        }

        if (
          !!user?.nickname &&
          location.pathname === "/complete-profile" &&
          !!subscription?.hasSubscriptions
        ) {
          return (
            <Redirect
              to={{
                pathname: "/onboarding",
                state: { from: location },
              }}
            />
          );
        }

        if (
          (!!user?.nickname &&
            location.pathname === "/complete-profile" &&
            !subscription?.hasSubscriptions) ||
          (location.pathname === "/journey" && !subscription?.hasSubscriptions)
        ) {
          return (
            <Redirect
              to={{
                pathname: "/chat",
                state: { from: location },
              }}
            />
          );
        }

        return children;
      }}
    />
  );
};

const Router: FC = (props) => {
  const [loading, setLoading] = useState<boolean>(true);

  const user = useSelector((state: RootState) => state.authentication.user);

  const dispatch = useDispatch<Dispatch>();

  useEffect(() => {
    firebase.auth().onAuthStateChanged(async (currentUser) => {
      try {
        if (!currentUser) {
          await dispatch.authentication.logout();

          return setLoading(false);
        }

        await dispatch.authentication.fetchCurrentUserDetails();

        await dispatch.subscriptions.checkSubscriptions();

        setLoading(false);
      } catch (_) {
        await dispatch.authentication.logout();

        setLoading(false);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderRoute = ({ location }: RouteProps) => {
    if (!!user) {
      return (
        <Redirect
          to={{
            pathname: "/chat",
            state: { from: location },
          }}
        />
      );
    }

    switch (location?.pathname) {
      case "/login":
        return <Login />;
      case "/forgot-password":
        return <ForgotPassword />;
      default:
        return <Login />;
    }
  };

  if (loading) {
    return <ScreenLoader />;
  }

  return (
    <BrowserRouter {...props}>
      <Switch>
        <Route exact path="/login">
          {renderRoute}
        </Route>
        <Route exact path="/forgot-password">
          {renderRoute}
        </Route>
        <Route exact path="/create-password/:token">
          <CreatePassword />
        </Route>
        <Route exact path="/free-consultation/:token">
          <FreeConsultation />
        </Route>
        <Route exact path="/start">
          <Start />
        </Route>
        <Route exact path="/">
          {renderRoute}
        </Route>
        <AuthenticatedRoute exact path="/complete-profile">
          <CompleteProfile />
        </AuthenticatedRoute>
        <AuthenticatedRoute exact path="/onboarding">
          <Onboarding />
        </AuthenticatedRoute>
        <AuthenticatedRoute exact path="/chat">
          <Chat />
        </AuthenticatedRoute>
        <AuthenticatedRoute exact path="/chat/therapist">
          <Therapist />
        </AuthenticatedRoute>
        <AuthenticatedRoute exact path="/journey">
          <Journey />
        </AuthenticatedRoute>
        <AuthenticatedRoute exact path="/profile">
          <Profile />
        </AuthenticatedRoute>
        <AuthenticatedRoute exact path="/profile/settings">
          <Settings />
        </AuthenticatedRoute>
        <AuthenticatedRoute exact path="/profile/settings/account">
          <AccountSettings />
        </AuthenticatedRoute>
        <AuthenticatedRoute exact path="/profile/settings/subscription">
          <SubscriptionSettings />
        </AuthenticatedRoute>
        <AuthenticatedRoute exact path="/profile/settings/therapist">
          <TherapistSettings />
        </AuthenticatedRoute>
        <AuthenticatedRoute exact path="/profile/settings/notifications">
          <NotificationsSettings />
        </AuthenticatedRoute>
        <AuthenticatedRoute exact path="/about/:id">
          <About />
        </AuthenticatedRoute>
        <AuthenticatedRoute exact path="/faq">
          <FAQ />
        </AuthenticatedRoute>
        <Redirect to="/" />
      </Switch>
    </BrowserRouter>
  );
};

export default Router;
