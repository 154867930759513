import { FC, HTMLAttributes, ReactNode } from "react";
import styled from "styled-components";

import Text from "./Text";
import BackButton from "./BackButton";

interface SettingsCardProps {
  title: string;
  breadcrumb?: string;
  FooterComponent?: ReactNode;
  withBackButton?: boolean;
}

interface FooterContainerProps {
  hasContent: boolean;
}

const Container = styled.div`
  background-color: ${({ theme }) => theme.colors.modalC};
  padding: 3rem;
  border-radius: 1rem;

  @media ${({ theme }) => theme.device.mobile} {
    padding: 1.5rem;
  }
`;

const TitleContainer = styled.div`
  padding-bottom: 1.5rem;
  border-bottom: 1px solid ${({ theme }) => theme.colors.inactiveLightC};
  display: flex;
`;

const Breadcrumb = styled(Text)`
  display: inline;
`;

const FooterContainer = styled.div<FooterContainerProps>`
  padding-top: 1.5rem;
  border-top: ${({ theme, hasContent }) =>
    hasContent ? `1px solid ${theme.colors.inactiveLightC}` : "none"};
  display: flex;
`;

const BackButtonStyled = styled(BackButton)`
  margin-bottom: 1rem;
`;

const SettingsCard: FC<SettingsCardProps & HTMLAttributes<HTMLDivElement>> = ({
  title,
  breadcrumb,
  FooterComponent,
  withBackButton,
  children,
  ...props
}) => {
  return (
    <Container {...props}>
      {!!withBackButton && <BackButtonStyled />}
      <TitleContainer>
        <Text type="h3Bold" color="headingText">
          {title}
          {!!breadcrumb && (
            <Breadcrumb type="body" color="headingText">
              {` / ${breadcrumb}`}
            </Breadcrumb>
          )}
        </Text>
      </TitleContainer>
      {children}
      <FooterContainer hasContent={!!children && !!FooterComponent}>
        {FooterComponent}
      </FooterContainer>
    </Container>
  );
};

export default SettingsCard;
