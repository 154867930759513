import { FC, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import styled from "styled-components";

import ScreenContainer from "@/components/ScreenContainer";
import Header from "@/components/Header";
import MobileNav from "@/components/MobileNav";
import SettingsCard from "@/components/SettingsCard";
import TextButton from "@/components/TextButton";
import Footer from "@/components/Footer";
import ChangeTherapistModal from "./components/ChangeTherapistModal";
import RequestRegisteredModal from "./components/RequestRegisteredModal";
import { RootState, Dispatch } from "@/utilities/store";
import {
  changeTherapist1GtmEvent,
  changeTherapist2GtmEvent,
} from "@/utilities/gtm";

const ScreenContainerStyled = styled(ScreenContainer)`
  display: flex;
`;

const Container = styled.div`
  flex: 1;
  padding: 3rem 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media ${({ theme }) => theme.device.mobile} {
    padding: 2rem 0;
  }
`;

const FooterStyled = styled(Footer)`
  padding-top: 3rem;

  @media ${({ theme }) => theme.device.mobile} {
    padding-top: 2rem;
  }
`;

const TherapistSettings: FC = () => {
  const [showChangeTherapistModal, setShowChangeTherapistModal] =
    useState<boolean>(false);
  const [showRequestRegisteredModal, setShowRequestRegisteredModal] =
    useState<boolean>(false);
  const [requestId, setRequestId] = useState<string>("");

  const loading = useSelector(
    (state: RootState) =>
      state.loading.effects.authentication.sendChangeTherapistRequest
  );

  const dispatch = useDispatch<Dispatch>();

  const toggleChangeTherapistModal = () => {
    setShowChangeTherapistModal(!showChangeTherapistModal);
  };

  const toggleRequestRegisteredModal = () => {
    setShowRequestRegisteredModal(!showRequestRegisteredModal);
  };

  const handleChangeTherapistModalConfirm = async (data: {
    reason: string;
    description: string;
  }) => {
    changeTherapist2GtmEvent(
      !data?.description?.trim()
        ? data?.reason
        : `${data?.reason} - ${data?.description}`
    );

    const id = await dispatch.authentication.sendChangeTherapistRequest(data);
    if (!id) {
      return;
    }

    setRequestId(id);

    toggleChangeTherapistModal();

    toggleRequestRegisteredModal();
  };

  const handlePress = () => {
    changeTherapist1GtmEvent();

    toggleChangeTherapistModal();
  };

  return (
    <ScreenContainerStyled
      HeaderComponent={<Header />}
      FooterComponent={<MobileNav />}
    >
      <Container>
        <SettingsCard
          title="Settings"
          breadcrumb="Therapist Settings"
          withBackButton
          FooterComponent={
            <TextButton type="body" color="dangerC" onClick={handlePress}>
              Change Therapist
            </TextButton>
          }
        />
        <FooterStyled />
      </Container>
      <ChangeTherapistModal
        isOpen={showChangeTherapistModal}
        disabled={loading}
        toggleModal={toggleChangeTherapistModal}
        onConfirm={handleChangeTherapistModalConfirm}
      />
      <RequestRegisteredModal
        isOpen={showRequestRegisteredModal}
        requestId={requestId}
        toggleModal={toggleRequestRegisteredModal}
      />
    </ScreenContainerStyled>
  );
};

export default TherapistSettings;
