import { FC, HTMLAttributes } from "react";
import styled from "styled-components";

import { PAYMENT_METHOD } from "./KiloPayments";

interface PaymentCardProps {
  id: string;
  title: string;
  images: { src: string; alt: string }[];
  selected: boolean;
  onSelect: (id: string) => void;
}

interface ContainerProps {
  selected: boolean;
}

interface HeaderRightContainerProps {
  imagesLength: number;
}

interface PaymentContainerProps {
  selected: boolean;
}

interface ImageProps {
  isPaypal: boolean;
}

const Container = styled.div<ContainerProps>`
  border-style: solid;
  border-width: ${({ selected }) => (selected ? 2 : 0.5)}px;
  border-color: ${({ selected }) => (selected ? "#3A3A3A" : "#747474")};
  border-radius: 5px;
`;

const HeaderContainer = styled.div`
  padding: 1.5rem 1rem;
  cursor: pointer;
  display: flex;
  align-items: center;
`;

const HeaderCenterContainer = styled.div`
  flex: 1;
  padding: 0 1rem;

  @media ${({ theme }) => theme.device.mobile} {
    min-width: 7.2rem;
  }
`;

const Title = styled.span`
  font-family: "Source Sans Pro", sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 1rem;
  line-height: 1.26rem;
  color: #000000;
  opacity: 0.54;

  @media ${({ theme }) => theme.device.mobile} {
    font-size: 0.9rem;
  }
`;

const HeaderRightContainer = styled.div<HeaderRightContainerProps>`
  display: grid;
  grid-template-columns: repeat(${({ imagesLength }) => imagesLength}, auto);
  grid-column-gap: 0.38rem;
`;

const Image = styled.img<ImageProps>`
  width: 100%;
  max-width: ${({ isPaypal }) => (isPaypal ? 3.63 : 2.46)}rem;
  height: 100%;
  max-height: ${({ isPaypal }) => (isPaypal ? 2.21 : 1.5)}rem;
`;

const PaymentContainer = styled.div<PaymentContainerProps>`
  display: ${({ selected }) => (selected ? "block" : "none")};
  padding: 0.4rem 1rem;

  label {
    font-family: "Roboto", sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 0.75rem;
    line-height: 0.88rem;
    color: #000000;
    opacity: 0.64;
    display: block;
    margin-bottom: 0.5rem;
  }

  input,
  div#kp-stripe-card {
    font-family: "Source Sans Pro", sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 1rem;
    line-height: 1.26rem;
    color: #000000;
    opacity: 0.54;
    margin-bottom: 1rem;
    padding: 0.63rem 1rem;
    border: 1px solid #a7a7a7;
    border-radius: 0.5rem;
    box-shadow: none;

    ::placeholder {
      color: #000000;
      opacity: 0.54;
    }
  }

  button {
    opacity: 1 !important;
    height: 3rem;
    border-radius: 0.5rem;
    background-color: #4f7bea;
    font-family: "Source Sans Pro", sans-serif;
    font-weight: 600;
    font-style: normal;
    font-size: 1.13rem;
    line-height: 1.41rem;
    color: #ffffff;
    box-shadow: 0 0.38rem 1.25rem rgba(0, 0, 0, 0.05);
    padding: 0;
    margin: 0;
  }

  .kp-row {
    display: inline;
  }
`;

const RadioInput = styled.input`
  width: 1.25rem;
  height: 1.25rem;
`;

const PaymentCard: FC<PaymentCardProps & HTMLAttributes<HTMLDivElement>> = ({
  id,
  title,
  images,
  selected,
  onSelect,
  ...props
}) => {
  const handleClick = () => {
    onSelect(id);
  };

  return (
    <Container selected={selected} {...props}>
      <HeaderContainer onClick={handleClick}>
        <RadioInput type="radio" checked={selected} />
        <HeaderCenterContainer>
          <Title>{title}</Title>
        </HeaderCenterContainer>
        <HeaderRightContainer imagesLength={images.length}>
          {images.map((image, index) => (
            <Image
              key={index}
              isPaypal={id === PAYMENT_METHOD.paypal}
              {...image}
            />
          ))}
        </HeaderRightContainer>
      </HeaderContainer>
      <PaymentContainer selected={selected}>
        {selected && <div id="kilo-payments-error" />}
        <div id={id} />
      </PaymentContainer>
    </Container>
  );
};

export default PaymentCard;
