import { FC, ChangeEvent, useRef, Fragment, useEffect } from "react";
import { IoMdAttach, IoMdClose } from "react-icons/io";
import styled from "styled-components";

interface AttachFileProps {
  isAttachment: boolean;
  loading: boolean;
  setAttachment: (file: File | null) => void;
}

interface IconsProps {
  loading: boolean;
}

const AttachIcon = styled(IoMdAttach)<IconsProps>`
  font-size: 1.5rem;
  color: ${({ theme }) => theme.colors.baseC};
  cursor: ${({ loading }) => (loading ? "not-allowed" : "pointer")};
`;

const CloseIcon = styled(IoMdClose)<IconsProps>`
  font-size: 1.5rem;
  color: ${({ theme }) => theme.colors.promoC};
  cursor: ${({ loading }) => (loading ? "not-allowed" : "pointer")};
`;

const FileInput = styled.input`
  display: none;
`;

const AttachFile: FC<AttachFileProps> = ({
  isAttachment,
  loading,
  setAttachment,
  ...props
}) => {
  const fileInputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (isAttachment || !fileInputRef.current) {
      return;
    }

    fileInputRef.current.value = "";
  }, [isAttachment]);

  const selectAttachment = () => {
    if (loading) {
      return;
    }

    fileInputRef.current?.click();
  };

  const clearAttachment = () => {
    setAttachment(null);
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files?.length) {
      return;
    }

    setAttachment(e.target.files[0]);
  };

  return (
    <Fragment {...props}>
      {isAttachment ? (
        <CloseIcon loading={loading} onClick={clearAttachment} />
      ) : (
        <AttachIcon loading={loading} onClick={selectAttachment} />
      )}
      <FileInput type="file" ref={fileInputRef} onChange={handleChange} />
    </Fragment>
  );
};

export default AttachFile;
