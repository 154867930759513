import { FC, HTMLAttributes } from "react";
import styled from "styled-components";

interface DotsProps {
  activeIndex: number;
  length: number;
}

interface ContainerProps {
  length: number;
}

interface DotProps {
  active: boolean;
}

const Container = styled.div<ContainerProps>`
  display: grid;
  grid-template-columns: repeat(${({ length }) => length}, auto);
  grid-column-gap: 1.5rem;
  justify-content: center;
`;

const Dot = styled.div<DotProps>`
  width: 0.875rem;
  height: 0.875rem;
  border-radius: 50%;
  background-color: ${({ active, theme }) =>
    active ? theme.colors.mainC : theme.colors.inactiveLightC};

  @media ${({ theme }) => theme.device.mobile} {
    width: 0.75rem;
    height: 0.75rem;
  }
`;

const Dots: FC<DotsProps & HTMLAttributes<HTMLDivElement>> = ({
  activeIndex,
  length,
  ...props
}) => {
  return (
    <Container length={length} {...props}>
      {new Array(length).fill(null).map((_, index) => (
        <Dot key={index} active={activeIndex === index} />
      ))}
    </Container>
  );
};

export default Dots;
