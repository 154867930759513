import { FC, HTMLAttributes } from "react";
import styled from "styled-components";

import Text from "@/components/Text";
import GooglePlayImg from "@/assets/images/google-play.svg";
import AppStoreImg from "@/assets/images/app-store.svg";
import { useMediaQuery } from "@/utilities/hooks";

const Container = styled.div`
  max-width: 22rem;
  width: 100%;
`;

const TitleContainer = styled.div`
  text-align: center;
  margin-bottom: 1.5rem;
`;

const StoresContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 1.5rem;

  @media ${({ theme }) => theme.device.mobile} {
    grid-column-gap: 1rem;
    padding: 0 1rem;
  }
`;

const Image = styled.img`
  width: 100%;
  cursor: pointer;
`;

const StoresFooter: FC<HTMLAttributes<HTMLDivElement>> = (props) => {
  const { isMobile } = useMediaQuery();

  const openGooglePlay = () => {
    window.open(
      "https://play.google.com/store/apps/details?id=com.domental.app",
      "_self"
    );
  };

  const openAppStore = () => {
    window.open("https://apps.apple.com/lt/app/domental/id1558664529", "_self");
  };

  return (
    <Container {...props}>
      <TitleContainer>
        <Text type={isMobile ? "bodyBold" : "h5Bold"} color="headingText">
          Want your therapy on the go?
        </Text>
        <Text type={isMobile ? "bodyBold" : "h5Bold"} color="headingText">
          Download the app
        </Text>
      </TitleContainer>
      <StoresContainer>
        <Image src={GooglePlayImg} alt="google-play" onClick={openGooglePlay} />
        <Image src={AppStoreImg} alt="app-store" onClick={openAppStore} />
      </StoresContainer>
    </Container>
  );
};

export default StoresFooter;
