import { FC, Fragment, HTMLAttributes } from "react";
import styled from "styled-components";

import { ReactComponent as LockIcon } from "@/assets/images/lock.svg";
import { ReactComponent as ReceiptIcon } from "@/assets/images/receipt.svg";
import { ReactComponent as AllAccessIcon } from "@/assets/images/all-access.svg";
import { ReactComponent as RefundIcon } from "@/assets/images/refund.svg";

const ITEMS = [
  {
    Icon: <LockIcon />,
    title: "Follow the instructions above to complete your secure purchase.",
  },
  {
    Icon: <ReceiptIcon />,
    title: "Payments are charged in USD.",
  },
  {
    Icon: <RefundIcon />,
    title: "You can cancel your subscription at any time.",
  },
  {
    Icon: <AllAccessIcon />,
    title: "Active subscription gives you access to all app features.",
  },
];

interface ItemContainerProps {
  hasBottomPadding: boolean;
}

const ItemContainer = styled.div<ItemContainerProps>`
  display: flex;
  align-items: center;
  padding-bottom: ${({ hasBottomPadding }) => (hasBottomPadding ? 0.75 : 0)}rem;
`;

const IconContainer = styled.div`
  width: 2.31rem;
  height: 2.31rem;
  border-radius: 50%;
  background-color: #eeeeee;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ItemTitleContainer = styled.div`
  flex: 1;
  padding-left: 0.81rem;
`;

const ItemTitle = styled.span`
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-size: 0.75rem;
  line-height: 0.88rem;
  color: rgba(0, 0, 0, 0.64);
`;

const PaymentInfo: FC<HTMLAttributes<HTMLDivElement>> = (props) => (
  <Fragment {...props}>
    {ITEMS.map(({ Icon, title }, index) => (
      <ItemContainer key={index} hasBottomPadding={index !== ITEMS.length - 1}>
        <IconContainer>{Icon}</IconContainer>
        <ItemTitleContainer>
          <ItemTitle>{title}</ItemTitle>
        </ItemTitleContainer>
      </ItemContainer>
    ))}
  </Fragment>
);

export default PaymentInfo;
