import TagManager, { TagManagerArgs, DataLayerArgs } from "react-gtm-module";

type Currency = "USD" | "EUR";

type Product = { title: string };

interface PaymentData {
  method: "dropin" | "dropin2" | "card" | "paypal";
  product: string | Product;
}

export const initGtm = () => {
  const gtmTag = process.env.REACT_APP_GTM_TAG;
  if (!gtmTag) {
    return;
  }

  const tagManagerArgs: TagManagerArgs = {
    gtmId: gtmTag,
  };

  TagManager.initialize(tagManagerArgs);
};

export const subCancellation1GtmEvent = () => {
  const dataLayerArgs: DataLayerArgs = {
    dataLayer: {
      event: "SUBCancellation1",
    },
  };

  TagManager.dataLayer(dataLayerArgs);
};

export const subCancellation2GtmEvent = () => {
  const dataLayerArgs: DataLayerArgs = {
    dataLayer: {
      event: "SUBCancellation2",
    },
  };

  TagManager.dataLayer(dataLayerArgs);
};

export const changeTherapist1GtmEvent = () => {
  const dataLayerArgs: DataLayerArgs = {
    dataLayer: {
      event: "ChangeTherapist1",
    },
  };

  TagManager.dataLayer(dataLayerArgs);
};

export const changeTherapist2GtmEvent = (whyChanged: string) => {
  const dataLayerArgs: DataLayerArgs = {
    dataLayer: {
      event: "ChangeTherapist2",
      whyChanged,
    },
  };

  TagManager.dataLayer(dataLayerArgs);
};

export const trackPurchaseEvent = ({
  orderId,
  currency,
}: {
  orderId: string;
  currency: Currency;
}) => {
  const product = (window as any)?.kiloCheckout?.getSelectedProduct();

  const dataLayerArgs: DataLayerArgs = {
    dataLayer: {
      event: "purchase_main",
      pageCategory: "successPage",
      transactionId: orderId,
      transactionTotal: product?.price,
      currencyCode: currency,
      transactionProducts: [
        {
          sku: product?.key,
          name: product?.title,
          price: product?.price,
          quantity: 1,
        },
      ],
    },
  };

  TagManager.dataLayer(dataLayerArgs);
};

export const trackPaymentSubmitEvent = (paymentData: PaymentData) => {
  const method = paymentData?.method;

  let title = paymentData?.product;
  if (typeof title !== "string") {
    title = title?.title;
  }

  const dataLayerArgs: DataLayerArgs = {
    dataLayer: {
      event: "payment_initialized",
      payment_method: method,
      subscription_plan: title,
    },
  };

  TagManager.dataLayer(dataLayerArgs);
};

export const subscribeinChat = (code: string) => {
  const dataLayerArgs: DataLayerArgs = {
    dataLayer: {
      event: "SubscribeinChat",
      clientcode: code,
    },
  };

  TagManager.dataLayer(dataLayerArgs);
};

export const startNowinChat = ({
  plan,
  price,
  currency,
  code,
}: {
  plan: string;
  price: number;
  currency: string;
  code: string;
}) => {
  const dataLayerArgs: DataLayerArgs = {
    dataLayer: {
      event: "StartNowinChat",
      plan,
      value: price,
      currencyCode: currency,
      clientcode: code,
    },
  };

  TagManager.dataLayer(dataLayerArgs);
};

export const addPaymentInfoinChat = ({
  plan,
  price,
  currency,
  code,
}: {
  plan: string;
  price: number;
  currency: string;
  code: string;
}) => {
  const dataLayerArgs: DataLayerArgs = {
    dataLayer: {
      event: "AddPaymentInfoinChat",
      plan,
      value: price,
      currencyCode: currency,
      clientcode: code,
    },
  };

  TagManager.dataLayer(dataLayerArgs);
};

export const purchaseInChat = ({
  plan,
  price,
  currency,
  code,
  orderId,
}: {
  plan: string;
  price: number;
  currency: string;
  code: string;
  orderId: number;
}) => {
  const dataLayerArgs: DataLayerArgs = {
    dataLayer: {
      event: "PurchaseInChat",
      plan,
      value: price,
      currencyCode: currency,
      clientcode: code,
      transactionId: orderId,
    },
  };

  TagManager.dataLayer(dataLayerArgs);
};
