import { FC, HTMLAttributes } from "react";
import { DateTime } from "luxon";
import styled from "styled-components";

import { useFetchFirebaseStorageFileUrl } from "@/utilities/hooks";
import { Message } from "@/models/messages";

interface AudioMessageProps {
  message: Message;
  isMyMsg: boolean;
}

interface ContainerProps {
  isMyMsg: boolean;
}

const Container = styled.div<ContainerProps>`
  max-width: 320px;
  width: 100%;
  border-radius: 1rem;
  background-color: ${({ theme, isMyMsg }) =>
    isMyMsg ? theme.colors.chatUC : theme.colors.chatTC};
  margin-bottom: 0.5rem;
  align-self: ${({ isMyMsg }) => (isMyMsg ? "flex-end" : "flex-start")};
  position: relative;
`;

const AudioContainer = styled.div`
  padding: 0.75rem 1.5rem;
  display: flex;

  @media ${({ theme }) => theme.device.mobile} {
    padding: 0.5rem 1rem;
  }
`;

const Audio = styled.audio`
  border-radius: 1rem;
`;

const Time = styled.p`
  font-family: Roboto;
  font-weight: 400;
  font-size: 0.63rem;
  line-height: 0.5rem;
  letter-spacing: 0.1px;
  color: ${({ theme }) => theme.colors.white};
  position: absolute;
  bottom: 0.75rem;
  right: 1.5rem;

  @media ${({ theme }) => theme.device.mobile} {
    bottom: 0.5rem;
    right: 1rem;
  }
`;

const AudioMessage: FC<AudioMessageProps & HTMLAttributes<HTMLDivElement>> = ({
  message,
  ...props
}) => {
  const audioUrl = useFetchFirebaseStorageFileUrl(message.file!);

  return (
    <Container {...props}>
      <AudioContainer>
        <Audio src={audioUrl} controls />
      </AudioContainer>
      <Time>
        {DateTime.fromSeconds(message.createdAt.seconds).toFormat("t")}
      </Time>
    </Container>
  );
};

export default AudioMessage;
