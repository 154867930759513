import { FC, useState } from "react";
import MicRecorder from "mic-recorder-to-mp3";
import { IoMdMic, IoMdMicOff } from "react-icons/io";
import styled from "styled-components";

const recorder = new MicRecorder({
  bitRate: 128,
});

interface AudioRecorderProps {
  iconColor?: string;
  backgroundColor?: string;
  recordingBackgroundColor?: string;
  loading?: boolean;
  onStart?: () => void;
  onStop?: (blob: Blob) => void;
  onError?: (message: string) => void;
}

interface ContainerProps {
  iconColor?: string;
  backgroundColor?: string;
  recordingBackgroundColor?: string;
  recording: boolean;
  available: boolean;
  loading: boolean;
}

const Container = styled.div<ContainerProps>`
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 50%;
  background-color: ${({
    backgroundColor,
    recordingBackgroundColor,
    recording,
  }) =>
    recording
      ? recordingBackgroundColor ?? "#008000"
      : backgroundColor ?? "#ff0000"};
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${({ iconColor }) => iconColor ?? "#ffffff"};
  font-size: 1.25rem;

  :hover {
    box-shadow: 0 0 5px 1px rgba(0, 0, 0, 0.5);
  }

  ${({ available, loading }) =>
    (!available || loading) &&
    `
    cursor: not-allowed;
    opacity: 0.5;

    :hover {
      box-shadow: none;
    }
  `}
`;

const AudioRecorder: FC<AudioRecorderProps> = ({
  iconColor,
  backgroundColor,
  recordingBackgroundColor,
  loading,
  onStart,
  onStop,
  onError,
}) => {
  const [recording, setRecording] = useState<boolean>(false);
  const [available, setAvailable] = useState<boolean>(true);

  const start = async () => {
    if (recording) {
      return;
    }

    setRecording(true);

    try {
      await recorder.start();

      onStart?.();
    } catch (error) {
      setRecording(false);

      setAvailable(false);

      onError?.((error as Error)?.message);
    }
  };

  const stop = async () => {
    if (!recording) {
      return;
    }

    setRecording(false);

    try {
      const [buffer, blob]: [Buffer, Blob] = await recorder.stop().getMp3();

      onStop?.(blob);
    } catch (error) {
      setAvailable(false);

      onError?.((error as Error)?.message);
    }
  };

  const handleClick = () => {
    if (!!loading) {
      return;
    }

    if (!available) {
      return alert(
        "Sending audio messages requires microphone access, which you can provide in your browser settings."
      );
    }

    if (!recording) {
      return start();
    }

    stop();
  };

  return (
    <Container
      iconColor={iconColor}
      backgroundColor={backgroundColor}
      recordingBackgroundColor={recordingBackgroundColor}
      recording={recording}
      available={available}
      loading={!!loading}
      onClick={handleClick}
    >
      {available ? <IoMdMic /> : <IoMdMicOff />}
    </Container>
  );
};

export default AudioRecorder;
