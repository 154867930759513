import React from "react";
import ReactDOM from "react-dom";
import { Provider as ReduxProvider } from "react-redux";
import { PersistGate } from "redux-persist/lib/integration/react";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import { ThemeProvider } from "styled-components";

import Router from "@/screens";
import store, { persistor } from "@/utilities/store";
import theme from "@/utilities/theme";
import { initGtm } from "@/utilities/gtm";
import "@/utilities/firebase";
import "@/index.css";

if (process.env.NODE_ENV === "production") {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: 1.0,
  });
}

initGtm();

ReactDOM.render(
  <React.StrictMode>
    <ReduxProvider store={store}>
      <PersistGate persistor={persistor}>
        <ThemeProvider theme={theme}>
          <Router />
        </ThemeProvider>
      </PersistGate>
    </ReduxProvider>
  </React.StrictMode>,
  document.getElementById("root")
);
