import { FC } from "react";
import TextareaAutosize, {
  TextareaAutosizeProps,
} from "react-textarea-autosize";
import styled from "styled-components";

import { typography } from "@/utilities/theme";

interface InputProps {
  style: any;
}

const Input = styled(TextareaAutosize)<InputProps>`
  outline: none;
  color: ${({ theme }) => theme.colors.baseC};
  border: 1px solid ${({ theme }) => theme.colors.inactiveLightC};
  border-radius: 3.13rem;
  padding: 0.75rem 1.5rem;
  width: 100%;
  max-height: 30vh;

  ::placeholder {
    color: ${({ theme }) => theme.colors.baseC};
  }
`;

const ChatInput: FC<TextareaAutosizeProps> = ({ style, ...props }) => {
  return <Input style={{ ...typography.chatP, ...style }} {...props} />;
};

export default ChatInput;
