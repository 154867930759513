import { createModel } from "@rematch/core";

import firebase from "@/utilities/firebase";
import { RootState } from "@/utilities/store";
import { Doctor } from "./authentication";
import { RootModel } from ".";

type Timestamp = {
  nanoseconds: number;
  seconds: number;
};

interface Step {
  id: string;
  completed: boolean;
  completedAt: Timestamp;
  title: string;
}

export interface Journey {
  section: {
    id:
      | "orientation"
      | "first-steps"
      | "exploration"
      | "continuing-on"
      | "resting-up"
      | "broadening-scope"
      | "carrying-on"
      | "ending-the-journey";
    period: string;
    sortOrder: number;
    title: string;
  };
  steps: Step[];
}

export interface Consultation {
  id: string;
  doctor: Doctor;
  patient: {
    id: string;
  };
  createdAt: Timestamp;
  journey: Journey[];
  type: "doctor" | "support" | "expert";
  patientLastSeen?: Timestamp;
}

interface State {
  consultations: Consultation[];
}

const initialState: State = {
  consultations: [],
};

const consultations = createModel<RootModel>()({
  state: initialState,
  reducers: {
    setConsultations(state, consultations: Consultation[]) {
      return { ...state, consultations };
    },
    resetState() {
      return initialState;
    },
  },
  effects: (dispatch) => ({
    async fetchConsultations() {
      const currentUser = firebase.auth().currentUser;

      const patientRef = firebase
        .firestore()
        .collection("users")
        .doc(currentUser?.uid);

      const unsubscribe = firebase
        .firestore()
        .collection("consultations")
        .where("patient", "==", patientRef)
        .orderBy("createdAt", "desc")
        .onSnapshot(async (consultationsSnapshot) => {
          const consultations = (await Promise.all(
            consultationsSnapshot.docs.map(async (consultationDoc) => {
              const consultationData = consultationDoc.data();

              const doctorSnapshot = await consultationData.doctor.get();

              const doctorData = doctorSnapshot.data();

              const doctorPhoto = await dispatch.authentication.fetchPhotoUrl(
                doctorData.photo
              );

              return {
                ...consultationData,
                id: consultationDoc.id,
                doctor: {
                  ...doctorData,
                  id: doctorSnapshot.id,
                  photo: doctorPhoto,
                },
                patient: {
                  id: consultationData.patient.id,
                },
              };
            })
          )) as Consultation[];

          dispatch.consultations.setConsultations(consultations);
        });

      return unsubscribe;
    },
    async updateLastSeen(consultationId: string) {
      await firebase
        .firestore()
        .collection("consultations")
        .doc(consultationId)
        .update({ patientLastSeen: firebase.firestore.Timestamp.now() });
    },
    async fetchPhotoUrl(photo: string) {
      return await firebase.storage().refFromURL(photo).getDownloadURL();
    },
  }),
  selectors: (slice, createSelector) => ({
    currentConsultation() {
      return createSelector(
        slice,
        (_: any, assignedDoctorId: string) => assignedDoctorId,
        (state, assignedDoctorId) =>
          state.consultations.find(
            (consultation) => consultation.doctor.id === assignedDoctorId
          )
      );
    },
    supportConsultation() {
      return createSelector(slice, (state) =>
        state.consultations.find(
          (consultation) => consultation.type === "support"
        )
      );
    },
    expertConsultation() {
      return createSelector(slice, (state) =>
        state.consultations.find(
          (consultation) => consultation.type === "expert"
        )
      );
    },
    previousConsultations() {
      return createSelector(
        slice,
        (_: any, assignedDoctorId: string) => assignedDoctorId,
        (state, assignedDoctorId) =>
          state.consultations.filter(
            (consultation) =>
              consultation.doctor.id !== assignedDoctorId &&
              consultation.type === "doctor"
          )
      );
    },
  }),
});

export { consultations };
