import { FC, HTMLAttributes } from "react";
import { DateTime } from "luxon";
import styled from "styled-components";

import Avatar from "@/components/Avatar";
import Text from "@/components/Text";
import { useMediaQuery } from "@/utilities/hooks";
import { Consultation } from "@/models/consultations";
import { Message } from "@/models/messages";

interface ConsultationsListItemProps {
  consultation: Consultation;
  lastMessage?: Message;
  active: boolean;
  avatarBadge?: boolean;
  onConsultationSelect: (consultationId: string) => void;
}

interface ContainerProps {
  active: boolean;
}

const Container = styled.div<ContainerProps>`
  padding: 0.5rem;
  background-color: ${({ active, theme }) =>
    active ? theme.colors.selectC : theme.colors.white};
  border-radius: 0.5rem;
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
  cursor: pointer;

  @media ${({ theme }) => theme.device.mobile} {
    flex-direction: column;
    margin: 0 1rem 0.75rem 0;
  }
`;

const ContentContainer = styled.div`
  padding-left: 1rem;
  flex: 1;
  overflow: hidden;

  @media ${({ theme }) => theme.device.mobile} {
    padding-left: 0;
  }
`;

const AvatarContainer = styled.div`
  display: flex;
  position: relative;

  @media ${({ theme }) => theme.device.mobile} {
    padding-bottom: 0.25rem;
  }
`;

const LastMsgContainer = styled.div`
  display: flex;

  @media ${({ theme }) => theme.device.mobile} {
    display: none;
  }
`;

const LastMsg = styled(Text)`
  flex: 1;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

const AvatarBadge = styled.div`
  width: 0.5rem;
  height: 0.5rem;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.colors.promoC};
  position: absolute;
  right: 0.246rem;
  top: 0.246rem;

  @media ${({ theme }) => theme.device.mobile} {
    right: 0.091rem;
    top: 0.091rem;
  }
`;

const ConsultationsListItem: FC<
  ConsultationsListItemProps & HTMLAttributes<HTMLDivElement>
> = ({
  consultation,
  lastMessage,
  avatarBadge,
  onConsultationSelect,
  ...props
}) => {
  const { isMobile } = useMediaQuery();

  const name = isMobile
    ? consultation.doctor.name
    : `${consultation.doctor.name} ${consultation.doctor.surname}`;

  const showAvatarBadge =
    !!avatarBadge && lastMessage?.sender?.id === consultation?.doctor?.id;

  const getLastMsgText = () => {
    if (lastMessage?.type === "text" || lastMessage?.type === "auto-reply") {
      return lastMessage.text;
    }

    return `${lastMessage?.type} message`;
  };

  const handleClick = () => {
    onConsultationSelect(consultation.id);
  };

  return (
    <Container onClick={handleClick} {...props}>
      <AvatarContainer>
        <Avatar
          src={consultation.doctor.photo}
          size={isMobile ? "2.25rem" : "3rem"}
        />
        {showAvatarBadge && <AvatarBadge />}
      </AvatarContainer>
      <ContentContainer>
        <Text type="bodyBold" color="bodyC">
          {name}
        </Text>
        {!!lastMessage && (
          <LastMsgContainer>
            <LastMsg type="bodyMini" color="baseC">
              {getLastMsgText()}
            </LastMsg>
            <Text type="bodyMini" color="baseC">
              {DateTime.fromSeconds(lastMessage.createdAt.seconds).toRelative()}
            </Text>
          </LastMsgContainer>
        )}
      </ContentContainer>
    </Container>
  );
};

export default ConsultationsListItem;
