import { createModel } from "@rematch/core";
import { v4 as uuidv4 } from "uuid";

import firebase from "@/utilities/firebase";
import { RootModel } from ".";

export interface Message {
  id: string;
  createdAt: {
    seconds: number;
    nanoseconds: number;
  };
  sender: {
    id: string;
  };
  text?: string;
  file?: string;
  type: "text" | "image" | "video" | "audio" | "auto-reply";
}

interface State {
  messages: { [key: string]: Message[] } | null;
}

const initialState: State = {
  messages: null,
};

const messages = createModel<RootModel>()({
  state: initialState,
  reducers: {
    updateMessages(state, messages: { [key: string]: Message[] }) {
      return { ...state, messages: { ...state.messages, ...messages } };
    },
    resetState() {
      return initialState;
    },
  },
  effects: (dispatch) => ({
    async fetchCurrentConsultationMessages(consultationId: string) {
      const unsubscribe = firebase
        .firestore()
        .collection(`consultations/${consultationId}/messages`)
        .orderBy("createdAt")
        .onSnapshot((messagesSnapshot) => {
          const messages = messagesSnapshot.docs.map((doc) => ({
            ...doc.data(),
            id: doc.id,
            sender: {
              id: doc.data().sender.id,
            },
          })) as Message[];

          dispatch.messages.updateMessages({
            [consultationId]: messages,
          });
        });

      return unsubscribe;
    },
    async fetchSupportConsultationMessages(consultationId: string) {
      const unsubscribe = firebase
        .firestore()
        .collection(`consultations/${consultationId}/messages`)
        .orderBy("createdAt")
        .onSnapshot((messagesSnapshot) => {
          const messages = messagesSnapshot.docs.map((doc) => ({
            ...doc.data(),
            id: doc.id,
            sender: {
              id: doc.data().sender.id,
            },
          })) as Message[];

          dispatch.messages.updateMessages({ [consultationId]: messages });
        });

      return unsubscribe;
    },
    async fetchExpertConsultationMessages(consultationId: string) {
      const unsubscribe = firebase
        .firestore()
        .collection(`consultations/${consultationId}/messages`)
        .orderBy("createdAt")
        .onSnapshot((messagesSnapshot) => {
          const messages = messagesSnapshot.docs.map((doc) => ({
            ...doc.data(),
            id: doc.id,
            sender: {
              id: doc.data().sender.id,
            },
          })) as Message[];

          dispatch.messages.updateMessages({ [consultationId]: messages });
        });

      return unsubscribe;
    },
    async fetchPreviousConsultationMessages(consultationId: string) {
      const messagesSnapshot = await firebase
        .firestore()
        .collection(`consultations/${consultationId}/messages`)
        .orderBy("createdAt")
        .get();

      const messages = messagesSnapshot.docs.map((doc) => ({
        ...doc.data(),
        id: doc.id,
        sender: {
          id: doc.data().sender.id,
        },
      })) as Message[];

      dispatch.messages.updateMessages({ [consultationId]: messages });
    },
    async sendTextMessage({
      consultationId,
      text,
    }: {
      consultationId: string;
      text: string;
    }) {
      await dispatch.messages.sendMessage({
        consultationId,
        data: { text, type: "text" },
      });
    },
    async sendFileMessage({
      consultationId,
      doctorId,
      file,
    }: {
      consultationId: string;
      doctorId: string;
      file: File;
    }) {
      const fileType = file.type.split("/")[0];

      const fileUrl = await dispatch.messages.uploadFile({
        consultationId,
        doctorId,
        file,
        fileType,
      });

      await dispatch.messages.sendMessage({
        consultationId,
        data: { file: fileUrl, type: fileType },
      });
    },
    async sendAudioMessage({
      consultationId,
      doctorId,
      file,
    }: {
      consultationId: string;
      doctorId: string;
      file: Blob;
    }) {
      const fileType = "audio";

      const fileUrl = await dispatch.messages.uploadFile({
        consultationId,
        doctorId,
        file,
        fileType,
      });

      await dispatch.messages.sendMessage({
        consultationId,
        data: { file: fileUrl, type: fileType },
      });
    },
    async sendMessage({
      consultationId,
      data,
    }: {
      consultationId: string;
      data: { text?: string; file?: string; type: string };
    }) {
      const currentUser = firebase.auth().currentUser;

      const senderRef = firebase
        .firestore()
        .collection("users")
        .doc(currentUser?.uid);

      const message = {
        createdAt: firebase.firestore.Timestamp.now(),
        sender: senderRef,
        ...data,
      };

      await firebase
        .firestore()
        .collection(`consultations/${consultationId}/messages`)
        .add(message);
    },
    async uploadFile(
      {
        consultationId,
        doctorId,
        file,
        fileType,
      }: {
        consultationId: string;
        doctorId: string;
        file: File | Blob;
        fileType: string;
      },
      state
    ) {
      const fileExtension = dispatch.messages.getFileExtension(fileType);

      const reference = firebase
        .storage()
        .ref(`consultations/${consultationId}/${uuidv4()}${fileExtension}`);

      const metadata = {
        consultationId,
        doctorId,
        patientId: state.authentication.user?.id!,
      };

      const taskSnapshot = await reference.put(file, {
        customMetadata: metadata,
      });

      return taskSnapshot.metadata.fullPath;
    },
    async sendSupportAutoMsg() {
      await firebase.functions().httpsCallable("sendSupportAutoMsg")();
    },
    getFileExtension(fileType: string) {
      switch (fileType) {
        case "image":
          return ".png";
        case "video":
          return ".mp4";
        case "audio":
          return ".mp3";
        default:
          return;
      }
    },
  }),
});

export { messages };
