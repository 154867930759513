import { FC } from "react";
import { Props } from "react-modal";
import styled from "styled-components";

import Modal from "@/components/Modal";
import Text from "@/components/Text";
import TextButton from "@/components/TextButton";
import { ReactComponent as CompleteIcon } from "@/assets/images/complete.svg";
import { useMediaQuery } from "@/utilities/hooks";

interface RequestRegisteredModalProps {
  requestId: string;
  toggleModal: () => void;
}

const CompleteIconStyled = styled(CompleteIcon)`
  width: 7.38rem;
  height: auto;
  display: block;
  margin: 2rem auto;

  @media ${({ theme }) => theme.device.mobile} {
    width: 6.32rem;
    margin: 1rem auto;
  }
`;

const Description = styled(Text).attrs({
  type: "body",
  color: "bodyC",
})`
  margin-bottom: 1rem;
`;

const TextButtonStyled = styled(TextButton)`
  margin-top: 2rem;
  float: right;
`;

const RequestRegisteredModal: FC<RequestRegisteredModalProps & Props> = ({
  requestId,
  toggleModal,
  ...props
}) => {
  const { isMobile } = useMediaQuery();

  return (
    <Modal width="25rem" toggleModal={toggleModal} {...props}>
      <Text type={isMobile ? "h4Bold" : "h3Bold"} color="headingText">
        Your request is registered
      </Text>
      <CompleteIconStyled />
      <Description>
        Your request #{requestId} was successfully created.
      </Description>
      <Description>
        Our support specialist will take a look into your preferences and assign
        a new therapist for you.
      </Description>
      <Text type="body" color="bodyC">
        You’ll receive a confirmation to your inbox.
      </Text>
      <TextButtonStyled type="btn" color="mainC" onClick={toggleModal}>
        Understood
      </TextButtonStyled>
    </Modal>
  );
};

export default RequestRegisteredModal;
