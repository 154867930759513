import { useState, FC } from "react";
import { useSelector, useDispatch } from "react-redux";
import { FiMail } from "react-icons/fi";
import styled from "styled-components";

import ScreenContainer from "@/components/ScreenContainer";
import Header from "@/components/Header";
import MobileNav from "@/components/MobileNav";
import SettingsCard from "@/components/SettingsCard";
import Footer from "@/components/Footer";
import NotificationsItem from "./components/NotificationsItem";
import { RootState, Dispatch } from "@/utilities/store";

const ScreenContainerStyled = styled(ScreenContainer)`
  display: flex;
`;

const Container = styled.div`
  flex: 1;
  padding: 3rem 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media ${({ theme }) => theme.device.mobile} {
    padding: 2rem 0;
  }
`;

const FooterStyled = styled(Footer)`
  padding-top: 3rem;

  @media ${({ theme }) => theme.device.mobile} {
    padding-top: 2rem;
  }
`;

const NotificationsSettings: FC = () => {
  const user = useSelector((state: RootState) => state.authentication.user);

  const [notifications, setNotifications] = useState<{
    emailNotificationsEnabled: boolean;
  }>({
    emailNotificationsEnabled: user?.emailNotificationsEnabled ?? true,
  });

  const loading = useSelector(
    (state: RootState) => state.loading.effects.authentication.updateUserDetails
  );

  const dispatch = useDispatch<Dispatch>();

  const handleValueChange = async ({
    name,
    value,
  }: {
    name: string;
    value: boolean;
  }) => {
    try {
      setNotifications({ ...notifications, [name]: value });

      await dispatch.authentication.updateUserDetails({ [name]: value });
    } catch (_) {
      setNotifications({ ...notifications, [name]: !value });
    }
  };

  return (
    <ScreenContainerStyled
      HeaderComponent={<Header />}
      FooterComponent={<MobileNav />}
    >
      <Container>
        <SettingsCard
          title="Settings"
          breadcrumb="Notification Settings"
          withBackButton
          FooterComponent={
            <NotificationsItem
              name="emailNotificationsEnabled"
              title="Email Notifications"
              description="Get news and updates directly into your inbox"
              value={notifications.emailNotificationsEnabled}
              loading={loading}
              Icon={FiMail}
              onValueChange={handleValueChange}
            />
          }
        />
        <FooterStyled />
      </Container>
    </ScreenContainerStyled>
  );
};

export default NotificationsSettings;
