import { FC, CSSProperties, InputHTMLAttributes } from "react";
import styled from "styled-components";

import Text from "@/components/Text";

interface RadioInputProps {
  label: string;
  style?: CSSProperties;
  onItemClick: (value: string) => void;
}

interface ContainerProps {
  disabled: boolean;
}

const Container = styled.div<ContainerProps>`
  display: flex;
  cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};
`;

const InputContainer = styled.div`
  margin-right: 1rem;
`;

const Input = styled.input`
  width: 1.25rem;
  height: 1.25rem;
  cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};
`;

const RadioInput: FC<RadioInputProps & InputHTMLAttributes<HTMLInputElement>> =
  ({ label, value, style, disabled, onItemClick, ...props }) => {
    const handleClick = () => {
      if (!!disabled) {
        return;
      }

      onItemClick(String(value));
    };

    return (
      <Container style={style} disabled={!!disabled} onClick={handleClick}>
        <InputContainer>
          <Input type="radio" value={value} disabled={disabled} {...props} />
        </InputContainer>
        <Text type="body" color="bodyC">
          {label}
        </Text>
      </Container>
    );
  };

export default RadioInput;
