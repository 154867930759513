import { FC, useState, ChangeEvent } from "react";
import { useSelector, useDispatch } from "react-redux";
import { MdEdit } from "react-icons/md";
import styled from "styled-components";

import ScreenContainer from "@/components/ScreenContainer";
import Header from "@/components/Header";
import MobileNav from "@/components/MobileNav";
import SettingsCard from "@/components/SettingsCard";
import TextButton from "@/components/TextButton";
import Input from "@/components/Input";
import Button from "@/components/Button";
import Text from "@/components/Text";
import Footer from "@/components/Footer";
import DeleteAccountModal from "./components/DeleteAccountModal";
import { RootState, Dispatch } from "@/utilities/store";

const ScreenContainerStyled = styled(ScreenContainer)`
  display: flex;
`;

const Container = styled.div`
  flex: 1;
  padding: 3rem 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media ${({ theme }) => theme.device.mobile} {
    padding: 2rem 0;
  }
`;

const CardContentContainer = styled.div`
  padding: 1.5rem 0;
`;

const InputContainer = styled.div`
  margin-top: 1.5rem;
  width: 22.5rem;

  @media ${({ theme }) => theme.device.mobile} {
    margin-top: 1rem;
    width: 100%;
  }
`;

const ButtonStyled = styled(Button)`
  max-width: 14rem;
  margin-top: 1.5rem;

  @media ${({ theme }) => theme.device.mobile} {
    display: block;
    margin: 1rem auto 0 auto;
  }
`;

const FooterStyled = styled(Footer)`
  padding-top: 3rem;

  @media ${({ theme }) => theme.device.mobile} {
    padding-top: 2rem;
  }
`;

const AccountSettings: FC = () => {
  const user = useSelector((state: RootState) => state.authentication.user);

  const [showModal, setShowModal] = useState<boolean>(false);
  const [nickname, setNickname] = useState<string>(user?.nickname || "");
  const [nicknameDisabled, setNicknameDisabled] = useState<boolean>(true);
  const [error, setError] = useState<string>("");

  const subscription = useSelector(
    (state: RootState) => state.subscriptions.subscription
  );

  const disableUserLoading = useSelector(
    (state: RootState) => state.loading.effects.authentication.disableUser
  );
  const cancelSubscriptionLoading = useSelector(
    (state: RootState) => state.loading.effects.subscriptions.cancelSubscription
  );
  const updateNicknameLoading = useSelector(
    (state: RootState) => state.loading.effects.authentication.updateNickname
  );

  const dispatch = useDispatch<Dispatch>();

  const toggleModal = () => {
    setShowModal(!showModal);
  };

  const toggleNicknameDisabled = () => {
    setNicknameDisabled(!nicknameDisabled);
  };

  const handleModalConfirm = async () => {
    const text = `User ${user?.email} deleted account.`;

    if (!!subscription?.active) {
      await dispatch.subscriptions.cancelSubscription(subscription.active.id);
    }

    await dispatch.authentication.disableUser({ text });
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setNickname(e.target.value);
  };

  const handleUpdateNickname = async () => {
    if (!nickname.trim().length) {
      return setError("This field is required.");
    }

    setError("");

    const res = await dispatch.authentication.updateNickname(nickname);

    if (!res?.error) {
      return toggleNicknameDisabled();
    }

    setError(res.error);
  };

  return (
    <ScreenContainerStyled
      HeaderComponent={<Header />}
      FooterComponent={<MobileNav />}
    >
      <Container>
        <SettingsCard
          title="Settings"
          breadcrumb="Account Settings"
          withBackButton
          FooterComponent={
            <TextButton type="body" color="dangerC" onClick={toggleModal}>
              Delete My Account
            </TextButton>
          }
        >
          <CardContentContainer>
            <Text type="body" color="bodyC">
              Change Username
            </Text>
            <InputContainer>
              <Input
                type="text"
                name="nickname"
                placeholder="Username"
                loading={updateNicknameLoading}
                disabled={nicknameDisabled}
                error={error}
                Icon={MdEdit}
                onIconClick={toggleNicknameDisabled}
                value={nickname}
                onChange={handleChange}
              />
            </InputContainer>
            {!nicknameDisabled && (
              <ButtonStyled
                disabled={updateNicknameLoading}
                onClick={handleUpdateNickname}
              >
                Update
              </ButtonStyled>
            )}
          </CardContentContainer>
        </SettingsCard>
        <FooterStyled />
      </Container>
      <DeleteAccountModal
        isOpen={showModal}
        disabled={disableUserLoading || cancelSubscriptionLoading}
        toggleModal={toggleModal}
        onConfirm={handleModalConfirm}
      />
    </ScreenContainerStyled>
  );
};

export default AccountSettings;
