import { useState, useEffect } from "react";
import { useMediaQuery as useQuery } from "react-responsive";

import firebase from "@/utilities/firebase";
import { device } from "@/utilities/theme";

const useMediaQuery = () => {
  return {
    isMobile: useQuery({ query: device.mobile }),
    isTablet: useQuery({ query: device.tablet }),
    isDesktop: useQuery({ query: device.desktop }),
  };
};

const useFetchFirebaseStorageFileUrl = (fileRef: string) => {
  const [fileUrl, setFileUrl] = useState<string>();

  const fetchFileUrl = async () => {
    const url = await firebase.storage().ref(fileRef).getDownloadURL();

    setFileUrl(url);
  };

  useEffect(() => {
    fetchFileUrl();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return fileUrl;
};

export { useMediaQuery, useFetchFirebaseStorageFileUrl };
