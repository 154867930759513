import { FC, HTMLAttributes } from "react";
import { IoChatbubble, IoFlag, IoPerson } from "react-icons/io5";
import { useSelector } from "react-redux";
import styled from "styled-components";

import NavLink from "./NavLink";
import { RootState } from "@/utilities/store";

interface ContainerProps {
  hasSubscriptions: boolean;
}

const Container = styled.div<ContainerProps>`
  display: none;

  @media ${({ theme }) => theme.device.mobile} {
    height: 4.5rem;
    display: grid;
    grid-template-columns: repeat(
      ${({ hasSubscriptions }) => (hasSubscriptions ? 3 : 2)},
      1fr
    );
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: ${({ theme }) => theme.colors.white};
    z-index: 999;
  }
`;

const MobileNav: FC<HTMLAttributes<HTMLDivElement>> = (props) => {
  const subscription = useSelector(
    (state: RootState) => state.subscriptions.subscription
  );

  return (
    <Container hasSubscriptions={!!subscription?.hasSubscriptions} {...props}>
      <NavLink to="/chat" Icon={IoChatbubble}>
        Inbox
      </NavLink>
      {!!subscription?.hasSubscriptions && (
        <NavLink to="/journey" Icon={IoFlag}>
          Journey
        </NavLink>
      )}
      <NavLink to="/profile" Icon={IoPerson}>
        Profile
      </NavLink>
    </Container>
  );
};

export default MobileNav;
