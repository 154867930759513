import { FC, useRef } from "react";
import { Props, Styles } from "react-modal";
import styled from "styled-components";

import Modal from "@/components/Modal";
import PaymentDialogOrderSummary from "./PaymentDialogOrderSummary";
import PaymentsForm from "./PaymentsForm";
import PaymentInfo from "./PaymentInfo";
import PaymentSecurityLogos from "./PaymentSecurityLogos";
import { Product } from "@/models/products";

interface PaymentDialogProps {
  selectedProduct: Product | null;
  onSuccess: (data: { order_id: number }) => void;
  onClose: () => void;
}

const Container = styled.div`
  padding: 1.5rem 1.5rem 2rem 1.5rem;
`;

const Title = styled.h1`
  font-family: "Source Sans Pro", serif;
  font-weight: 600;
  font-size: 1.13rem;
  line-height: 1.41rem;
  color: #222222;
  text-align: center;
  margin-bottom: 1rem;
`;

const PaymentSecurityLogosStyled = styled(PaymentSecurityLogos)`
  padding: 1.5rem 0;
`;

const PaymentDialog: FC<PaymentDialogProps & Props> = ({
  selectedProduct,
  onSuccess,
  onClose,
  ...props
}) => {
  const containerRef = useRef<HTMLDivElement>(null);

  const handlePaymentCardSelect = () => {
    containerRef?.current?.scrollIntoView({ behavior: "smooth" });
  };

  const customStyle: Styles = {
    content: {
      padding: 0,
    },
  };

  return (
    <Modal width="34.5rem" style={customStyle} toggleModal={onClose} {...props}>
      <PaymentDialogOrderSummary selectedProduct={selectedProduct} />
      <Container ref={containerRef}>
        <Title>Select a secure payment method:</Title>
        <PaymentsForm
          selectedProduct={selectedProduct}
          onSuccess={onSuccess}
          onPaymentCardSelect={handlePaymentCardSelect}
        />
        <PaymentSecurityLogosStyled />
        <PaymentInfo />
      </Container>
    </Modal>
  );
};

export default PaymentDialog;
