import { FC, useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { use100vh } from "react-div-100vh";
import styled from "styled-components";

import ScreenContainer from "@/components/ScreenContainer";
import Header from "@/components/Header";
import MobileNav from "@/components/MobileNav";
import Footer from "@/components/Footer";
import Text from "@/components/Text";
import AboutList from "./components/AboutList";
import { useMediaQuery } from "@/utilities/hooks";
import data from "./data";

enum DataId {
  TermsAndConditions = "terms-and-conditions",
  PrivacyPolicy = "privacy-policy",
}

interface ContainerProps {
  height: number;
}

interface ParagraphProps {
  hasBottomMargin: boolean;
}

const ScreenContainerStyled = styled(ScreenContainer)`
  display: flex;
`;

const Container = styled.div<ContainerProps>`
  flex: 1;
  display: grid;
  grid-template-columns: 18rem auto 1fr;
  grid-column-gap: 2rem;
  padding: 3rem 0;
  height: calc(${({ height }) => height}px - 6.25rem);

  @media ${({ theme }) => theme.device.mobile} {
    grid-template-columns: 1fr;
    grid-auto-rows: auto 1fr;
    grid-row-gap: 1.5rem;
    padding: 2rem 0 1.5rem 0;
    height: calc(${({ height }) => height}px - 3.5rem - 4.5rem);
  }
`;

const LeftContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const Divider = styled.div`
  width: 1px;
  background-color: ${({ theme }) => theme.colors.inactiveLightC};

  @media ${({ theme }) => theme.device.mobile} {
    display: none;
  }
`;

const RightContainer = styled.div`
  overflow-y: auto;

  @media ${({ theme }) => theme.device.mobile} {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
`;

const Title = styled(Text)`
  margin-bottom: 1.5rem;

  @media ${({ theme }) => theme.device.mobile} {
    margin-bottom: 1rem;
  }
`;

const Paragraph = styled(Text)<ParagraphProps>`
  margin-bottom: ${({ hasBottomMargin }) => (hasBottomMargin ? 0.75 : 0)}rem;
`;

const FooterStyled = styled(Footer)`
  padding-top: 3rem;
`;

const About: FC = () => {
  const [activeId, setActiveId] = useState<DataId | null>(null);

  const height = use100vh();

  const { isMobile } = useMediaQuery();

  const params: { id?: DataId } = useParams();

  const rightContainerRef = useRef<HTMLDivElement>(null);

  const aboutListItems = Object.entries(data).map(([id, { title }]) => ({
    id,
    title,
  }));

  const activeData = activeId ? data[activeId] : null;

  useEffect(() => {
    const id = params?.id;

    if (!id) {
      return setActiveId(null);
    }

    setActiveId(id);
  }, [params]);

  useEffect(() => {
    rightContainerRef.current?.scroll(0, 0);
  }, [activeId]);

  return (
    <ScreenContainerStyled
      HeaderComponent={<Header />}
      FooterComponent={<MobileNav />}
    >
      <Container height={height ?? 0}>
        <LeftContainer>
          <AboutList items={aboutListItems} />
          {!isMobile && <FooterStyled />}
        </LeftContainer>
        <Divider />
        <RightContainer ref={rightContainerRef}>
          <div>
            <Title type="h2" color="headingText">
              {activeData?.title}
            </Title>
            {activeData?.paragraphs.map((paragraph, index) => (
              <Paragraph
                key={index}
                type="body"
                color="bodyC"
                hasBottomMargin={index !== activeData.paragraphs.length - 1}
              >
                {paragraph}
              </Paragraph>
            ))}
          </div>
          {isMobile && <FooterStyled />}
        </RightContainer>
      </Container>
    </ScreenContainerStyled>
  );
};

export default About;
