import { FC, HTMLAttributes } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";

import KiloPayments from "./KiloPayments";
import Loader from "@/components/Loader";
import { Product } from "@/models/products";
import { RootState } from "@/utilities/store";

interface PaymentsFormProps {
  selectedProduct: Product | null;
  onSuccess: (data: { order_id: number }) => void;
  onPaymentCardSelect?: () => void;
}

const Container = styled.div`
  min-height: 9.625rem;
`;

const LoaderContainer = styled.div`
  height: 9.625rem;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const PaymentsForm: FC<PaymentsFormProps & HTMLAttributes<HTMLDivElement>> = ({
  selectedProduct,
  onSuccess,
  onPaymentCardSelect,
  ...props
}) => {
  const user = useSelector((state: RootState) => state.authentication.user);

  return (
    <Container {...props}>
      <KiloPayments
        code={user?.funnel?.code}
        initialProduct={selectedProduct}
        handleError={console.error}
        handleSuccess={onSuccess}
        onPaymentCardSelect={onPaymentCardSelect}
      >
        <LoaderContainer>
          <Loader type="Circles" />
        </LoaderContainer>
      </KiloPayments>
    </Container>
  );
};

export default PaymentsForm;
