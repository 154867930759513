import { FC, useState, ChangeEvent } from "react";
import { useSelector, useDispatch } from "react-redux";
import styled from "styled-components";

import ScreenContainer from "@/components/ScreenContainer";
import Header from "@/components/Header";
import Text from "@/components/Text";
import Input from "@/components/Input";
import Button from "@/components/Button";
import Footer from "@/components/Footer";
import { ReactComponent as DefaultProfileImg } from "@/assets/images/default-profile-img.svg";
import { useMediaQuery } from "@/utilities/hooks";
import { RootState, Dispatch } from "@/utilities/store";

const ScreenContainerStyled = styled(ScreenContainer)`
  display: flex;
`;

const MainContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 3rem 0;

  @media ${({ theme }) => theme.device.mobile} {
    padding: 2rem 0;
  }
`;

const Container = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;

  @media ${({ theme }) => theme.device.mobile} {
    align-items: flex-start;
  }
`;

const ContentContainer = styled.div`
  max-width: 64rem;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-auto-rows: minmax(19.38rem, auto);
  grid-column-gap: 1rem;

  @media ${({ theme }) => theme.device.mobile} {
    grid-template-columns: 1fr;
    grid-auto-rows: auto 1fr;
  }
`;

const LeftContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const RightContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;

  @media ${({ theme }) => theme.device.mobile} {
    justify-content: center;
    align-items: flex-start;
  }
`;

const Title = styled(Text)`
  margin-bottom: 0.75rem;

  @media ${({ theme }) => theme.device.mobile} {
    text-align: center;
  }
`;

const Description = styled(Text)`
  @media ${({ theme }) => theme.device.mobile} {
    text-align: center;
    margin-bottom: 2rem;
  }
`;

const ButtonStyled = styled(Button)`
  max-width: 14rem;

  @media ${({ theme }) => theme.device.mobile} {
    margin: 2rem auto 3rem auto;
  }
`;

const DefaultProfileImgStyled = styled(DefaultProfileImg)`
  width: 15rem;
  height: 15rem;

  @media ${({ theme }) => theme.device.mobile} {
    width: 10.25rem;
    height: 10.25rem;
  }
`;

const FooterStyled = styled(Footer)`
  padding-top: 3rem;

  @media ${({ theme }) => theme.device.mobile} {
    padding-top: 2rem;
  }
`;

const CompleteProfile: FC = () => {
  const [nickname, setNickname] = useState<string>("");
  const [error, setError] = useState<string>("");

  const loading = useSelector(
    (state: RootState) => state.loading.effects.authentication.updateNickname
  );

  const { isMobile } = useMediaQuery();

  const dispatch = useDispatch<Dispatch>();

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setNickname(e.target.value);
  };

  const handleContinue = async () => {
    if (!nickname.trim().length) {
      return setError("This field is required.");
    }

    setError("");

    const res = await dispatch.authentication.updateNickname(nickname);

    if (!res?.error) {
      return;
    }

    setError(res.error);
  };

  return (
    <ScreenContainerStyled HeaderComponent={<Header />}>
      <MainContainer>
        <Container>
          <ContentContainer>
            <LeftContainer>
              <div>
                <Title type="h2" color="headingText">
                  Choose your nickname
                </Title>
                <Description type={isMobile ? "body" : "h4"} color="bodyC">
                  Our conversations are private and secure, and you can be as
                  anonymous as you like.
                </Description>
              </div>
              <Input
                type="text"
                name="nickname"
                placeholder="Nickname"
                autoComplete="off"
                loading={loading}
                error={error}
                value={nickname}
                onChange={handleChange}
              />
              <ButtonStyled disabled={loading} onClick={handleContinue}>
                Continue
              </ButtonStyled>
            </LeftContainer>
            <RightContainer>
              <DefaultProfileImgStyled />
            </RightContainer>
          </ContentContainer>
        </Container>
        <FooterStyled />
      </MainContainer>
    </ScreenContainerStyled>
  );
};

export default CompleteProfile;
