import { FC, HTMLAttributes } from "react";
import { IconType } from "react-icons";
import styled from "styled-components";

import { colors } from "@/utilities/theme";

interface JourneyListItemIconProps {
  header: boolean;
  last: boolean;
  iconBgColor: string;
  Icon: IconType;
}

interface ContainerProps {
  header: boolean;
  last: boolean;
}

interface IconContainerProps extends ContainerProps {
  iconBgColor: string;
}

const Container = styled.div<ContainerProps>`
  min-width: 2.19rem;
  height: 100%;

  ${({ header }) =>
    header &&
    `
    padding-top: 0.13rem;
  `}

  ${({ last }) =>
    last &&
    `
    padding-bottom: 0.13rem;
  `}

  @media ${({ theme }) => theme.device.mobile} {
    ${({ header }) =>
      header &&
      `
      padding-top: 0.56rem;
    `}

    ${({ last }) =>
      last &&
      `
      padding-bottom: 0.56rem;
    `}
  }
`;

const IconContainer = styled.div<IconContainerProps>`
  height: 100%;
  background-color: ${({ iconBgColor }) => iconBgColor};
  display: flex;
  align-items: center;
  justify-content: center;

  ${({ header }) =>
    header &&
    `
    border-radius: 1.56rem 1.56rem 0 0;
  `};

  ${({ last }) =>
    last &&
    `
    border-radius: 0 0 1.56rem 1.56rem;
  `};
`;

const JourneyListItemIcon: FC<
  JourneyListItemIconProps & HTMLAttributes<HTMLDivElement>
> = ({ header, last, iconBgColor, Icon, ...props }) => {
  return (
    <Container header={header} last={last} {...props}>
      <IconContainer header={header} last={last} iconBgColor={iconBgColor}>
        <Icon size="1.5rem" color={colors.white} />
      </IconContainer>
    </Container>
  );
};

export default JourneyListItemIcon;
