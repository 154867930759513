import { FC, useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import ScreenLoader from "@/components/ScreenLoader";
import { RootState, Dispatch } from "@/utilities/store";

const FreeConsultation: FC = () => {
  const user = useSelector((state: RootState) => state.authentication.user);

  const dispatch = useDispatch<Dispatch>();

  const params = useParams<{ token: string }>();

  const history = useHistory();

  useEffect(() => {
    loginWithToken();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loginWithToken = async () => {
    try {
      if (!!user) {
        await dispatch.authentication.logout();
      }

      await dispatch.authentication.loginWithToken(params.token);

      await dispatch.authentication.fetchCurrentUserDetails();

      await dispatch.subscriptions.checkSubscriptions();

      navigateToCompleteProfile();
    } catch (_) {
      await dispatch.authentication.logout();

      navigateToLogin();
    }
  };

  const navigateToCompleteProfile = () => {
    history.replace("/complete-profile");
  };

  const navigateToLogin = () => {
    history.replace("/login");
  };

  return <ScreenLoader />;
};

export default FreeConsultation;
