export default [
  {
    question: "1. What is DoMental?",
    answers: [
      "DoMental is an online therapy app where you can chat on a daily basis with certified therapists via unlimited text and audio messaging. Our mission is to provide comfortable and affordable mental health support for anyone in need, and we are always happy to help.",
    ],
  },
  {
    question: "2. How does DoMental therapy work?",
    answers: [
      "We aim to make the therapy process simple and convenient.",
      "• After completing our mental health survey, you will be matched with a counselor.",
      "• Once you download our app, your new therapist will contact you and you can start chatting with them right away!",
      "• The online chat is via private and secure text and audio messaging.",
      "• You will be able to chat with your therapist on a daily basis and from anywhere, no matter how busy your schedule is.",
      "• Your therapist will respond to you throughout the day, offer guidance and support, and provide you with coping skills and strategies.",
      "• The more you talk with your therapist, the better they will understand you and your problems, and the better their insights will be.",
      "• Your chat history will always be accessible for you in case you want to go back and read it, so no useful information provided by your therapist gets lost!",
      "• Keep in mind that if you contact them in the middle of the night, you will have to wait a bit longer for them to reply (even therapists need sleep!).",
    ],
  },
  {
    question: "3. How long does it take to start my therapy?",
    answers: [
      "We aim to provide support as fast as possible. After you finish the mental health survey and sign up to create your account, it can take up to 2 hours to match you with the right therapist (and usually much less than that). Once you are matched, you can start your therapy right away.",
    ],
  },
  {
    question: "4. Who will be my therapist?",
    answers: [
      "All of our therapists are licensed professionals with appropriate academic background and experience in the field of counseling. After completing our mental health survey, our AI algorithm will match your profile with a number of therapists. To offer you the most convenient service, we will assign you a suitable therapist who will be able to answer you the fastest!",
    ],
  },
  {
    question: "5. Can I change the therapist assigned to me?",
    answers: [
      "Definitely. You can change your therapist at any point and for any reason. If you wish to do so, simply contact us with your request and a new therapist based on your preferences will be assigned to you within a few days.",
    ],
  },
  {
    question: "6. How often should I talk with my therapist?",
    answers: [
      "You can chat with your therapist every day, throughout the day, via text and audio messaging. For the therapy to be effective, we recommend you talk with them at least twice a week.",
    ],
  },
  {
    question: "7. How long will my therapy last?",
    answers: [
      "The length of therapy varies quite a bit depending on your individual needs and the type of problems you require help with. We recommend having at least 2 months of therapy for significant, meaningful results to really take root.",
    ],
  },
  {
    question: "8. Is DoMental right for me?",
    answers: [
      "We aim to help people improve their quality of life, overcome their struggles, and achieve their goals. In particular, our therapists specialize in depression, anxiety, stress, OCD, eating disorders, insomnia and relationship problems. Anyone over the age of 18 is eligible to use our services. However, we are unable to help people in crisis or emergencies, people with severe mental illnesses, suicidal thoughts or intentions to harm themselves or others, and people requiring court-mandated therapy. In the case of a crisis or emergency, please contact the relevant institutions in your state or country immediately. Here are a few options you can try:",
      "Emergency: 911",
      "National Domestic Violence Hotline: 1- 800-799-7233",
      "National Suicide Prevention Lifeline: 1-800-273-TALK (8255)",
      "National Hopeline Network: 1-800-SUICIDE (800-784-2433)",
      "Crisis Text Line: Text 'DESERVE' TO 741-741",
      "Self-Harm Hotline: 1-800-DONT CUT (1-800-366-8288)",
      "Lifeline Crisis Chat (Online live messaging): www.suicidepreventionlifeline.org/chat/",
      "Essential local and community services: 211, www.211.org",
      "American Association of Poison Control Centers: 1-800-222-1222",
      "National Council on Alcoholism & Drug Dependency Hope Line: 1-800-622-2255",
      "Planned Parenthood Hotline: 1-800-230-PLAN (7526)",
      "National Crisis Line - Anorexia and Bulimia: 1-800-233-4357",
      "TREVOR Crisis Hotline: 1-866-488-7386",
      "GLBT Hotline: 1-888-843-4564",
      "AIDS Crisis Line: 1-800-221-7044",
      "Veterans Crisis Line: www.veteranscrisisline.net",
    ],
  },
  {
    question: "9. How much does it cost?",
    answers: [
      "The price of our therapy ranges from $29 to $47 per week. The difference depends on the plan you choose, but all plans provide all of our app’s functions. Our services are generally not covered by health insurance, but your coverage may vary. You can cancel your membership at any time for any reason.",
    ],
  },
  {
    question:
      "10. Can DoMental substitute for traditional face-to-face therapy?",
    answers: [
      "Online service is proven to have similar benefits to in-person therapy. However, our therapists are unable to prescribe medication or offer an official diagnosis. This means that while it can substitute face-to-face therapy in many situations, it cannot do so for all situations.",
    ],
  },
  {
    question: "11. Is my data private?",
    answers: [
      "It sure is! Our platform is fully HIPAA-compliant and encrypted (SSL), and your privacy is one of our highest priorities. To use our app, you need to create an account with your own personal password, and once on the app, your data is protected by federal and state laws, and can only be seen by you and your therapist. You have the option of adding others to the chat, making it visible for them as well. If you wish for any of your information or records to be deleted, you can simply contact us help@domental.com.",
    ],
  },
  {
    question: "12. Can I use DoMental anonymously?",
    answers: [
      "You sure can! We wish to make you feel secure and comfortable while talking and opening up to your therapist. We do not ask for your name, surname, or social security number, and you can choose your own username when creating your account.",
    ],
  },
];
